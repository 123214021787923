import React, {useContext, useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import {CostcoContext} from "../CostcoHome";
import axios from "axios";
import {COSTCO_CONFIRM_MAIL_URL} from "../../../Messages/urls";
import {LinearProgress} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    balloonContainer: {
        marginTop: '2rem',
        textAlign: 'center',
    },
    balloon: {
        position: "relative",
        display: 'inline-block',
        minWidth: '120px',
        maxWidth: '100%',
        border: 'solid 3px #bfbfbf',
        boxSizing: 'border-box',
        borderRadius: '10px',
        '&::before': {
            content: '""',
            position: 'absolute',
            bottom: '-24px',
            left: '50%',
            marginLeft: '-15px',
            border: '12px solid transparent',
            borderTop: '12px solid #fff',
            zIndex: 2,
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            bottom: '-30px',
            left: '50%',
            marginLeft: '-17px',
            border: '14px solid transparent',
            borderTop: '14px solid #bfbfbf',
            zIndex: 1,
        },
    },
    balloonText: {
        whiteSpace: 'pre-wrap',
        margin: 0,
        padding: '1rem',
    },
    link: {
        textDecoration: 'none',
        color: 'blue',
        cursor: 'pointer',
    }
}))

const CostcoThanks = () => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'
    const classes = useStyles()
    const {orderNumber, email, isLoading, isFirstTime} = useContext(CostcoContext)
    const [kickBackUrl, setKickBackUrl] = useState('')

    const HuskyThanks = 'images/husky_thanks.jpg'

    const zeroPadding = (id) => {
        // 注文番号を4桁の0埋めにする
        return (Array(4).join('0') + id).slice(-4);
    }

    const shiftMyAccount = () => {
        document.location.href = isEnglish ? '/my_page_e' : '/my_page'
    }

    useEffect(() => {
        const send_confirm_mail = async () => {
            if (orderNumber && email) {
                const url = isEnglish ? `${COSTCO_CONFIRM_MAIL_URL}${orderNumber}/${email}/eng` : `${COSTCO_CONFIRM_MAIL_URL}${orderNumber}/${email}/jp`
                await axios.get(url)
            }
        }
        if (orderNumber && isFirstTime) {
            const promotionId = 4359
            const pcId = 11214
            const offerId = orderNumber
            const sessionCode = sessionStorage.getItem('rd_code')
            setKickBackUrl(`https://r.moshimo.com/af/r/result?p_id=${promotionId}&pc_id=${pcId}&m_v=${offerId}&rd_code=${sessionCode}`)

            const scriptA8 = document.createElement('script');
            const a8Text = `a8sales({
        "pid": "s00000023661001",
        "order_number": "${orderNumber}",
        "currency": "JPY",
        "items": [
            {
                "code": "kaiintouroku",
                "price": 1,
                "quantity": 1
            },
        ],
        "total_price": 1
    });`
            const textNode = document.createTextNode(a8Text)
            scriptA8.appendChild(textNode)
            const body = document.getElementsByTagName('body')[0]
            body.appendChild(scriptA8)
        }
        send_confirm_mail()
    }, [orderNumber, email])

    return (
        <Container maxWidth='md'>
            <img src={kickBackUrl} width="1" height="1" alt=""/>
            <span id="a8sales"></span>
            <div style={{whiteSpace: 'pre-wrap', marginTop: '2rem', marginBottom: '0'}}>
                {isLoading && <div style={{alignItems: 'center'}}><LinearProgress/></div>}
                {isLoading ?
                    isEnglish ?
                        // loading english
                        'Ordering now...\n' +
                        'Please wait a moment.\n\n' +
                        '※Moving pages before the order is completed may result in the order being discarded. Please be careful.' :
                        // loading japanese
                        '注文中です...\nしばらくお待ちください。\n\n' +
                        '※注文が完了する前にページを移動すると注文が破棄される恐れがあります。ご注意ください。'
                    :
                    isEnglish ?
                        'Thank you for your order.\n' +
                        `Your order number is #${zeroPadding(orderNumber)}.\n\n` +
                        '※For products that are out of stock on the day of shopping ' +
                        'from the shopper ' +
                        'we may need to contact you. please understand\n\n' +
                        'This process is a provisional settlement.\n\n' +
                        `Please check the "Shopping is done" email sent after shopping or the "Receipt" (My Account → Purchase History → #${zeroPadding(orderNumber)} → Receipt) which will be reflected after shopping for details of the payment, such as the amount of money changed for out of stock item and substitute products.`
                        :
                        'ご注文いただき有難うございました。\n' +
                        `あなたの注文番号は#${zeroPadding(orderNumber)}です。\n\n` +
                        '※配達日当日に代替品の提案の為、' +
                        '買い物担当から' +
                        'ご連絡を差し上げる場合がございます。ご了承ください。\n\n' +
                        '尚、本処理は仮決済になります。\n\n' +
                        '在庫切れ商品・代替品で変更された金額等の本決済内容は買い物代行終了後に' +
                        'お送りする"買い物代行終了"メール、または買い物代行終了後に反映される"領収書"' +
                        `(マイアカウント → 購入履歴 → #${zeroPadding(orderNumber)} → 領収書)をご確認願います。`
                }
            </div>

            {!isLoading &&
                <div className={classes.balloonContainer}>
                    <div className={classes.balloon}>
                        {isEnglish ?
                            <p className={classes.balloonText}>
                                You will receive a discount coupon based on the amount of your order. Click <span
                                className={classes.link}
                                onClick={() => shiftMyAccount()}>here</span> for details
                            </p>
                            :
                            <p className={classes.balloonText}>
                                注文金額に応じて、割引クーポンがもらえます。<br/>詳細は<span className={classes.link}
                                                                    onClick={() => shiftMyAccount()}>こちら</span>
                            </p>
                        }
                    </div>
                </div>
            }
            <div style={{textAlign: 'center'}}>
                <img src={HuskyThanks} alt="thx" width='30%'/>
            </div>
        </Container>
    )
}

export default CostcoThanks

