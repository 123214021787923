import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {LABEL_PHONE_NUMBER} from "../../Messages/constantMessages";
import {makeStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";


const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%'
    },
    actionButton: {
        marginTop: '1rem',
        marginBottom: '2rem',
    }
}));


const InputForm = (props) => {
    const classes = useStyles()
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'
    // const {isEnglish} = useContext(LanguageContext)
    const {contactData, setContactData, activeStep, setActiveStep} = props
    const required = contactData.last_name && contactData.first_name && contactData.email && contactData.content

    const handleInputChange = () => event => {
        const value = event.target.value
        const name = event.target.name
        setContactData({...contactData, [name]: value})
        // console.log(contactData)
    }

    return (
        <div>
            {/*defaultValueを表示させるため*/}
            {contactData.first_name ?
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                style={{marginTop: '0'}}
                                variant="outlined" margin="normal"
                                fullWidth
                                label={isEnglish ? 'Last Name' : '苗字'}
                                name="last_name"
                                defaultValue={contactData.last_name}
                                onChange={handleInputChange()}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                style={{marginTop: '0'}}
                                variant="outlined" margin="normal"
                                fullWidth
                                label={isEnglish ? 'First Name' : '名前'}
                                name="first_name"
                                defaultValue={contactData.first_name}
                                onChange={handleInputChange()}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        variant="outlined" margin="normal"
                        fullWidth
                        label={isEnglish ? LABEL_PHONE_NUMBER : '電話番号'}
                        name="phone_number"
                        defaultValue={contactData.phone_number}
                        onChange={handleInputChange()}
                    />

                </div>
                :
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                style={{marginTop: '0'}}
                                variant="outlined" margin="normal"
                                fullWidth
                                label={isEnglish ? 'Last Name' : '苗字'}
                                name="last_name"
                                onChange={handleInputChange()}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                style={{marginTop: '0'}}
                                variant="outlined" margin="normal"
                                fullWidth
                                label={isEnglish ? 'First Name' : '名前'}
                                name="first_name"
                                onChange={handleInputChange()}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        variant="outlined" margin="normal"
                        fullWidth
                        label={isEnglish ? LABEL_PHONE_NUMBER : '電話番号'}
                        name="phone_number"
                        onChange={handleInputChange()}
                    />

                </div>
            }
            <TextField
                variant="outlined" margin="normal"
                fullWidth
                label={isEnglish ? 'Email' : 'メールアドレス'}
                name="email"
                defaultValue={contactData.email}
                onChange={handleInputChange()}
            />

            <TextField
                variant="outlined" margin="normal"
                fullWidth
                label={isEnglish ? 'Order Number' : '注文番号'}
                name="order_number"
                defaultValue={contactData.order_number}
                onChange={handleInputChange()}
            />

            <TextField
                variant="outlined" margin="normal"
                fullWidth
                multiline
                label={isEnglish ? 'Content' : '内容'}
                name="content"
                defaultValue={contactData.content}
                onChange={handleInputChange()}
            />

            <Button onClick={() => setActiveStep(activeStep + 1)}
                    fullWidth
                    color='primary'
                    variant='contained'
                    className={classes.actionButton}
                    disabled={!required}
            >
                {isEnglish ? 'Confirm' : '確認する'}
            </Button>
        </div>
    )
}

export default InputForm