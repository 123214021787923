import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import AdminDisplayItems from "./AdminDisplayItems";
import axios from "axios";
import {COSTCO_ORDER_URL} from "../../Messages/urls";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import {Container} from "@material-ui/core";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const createData = (id, numCartItem, total, deliveryDate, cancel, memo, items, name, address, payment_method, phone_number) => {
    return {
        id,
        numCartItem,
        total,
        deliveryDate,
        cancel,
        memo,
        items,
        name,
        address,
        payment_method,
        phone_number,
    };
}

const Row = (props) => {
    const {row} = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">{row.id}</TableCell>
                <TableCell align="right">{row.numCartItem}</TableCell>
                <TableCell align="right"
                           style={{color: 'blue'}}
                           onClick={() => window.location.href = `/admin_price?${row.total}`}>
                    {row.total}
                </TableCell>
                <TableCell align="right">{row.deliveryDate}</TableCell>
                <TableCell align="right">{row.cancel}</TableCell>
                <TableCell align="right">{row.memo}</TableCell>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{row.address}</TableCell>
                <TableCell align="right">{row.payment_method}</TableCell>
                <TableCell align="right">{row.phone_number}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <AdminDisplayItems cartItemArray={row.items}/>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


const zeroPadding = (NUM, LEN) => {
    return (Array(LEN).join('0') + NUM).slice(-LEN);
}

const zeroPaddingDeliveryDate = (deliveryDate) => {
    console.log(deliveryDate)
    const paddingMonth = zeroPadding(deliveryDate.split('  ')[0].split('/')[0], 2)
    const paddingDate = zeroPadding(deliveryDate.split('  ')[0].split('/')[1], 2)
    const deliveryTime = deliveryDate.split('  ')[1]
    return `${paddingMonth}/${paddingDate}  ${deliveryTime}`
}

const AdminOrder = () => {
    const [order, setOrder] = useState()

    useEffect(() => {
        axios.get(`${COSTCO_ORDER_URL}?user=&all_complete=false`)
            .then(res => {
                setOrder(res.data)
            })
    }, [])
    const rows = []

    for (const _key in order) {
        const _order = order[_key]
        const rowData = createData(
            _order['id'],
            _order['cart_item'].length,
            _order['total'],
            zeroPaddingDeliveryDate(_order['delivery_date']),
            _order['cancel'],
            _order['memo'],
            _order['cart_item'],
            _order['name'],
            _order['address'],
            _order['payment_method'],
            _order['phone_number'],
        )
        rows.push(rowData)
    }

    rows.sort(function (a, b) {
        if (a.deliveryDate < b.deliveryDate) {
            return -1
        }
        if (a.deliveryDate > b.deliveryDate) {
            return 1
        }
        return 0
    })

    return (
        <Container maxWidth="xs">
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell>No.</TableCell>
                            <TableCell>商品種類数</TableCell>
                            <TableCell align="right">合計</TableCell>
                            <TableCell align="right">配達日時</TableCell>
                            <TableCell align="right">在庫切れ</TableCell>
                            <TableCell align="right">メモ</TableCell>
                            <TableCell align="right">名前</TableCell>
                            <TableCell align="right" style={{minWidth: 100}}>住所</TableCell>
                            <TableCell align="right">決済方法</TableCell>
                            <TableCell align="right">電話番号</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <Row key={row.id} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    )
}

export default AdminOrder