import React from "react";
import ReceiptDownload from "../Profile/ReceiptDownload";


const ReceiptDownloadPage = () => {

    return (
        <div className='pageWrapper'>
            <ReceiptDownload/>
        </div>
    )
}

export default ReceiptDownloadPage