import React from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer";
import UserHome from "../Profile/UserHome";


const UserPage = () => {
    return(
        <div className='pageWrapper'>
            <NavBar/>
            <UserHome/>
            <Footer/>
        </div>
    )
}

export default UserPage