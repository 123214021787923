import React, {createContext, useEffect, useState} from "react";
import EmailForm from "./EmailForm";
import CodeForm from "./CodeForm";
import ProfileForm from "./ProfileForm";
import Container from '@material-ui/core/Container';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Step, StepLabel, Stepper} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    titleImg: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    stepper: {
        paddingLeft: 0,
        paddingRight: 0,
    }
}))

export const RegisterContext = createContext()

const RegisterTop = () => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'
    const urlParam = currentUrl.split('?')[1]

    const classes = useStyles()

    const title_eng = '../images/title_register_eng.jpeg'
    const title_jp = '../images/title_register_jp.jpeg'


    const stepNamesJp = ['メール認証', '認証コード', '情報入力']
    const stepNamesEng = ['Verify email', 'Enter code', 'Profile Entry']
    const stepNames = isEnglish ? stepNamesEng : stepNamesJp


    const [activeStep, setActiveStep] = useState(0)
    const [email, setEmail] = useState('')
    const [authenticationCode, setAuthenticationCode] = useState('')

    const [invitationCode, setInvitationCode] = useState('')
    useEffect(() => {
        if (urlParam) {
            isEnglish ? setInvitationCode(urlParam.slice(0, 6)) : setInvitationCode(urlParam)
        }
    }, [urlParam, invitationCode])

    return (
        <RegisterContext.Provider
            value={{
                isEnglish,
                activeStep,
                setActiveStep,
                email,
                setEmail,
                authenticationCode,
                setAuthenticationCode,
                invitationCode,
                setInvitationCode,
            }}>
            <Container maxWidth='xs' style={{marginBottom: '2rem'}}>
                {isEnglish ?
                    <img src={title_eng} alt="title" className={classes.titleImg}/> :
                    <img src={title_jp} alt="title" className={classes.titleImg}/>
                }
                <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
                    {stepNames.map((label, index) => (<Step><StepLabel>{label}</StepLabel></Step>))}
                </Stepper>
                {activeStep === 0 && <EmailForm/>}
                {activeStep === 1 && <CodeForm/>}
                {activeStep === 2 && <ProfileForm/>}
            </Container>
        </RegisterContext.Provider>
    )
}

export default RegisterTop