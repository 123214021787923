import React from "react";
import {withCookies} from 'react-cookie';
import Footer from "../Footer";
import LoginForCostco from "../Costco/LoginForCostco";
import NavBar from "../NavBar/NavBar";


const LoginForCostcoPage = () => {

    return (
        <div className='pageWrapper'>
            <NavBar/>
            <LoginForCostco/>
            <Footer/>
        </div>
    )
}

export default withCookies(LoginForCostcoPage)