import React from "react";
import {Button} from "@material-ui/core";

const AdminNoAcess = () => {
    return (
        <div style={{textAlign: 'center'}}>
            <h3>権限がありません</h3>
            <Button
                variant='contained'
                onClick={()=> window.location.href = '/'}
            >
                ホームに戻る
            </Button>
        </div>
    )
}

export default AdminNoAcess