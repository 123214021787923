import React from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer";
import Container from "@material-ui/core/Container";


const CovidPage = () => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'

    const upperImgJp = 'images/covid_upper_jp.jpg'
    const upperImgEng = 'images/covid_upper_eng.jpg'
    const lowerImgJp = 'images/covid_lower_jp.jpg'
    const lowerImgJEng = 'images/covid_lower_eng.jpg'

    return (
        <div className="pageWrapper">
            <NavBar/>
            <Container maxWidth='xs'>
                <div>
                    {isEnglish ?
                        <img src={upperImgEng} alt="covid" width='100%' style={{marginTop: '2rem'}}/>:
                        <img src={upperImgJp} alt="covid" width='100%'/>
                    }

                </div>

                <div>
                    {isEnglish ?
                        <p style={{whiteSpace: 'pre-wrap', marginTop: '1rem', marginBottom: '2.2rem', marginLeft: '1.5rem'}}>In
                            response to the spread of the new coronavirus, Husky Cart has been taking various measures
                            to prevent infection, prioritizing the safety and security of our customers, employees and
                            their families.</p>
                        :
                        <p style={{whiteSpace: 'pre-wrap', marginTop: '0', marginBottom: '2rem'}}>
                            Husky Cartでは、新型コロナウイルス感染拡大に伴い、お客様並びに従業員、その家族の安心・安全を最優先し感染予防のための様々な取り組みを行っております。</p>
                    }
                    <p style={{whiteSpace: 'pre-wrap', marginTop: '0', marginBottom: '1rem'}}>
                        {isEnglish ?
                            '' :
                            ''
                        }
                    </p>
                </div>

                <div style={{marginBottom: '2rem'}}>
                    <img src={isEnglish ? lowerImgJEng : lowerImgJp} alt="covid" width='100%'/>
                </div>

            </Container>
            <Footer/>
        </div>
    )
}

export default CovidPage