import React, {useEffect, useState} from "react";
import axios from "axios";
import {COSTCO_PRODUCT_URL} from "../../Messages/urls";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";


const DisplayShoppingDetail = (props) => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '/e' || currentUrl.slice(-2) === '_e'
    const {firstOrder, orderItem, orderId, cartItems} = props
    const [products, setProducts] = useState()
    const [newOrderItem, setNewOrderItem] = useState()
    const [firstOrderItem, setFirstOrderItem] = useState()
    const [outOfStockItem, setOutOfStockItem] = useState()
    const [addItem, setAddItem] = useState()
    const [isOff, setIsOff] = useState(false)
    const list = []
    const outOfStockList = []
    const addItemList = []

    const tenPercentOff = price => {
        // 0.9倍して1の位切り捨て
        return Math.floor(price * 0.9 * 0.1) * 10
    }

    useEffect(() => {
        axios.get(COSTCO_PRODUCT_URL)
            .then(res => {
                setProducts(res.data)
            })
    }, [])

    useEffect(() => {
        for (const key in firstOrder) {
            if (firstOrder[key].id === orderId) {
                setFirstOrderItem(firstOrder[key].cart_item)
                setIsOff(firstOrder[key].memo.includes('10%off'))
            }
        }
    }, [firstOrder, orderId])

    useEffect(() => {
        if (orderItem && firstOrderItem) {
            let firstOrderItemArr = []
            let orderItemArr = []
            let outOfStockArr = []
            let addItemArr = []
            let newOrderArr = []
            for (let key in firstOrderItem) {
                firstOrderItemArr.unshift(firstOrderItem[key])
            }
            for (let key in orderItem) {
                orderItemArr.unshift(orderItem[key])
            }
            for (let key in firstOrderItemArr) {
                if (!orderItemArr.includes(firstOrderItemArr[key])) {
                    outOfStockArr.unshift(firstOrderItemArr[key])
                }
            }
            for (let key in orderItem) {
                if (!firstOrderItemArr.includes(orderItemArr[key])) {
                    addItemArr.unshift(orderItem[key])
                } else {
                    newOrderArr.push(orderItemArr[key])
                }
            }


            setOutOfStockItem(outOfStockArr)
            setAddItem(addItemArr)
            setNewOrderItem(newOrderArr)
        }
    }, [orderItem, firstOrderItem])


    if (products) {
        for (const index in newOrderItem) {
            const cartId = newOrderItem[index]
            for (const cartItemKey in cartItems) {
                if (cartId === cartItems[cartItemKey].id) {
                    const productId = cartItems[cartItemKey].product
                    const targetProduct = products.filter(product => (product.id === Number(productId)))[0]

                    list.push(
                        <Grid item xs={12} key={productId}>
                            <Card>
                                <CardActionArea>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <CardMedia
                                                image={targetProduct.image.replace('http', 'https')}
                                                title={targetProduct.name}
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    display: 'inline-block',
                                                    marginLeft: '1rem',
                                                    marginTop: '1rem',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CardContent style={{display: 'inline-block', paddingBottom: '0'}}>
                                                <Typography gutterBottom variant="subtitle1" component="h2">
                                                    {isEnglish ? targetProduct.english_name : targetProduct.name}
                                                </Typography>
                                                <p style={{marginRight: '0', marginLeft: '0'}}>
                                                    ¥{isOff ?
                                                    tenPercentOff(Math.ceil(targetProduct.marked_up_price)) + ' (10%off)'
                                                    :
                                                    Math.ceil(targetProduct.marked_up_price)
                                                }
                                                    {/*¥{Math.ceil(targetProduct.marked_up_price)}*/}
                                                </p>
                                            </CardContent>
                                        </Grid>
                                    </Grid>
                                </CardActionArea>

                                <CardActions style={{padding: '0', display: 'flex', justifyContent: 'center'}}>

                                    <p style={{marginRight: '0', marginLeft: '0'}}>
                                        <span>{isEnglish ? 'quantity: ' : '個数: '}</span>{cartItems[cartItemKey].quantity}
                                    </p>

                                    <Typography variant="h6" component="p" style={{marginLeft: '1rem'}}>
                                        ¥{isOff ?
                                        tenPercentOff(targetProduct.marked_up_price) * cartItems[cartItemKey].quantity
                                        :
                                        targetProduct.marked_up_price * cartItems[cartItemKey].quantity
                                    }
                                        {/*¥{targetProduct.marked_up_price * cartItems[cartItemKey].quantity}*/}
                                    </Typography>

                                </CardActions>
                            </Card>
                        </Grid>
                    )
                }
            }
        }
    }

    if (products && outOfStockItem) {
        for (const index in outOfStockItem) {
            const cartId = outOfStockItem[index]
            for (const cartItemKey in cartItems) {
                if (cartId === cartItems[cartItemKey].id) {
                    const productId = cartItems[cartItemKey].product
                    const targetProduct = products.filter(product => (product.id === Number(productId)))[0]

                    outOfStockList.push(
                        <Grid item xs={12} key={productId}>
                            <Card>
                                <CardActionArea>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <CardMedia
                                                image={targetProduct.image.replace('http', 'https')}
                                                title={targetProduct.name}
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    display: 'inline-block',
                                                    marginLeft: '1rem',
                                                    marginTop: '1rem',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CardContent style={{display: 'inline-block', paddingBottom: '0'}}>
                                                <Typography gutterBottom variant="subtitle1" component="h2">
                                                    {isEnglish ? targetProduct.english_name : targetProduct.name}
                                                </Typography>
                                                <p style={{marginRight: '0', marginLeft: '0'}}>
                                                    ¥{isOff ?
                                                    tenPercentOff(Math.ceil(targetProduct.marked_up_price)) + ' (10%off)'
                                                    :
                                                    Math.ceil(targetProduct.marked_up_price)
                                                }
                                                    {/*¥{Math.ceil(targetProduct.marked_up_price)}*/}
                                                </p>
                                            </CardContent>
                                        </Grid>
                                    </Grid>
                                </CardActionArea>

                                <CardActions style={{padding: '0', display: 'flex', justifyContent: 'center'}}>

                                    <p style={{marginRight: '0', marginLeft: '0'}}>
                                        <span>{isEnglish ? 'quantity: ' : '個数: '}</span>{cartItems[cartItemKey].quantity}
                                    </p>

                                    <Typography variant="h6" component="p" style={{marginLeft: '1rem'}}>
                                        ¥{isOff ?
                                        tenPercentOff(targetProduct.marked_up_price) * cartItems[cartItemKey].quantity
                                        :
                                        targetProduct.marked_up_price * cartItems[cartItemKey].quantity
                                    }
                                        {/*¥{targetProduct.marked_up_price * cartItems[cartItemKey].quantity}*/}
                                    </Typography>

                                </CardActions>
                            </Card>
                        </Grid>
                    )
                }
            }
        }
    }
    if (products && addItem) {
        for (const index in addItem) {
            const cartId = addItem[index]
            for (const cartItemKey in cartItems) {
                if (cartId === cartItems[cartItemKey].id) {
                    const productId = cartItems[cartItemKey].product
                    const targetProduct = products.filter(product => (product.id === Number(productId)))[0]

                    addItemList.push(
                        <Grid item xs={12} key={productId}>
                            <Card>
                                <CardActionArea>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <CardMedia
                                                image={targetProduct.image.replace('http', 'https')}
                                                title={targetProduct.name}
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    display: 'inline-block',
                                                    marginLeft: '1rem',
                                                    marginTop: '1rem',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CardContent style={{display: 'inline-block', paddingBottom: '0'}}>
                                                <Typography gutterBottom variant="subtitle1" component="h2">
                                                    {isEnglish ? targetProduct.english_name : targetProduct.name}
                                                </Typography>
                                                <p style={{marginRight: '0', marginLeft: '0'}}>
                                                    ¥{isOff ?
                                                    tenPercentOff(Math.ceil(targetProduct.marked_up_price)) + ' (10%off)'
                                                    :
                                                    Math.ceil(targetProduct.marked_up_price)
                                                }
                                                    {/*¥{Math.ceil(targetProduct.marked_up_price)}*/}
                                                </p>
                                            </CardContent>
                                        </Grid>
                                    </Grid>
                                </CardActionArea>

                                <CardActions style={{padding: '0', display: 'flex', justifyContent: 'center'}}>

                                    <p style={{marginRight: '0', marginLeft: '0'}}>
                                        <span>{isEnglish ? 'quantity: ' : '個数: '}</span>{cartItems[cartItemKey].quantity}
                                    </p>

                                    <Typography variant="h6" component="p" style={{marginLeft: '1rem'}}>
                                        ¥{isOff ?
                                        tenPercentOff(targetProduct.marked_up_price) * cartItems[cartItemKey].quantity
                                        :
                                        targetProduct.marked_up_price * cartItems[cartItemKey].quantity
                                    }
                                        {/*¥{targetProduct.marked_up_price * cartItems[cartItemKey].quantity}*/}
                                    </Typography>

                                </CardActions>
                            </Card>
                        </Grid>
                    )
                }
            }
        }
    }
    return (
        <div>
            {list}
            {addItemList.length !== 0 &&
            <div>
                <p style={{textAlign: 'center', backgroundColor: 'lightgreen', margin: 0, padding: '1rem'}}>
                    {isEnglish ? 'Substitute or additional items' : '追加・代替商品'}</p>
                {addItemList}
            </div>}
            {outOfStockList.length !== 0 &&
            <div>
                <p style={{textAlign: 'center', backgroundColor: 'pink', margin: 0, padding: '1rem'}}>
                    {isEnglish ? 'Out of stock items' : '在庫切れ商品'}</p>
                {outOfStockList}
            </div>}

        </div>
    )
}

export default DisplayShoppingDetail