import React, {useReducer, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import loginReducer from "../reducers/loginReducer";
import {ERROR_CATCHED, FETCH_SUCCESS, INPUT_EDIT, START_FETCH} from "../Messages/actions";
import axios from 'axios'
import {AUTHEN_URL} from "../Messages/urls";
import Container from '@material-ui/core/Container';
import CircularProgress from "@material-ui/core/CircularProgress";
import {BACK, FORGET_PASSWORD, LOGIN} from "../Messages/constantMessages";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {withCookies} from 'react-cookie';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(0, 0, 2),
    },
    loginButton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    span: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'teal',
    },
    spanError: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'fuchsia',
        marginTop: 10,
    },
    margin: {
        margin: theme.spacing(1),
    },
    titleImg: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',

    },
}))

const initialState = {
    isLoading: false,
    error: '',
    credentialsLog: {
        username: '',
        password: ''
    }
};

const Login = (props) => {
    const classes = useStyles()

    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'

    const [state, dispatch] = useReducer(loginReducer, initialState)
    const [showPassword, setShowPassword] = useState(false)
    const unCreatable = !state.credentialsLog.password || !state.credentialsLog.username
    // 画像のパス
    const title_eng = 'images/title_login_eng.jpeg'
    const title_jp = 'images/title_login_jp.jpeg'

    const inputChangedLog = () => event => {
        const cred = state.credentialsLog
        cred[event.target.name] = event.target.value
        dispatch({
            type: INPUT_EDIT,
            inputName: 'state.credentialsLog',
            payload: cred,
        })
    }

    const login = async (event) => {
        event.preventDefault()
        try {
            dispatch({type: START_FETCH})
            const res = await axios.post(AUTHEN_URL, state.credentialsLog, {
                headers: {'Content-Type': 'application/json'}
            })
            props.cookies.set('current-token', res.data.token)
            // 成功したらトップページに遷移
            res.data.token ?(isEnglish ? window.location.href = "/costco_e" : window.location.href = "/costco") : (isEnglish ? window.location.href = "/login_e" : window.location.href = "/login")
            dispatch({type: FETCH_SUCCESS})
        } catch (error) {
            console.log('from login', error)
            dispatch({type: ERROR_CATCHED})
        }
    }
    return (
        <Container maxWidth='xs'>
            <form onSubmit={login}>
                <div className={classes.paper}>
                    {state.isLoading && <CircularProgress/>}
                    {isEnglish ?
                        <img src={title_eng} alt="title" className={classes.titleImg}/>
                        :
                        <img src={title_jp} alt="title" className={classes.titleImg}/>
                    }
                    <TextField
                        variant="outlined" margin="normal"
                        fullWidth
                        label={isEnglish ? 'Email' : 'メールアドレス'}
                        name="username"
                        value={state.credentialsLog.username}
                        onChange={inputChangedLog()}
                        autoFocus/>

                    <FormControl
                        className={clsx(classes.margin, classes.textField)}
                        variant="outlined"
                        fullWidth
                    >
                        <InputLabel htmlFor="outlined-adornment-password">
                            {isEnglish ? 'Password' : 'パスワード'}
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            name="password"
                            label={isEnglish ? 'Password' : 'パスワード'}
                            type={showPassword ? 'text' : 'password'}
                            value={state.credentialsLog.password}
                            onChange={inputChangedLog()}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>

                    <span className={classes.spanError}>{state.error}</span>


                    <Button
                        className={classes.loginButton}
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        disabled={unCreatable}
                    >{isEnglish ? LOGIN : 'ログイン'}</Button>
                </div>
            </form>

            <Button
                className={classes.submit}
                type='submit'
                color='primary'
                fullWidth
                variant='contained'
                onClick={() => {
                    isEnglish ? window.location.href = '/register_e' : window.location.href = '/register'
                }}
            >{isEnglish ? 'New Register' : '新規会員登録はこちら'}</Button>

            <Grid container>
                <Grid item xs>
                    <Link href={isEnglish ? '/e' : '/'} variant="body2" color='textSecondary'>
                        {isEnglish ? BACK : '戻る'}
                    </Link>
                </Grid>
                <Grid item>
                    <Link href={isEnglish ? "/forget_password_e" : "/forget_password"} variant="body2"
                          color='textSecondary'>
                        {isEnglish ? FORGET_PASSWORD : 'パスワードをお忘れですか？'}
                    </Link>
                </Grid>
            </Grid>
        </Container>
    )
}

export default withCookies(Login)