import React, {useContext} from "react";
import TextField from "@material-ui/core/TextField";
import {LABEL_PHONE_NUMBER} from "../../../Messages/constantMessages";
import {CostcoContext} from "../CostcoHome";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import axios from "axios";
import {CREATE_PROFILE_URL} from "../../../Messages/urls";
import {isMatchLanguagePrefecture, isValidCombination} from "../../utils/func"
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";


const CostcoAddressForm = (props) => {
    const {myProfile, setMyProfile, token, classes} = useContext(CostcoContext)
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'
    const {setIsFill, setIsChange} = props

    const tokyo = isEnglish ? 'Tokyo' : '東京都'
    const kanagawa = isEnglish ? 'Kanagawa' : '神奈川県'

    const handleInputChange = () => event => {
        setIsChange(true)
        const value = event.target.value
        const name = event.target.name
        setMyProfile({...myProfile, [name]: value})
    }

    if (myProfile.last_name && myProfile.first_name && myProfile.prefecture && myProfile.city_name && myProfile.place_number && myProfile.phone_number && isValidCombination(myProfile.prefecture, myProfile.city_name) && isMatchLanguagePrefecture(myProfile.prefecture, isEnglish)) {
        setIsFill(true)
    } else {
        setIsFill(false)
    }
    const updateProfile = async () => {
        const editData = new FormData()
        editData.append("first_name", myProfile.first_name)
        editData.append("last_name", myProfile.last_name)
        editData.append("postal_code", myProfile.postal_code)
        editData.append("prefecture", myProfile.prefecture)
        editData.append("city_name", myProfile.city_name)
        editData.append("place_number", myProfile.place_number)
        editData.append("building_floor_room_name", myProfile.building_floor_room_name)
        editData.append("phone_number", myProfile.phone_number)
        try {
            const res = await axios.put(`${CREATE_PROFILE_URL}${myProfile.id}/`, editData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            })
            setMyProfile(res.data)
            setIsChange(false)
        } catch (e) {
            console.log("error from costco addressform", e)
        }
    }


    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        variant="outlined" margin="normal"
                        fullWidth
                        label={isEnglish ? 'Last Name' : '苗字'}
                        name="last_name"
                        defaultValue={myProfile.last_name}
                        onChange={handleInputChange()}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        variant="outlined" margin="normal"
                        fullWidth
                        label={isEnglish ? 'First Name' : '名前'}
                        name="first_name"
                        defaultValue={myProfile.first_name}
                        onChange={handleInputChange()}
                    />
                </Grid>
            </Grid>

            <FormControl className={classes.selectForm} margin="normal" variant="outlined"
                         {...(!isMatchLanguagePrefecture(myProfile.prefecture, isEnglish) ? {error: true} : {error: false})}>
                <InputLabel
                    id="prefecture-simple-select-outlined-label">{isEnglish ? 'Prefecture' : '都道府県'}</InputLabel>
                <Select
                    labelId="prefecture-simple-select-outlined-label"
                    id="prefecture-simple-select-outlined"
                    value={myProfile.prefecture}
                    onChange={handleInputChange()}
                    label={isEnglish ? 'Prefecture' : '都道府県'}
                    name="prefecture"
                >
                    <MenuItem value={tokyo}>{tokyo}</MenuItem>
                    <MenuItem value={kanagawa}>{kanagawa}</MenuItem>
                </Select>
            </FormControl>

            {myProfile.prefecture === tokyo &&
            <FormControl className={classes.selectForm} margin="normal" variant="outlined"
                         {...(!isValidCombination(myProfile.prefecture, myProfile.city_name) ? {error: true} : {error: false})}>
                <InputLabel
                    id="city-simple-select-outlined-label">{isEnglish ? 'City Name' : '市区町村名'}</InputLabel>
                <Select
                    labelId="city-simple-select-outlined-label"
                    id="city-simple-select-outlined"
                    value={myProfile.city_name}
                    onChange={handleInputChange()}
                    label={isEnglish ? 'City Name' : '市区町村名'}
                    name="city_name"
                    margin="normal"
                >
                    <MenuItem
                        value={isEnglish ? 'Shinjuku' : '新宿区'}>{isEnglish ? 'Shinjuku' : '新宿区'}</MenuItem>
                    <MenuItem
                        value={isEnglish ? 'Chiyoda' : '千代田区'}>{isEnglish ? 'Chiyoda' : '千代田区'}</MenuItem>
                    <MenuItem
                        value={isEnglish ? 'Chuo' : '中央区'}>{isEnglish ? 'Chuo' : '中央区'}</MenuItem>
                    <MenuItem
                        value={isEnglish ? 'Koto' : '江東区'}>{isEnglish ? 'Koto' : '江東区'}</MenuItem>
                    <MenuItem
                        value={isEnglish ? 'Minato' : '港区'}>{isEnglish ? 'Minato' : '港区'}</MenuItem>
                    <MenuItem
                        value={isEnglish ? 'Shibuya' : '渋谷区'}>{isEnglish ? 'Shibuya' : '渋谷区'}</MenuItem>
                    <MenuItem
                        value={isEnglish ? 'Shinagawa' : '品川区'}>{isEnglish ? 'Shinagawa' : '品川区'}</MenuItem>
                    <MenuItem
                        value={isEnglish ? 'Meguro' : '目黒区'}>{isEnglish ? 'Meguro' : '目黒区'}</MenuItem>
                    <MenuItem
                        value={isEnglish ? 'Setagaya' : '世田谷区'}>{isEnglish ? 'Setagaya' : '世田谷区'}</MenuItem>
                    <MenuItem
                        value={isEnglish ? 'Ota' : '大田区'}>{isEnglish ? 'Ota' : '大田区'}</MenuItem>
                </Select>
            </FormControl>}

            {myProfile.prefecture === kanagawa &&
            <FormControl className={classes.selectForm} margin="normal" variant="outlined"
                         {...(!isValidCombination(myProfile.prefecture, myProfile.city_name) ? {error: true} : {error: false})}>
                <InputLabel
                    id="city-simple-select-outlined-label">{isEnglish ? 'City Name' : '市区町村名'}</InputLabel>
                <Select
                    labelId="city-simple-select-outlined-label"
                    id="city-simple-select-outlined"
                    value={myProfile.city_name}
                    onChange={handleInputChange()}
                    label={isEnglish ? 'City Name' : '市区町村名'}
                    name="city_name"
                    margin="normal"
                >
                    <MenuItem
                        value={isEnglish ? 'Kawasaki-shi Kawasaki' : '川崎市 川崎区'}>{isEnglish ? 'Kawasaki-shi Kawasaki' : '川崎市 川崎区'}</MenuItem>
                    <MenuItem
                        value={isEnglish ? 'Kawasaki-shi Saiwai' : '川崎市 幸区'}>{isEnglish ? 'Kawasaki-shi Saiwai' : '川崎市 幸区'}</MenuItem>
                    <MenuItem
                        value={isEnglish ? 'Kawasaki-shi Nakahara' : '川崎市 中原区'}>{isEnglish ? 'Kawasaki-shi Nakahara' : '川崎市 中原区'}</MenuItem>
                    <MenuItem
                        value={isEnglish ? 'Yokohama-shi Kohoku' : '横浜市 港北区'}>{isEnglish ? 'Yokohama-shi Kohoku' : '横浜市 港北区'}</MenuItem>
                    <MenuItem
                        value={isEnglish ? 'Yokohama-shi Tsurumi' : '横浜市 鶴見区'}>{isEnglish ? 'Yokohama-shi Tsurumi' : '横浜市 鶴見区'}</MenuItem>
                </Select>
            </FormControl>}

            <TextField
                variant="outlined" margin="normal"
                fullWidth
                label={isEnglish ? 'Place Name and Address' : '地名・番地'}
                name="place_number"
                defaultValue={myProfile.place_number}
                onChange={handleInputChange()}
            />
            <TextField
                variant="outlined" margin="normal"
                fullWidth
                label={isEnglish ? 'Building name, floor and room number' : '建物名・階・部屋番号'}
                name="building_floor_room_name"
                defaultValue={myProfile.building_floor_room_name}
                onChange={handleInputChange()}
            />

            <TextField
                variant="outlined" margin="normal"
                fullWidth
                label={isEnglish ? LABEL_PHONE_NUMBER : '電話番号'}
                name="phone_number"
                defaultValue={myProfile.phone_number}
                onChange={handleInputChange()}
            />

            <Button onClick={() => updateProfile()} id='update'></Button>
        </div>
    )
}

export default CostcoAddressForm