import React, {useEffect, useState} from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {useWindowDimensions} from "../utils/useWindowDimensions";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    image: {
        maxWidth: 200,
        maxHeight: 200,
    },
}))


const CartProducts = props => {
    const classes = useStyles()
    const {cart, products, setCart, isEnglish} = props
    const dict = {}
    const list = []
    const [modalImage, setModalImage] = useState('')

    const [open, setOpen] = React.useState(false);
    const [modalProduct, setModalProduct] = useState([])

    // 現在の画面サイズを取得
    // const {width} = props
    const {width} = useWindowDimensions()
    const [size, setSize] = useState('')
    useEffect(() => {
        if (width >= 1920) {
            setSize('xl')
        } else if (width >= 1280) {
            setSize('lg')
        } else if (width >= 960) {
            setSize('md')
        } else if (width >= 600) {
            setSize('sm')
        } else {
            setSize('xs')
        }
    }, [width, size])


    const handleOpen = (productId) => {
        setOpen(true)
        const product = products.find(product => product.id === Number(productId))
        setModalProduct(product)
        setModalImage(product.image.replace('http', 'https'))
    };

    const handleClose = () => {
        setOpen(false);
    };

    const plusQuantity = (productId) => {
        setCart(cart.concat(products.find(product => product.id === Number(productId))))
    }

    const minusQuantity = (productId) => {
        const targetIndex = cart.findIndex(product => (
            product.id === Number(productId)
        ))

        setCart(cart.filter((product, index) => (
            index !== targetIndex
        )))
    }

    const deleteCartItem = (productId) => {
        setCart(cart.filter(product => (
            product.id !== Number(productId)
        )))
    }

    for (const product of cart) {
        const id = product.id
        if (dict[id]) {
            dict[id] += 1
        } else {
            dict[id] = 1
        }
    }
    try {
        if (size !== 'xs') {
            // pcサイズ
            for (let key in dict) {
                const targetProduct = products.filter(product => (product.id === Number(key)))
                list.push(
                    <TableRow key={key} style={{height: '200px'}}>
                        <TableCell component='th' scope='row' onClick={() => handleOpen(targetProduct[0].id)}>
                            <img src={targetProduct[0].image.replace('http', 'https')} alt={targetProduct.name}
                                 className={classes.image} height='100%' width='auto'/>
                        </TableCell>
                        <TableCell>{isEnglish ? targetProduct[0].english_name : targetProduct[0].name}</TableCell>
                        <TableCell
                            style={{color: 'red'}}>¥{targetProduct[0].marked_up_price}</TableCell>
                        <TableCell><Button
                            onClick={() => minusQuantity(key)}
                            style={{color: 'rgba(0, 0, 0, 0.54'}}><IndeterminateCheckBoxIcon/></Button></TableCell>
                        <TableCell>{dict[key]}</TableCell>
                        <TableCell><Button onClick={() => plusQuantity(key)}
                                           style={{color: 'rgba(0, 0, 0, 0.54'}}><AddBoxIcon/></Button></TableCell>
                        <TableCell style={{color: 'red'}}><span
                            style={{color: 'black'}}>{isEnglish ? 'Total ' : '小計 '}</span>¥{targetProduct[0].marked_up_price * dict[key]}
                        </TableCell>
                        <TableCell><Button
                            onClick={() => deleteCartItem(key)}
                            style={{color: 'rgba(0, 0, 0, 0.54'}}><DeleteForeverIcon/></Button></TableCell>
                    </TableRow>
                )
            }
        } else {
            // スマホサイズ
            for (let key in dict) {
                const targetProduct = products.filter(product => (product.id === Number(key)))
                list.push(
                    <Grid item xs={12} key={key}>
                        <Card>
                            <CardActionArea>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <CardMedia
                                            image={targetProduct[0].image.replace('http', 'https')}
                                            title={targetProduct[0].name}
                                            style={{
                                                width: '100px',
                                                height: '100px',
                                                display: 'inline-block',
                                                marginLeft: '1rem',
                                                marginTop: '1rem',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <CardContent style={{display: 'inline-block', paddingBottom: '0'}}>
                                            <Typography gutterBottom variant="subtitle1" component="h2">
                                                {isEnglish ? targetProduct[0].english_name : targetProduct[0].name}
                                            </Typography>
                                            <p style={{color: 'red', marginRight: '0', marginLeft: '0'}}>
                                                {/*¥{round(targetProduct[0].marked_up_price, -1)}*/}
                                                ¥{targetProduct[0].marked_up_price}
                                            </p>
                                        </CardContent>
                                    </Grid>
                                </Grid>
                            </CardActionArea>

                            <CardActions style={{padding: '0', display: 'flex', justifyContent: 'center'}}>
                                <Button onClick={() => minusQuantity(key)} style={{color: 'rgba(0, 0, 0, 0.54'}}>
                                    <IndeterminateCheckBoxIcon/>
                                    {/*<RemoveIcon style={{border: 'solid 2px', borderRadius: '50%'}}/>*/}
                                </Button>

                                <p style={{marginRight: '0', marginLeft: '0'}}>{dict[key]}</p>

                                <Button onClick={() => plusQuantity(key)} style={{color: 'rgba(0, 0, 0, 0.54'}}>
                                    <AddBoxIcon/>
                                    {/*<AddIcon style={{border: 'solid 2px', borderRadius: '50%'}}/>*/}
                                </Button>

                                <Typography variant="h6" component="p" style={{color: 'red'}}>
                                    ¥{targetProduct[0].marked_up_price * dict[key]}
                                    {/*¥{round(targetProduct[0].marked_up_price, -1) * dict[key]}*/}
                                </Typography>

                                <Button onClick={() => deleteCartItem(key)}
                                        style={{color: 'rgba(0, 0, 0, 0.54'}}><DeleteForeverIcon/>
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                )
            }
        }
    } catch (e) {
        setCart([])
        window.location.href = isEnglish ? '/error' : '/error_e'
    }

    return (
        <>
            {cart.length !== 0 ?
                // カートが空ではない時
                (size !== 'xs' ?
                        // pcサイズ
                        (width >= 1280 ?
                                // xl
                                <TableContainer component={Paper}>
                                    <Table className={classes.table}>
                                        <TableBody>
                                            {list}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                // lg以下
                                <TableContainer component={Paper} style={{width: `${width - 8}px`}}>
                                    <Table className={classes.table}>
                                        <TableBody>
                                            {list}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                        )
                        // スマホサイズ
                        :
                        <Grid container spacing={1} style={{width: `${width - 8}px`}}>
                            {list}
                        </Grid>
                )
                :
                // カートが空の時
                <Typography>カートは空です</Typography>
            }
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogActions>
                        <Button onClick={handleClose} color="default">
                            <CloseIcon/>
                        </Button>
                    </DialogActions>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <img src={modalImage} alt={modalProduct.id} width='100%' height='100%'/>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        </>
    )
}

export default CartProducts
