import React, {useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import {makeStyles} from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Typography} from "@material-ui/core";
import DeliveryTime from "./DeliveryTime";
import axios from "axios";
import {COSTCO_AREA_SCHEDULER_URL, MY_PROFILE_URL} from "../../Messages/urls";
import {withCookies} from "react-cookie";
import {isValidArea} from "../utils/func";


const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        margin: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        top: '13px',
        right: '13px',
        color: 'black',
    },
    titleWrapper: {
        color: '#424242',
        paddingLeft: '2rem',
        paddingBottom: '1.2rem',
    },
    tabActiveCostco: {
        backgroundColor: 'skyblue',
    },
    tabActiveSelect: {
        backgroundColor: 'lightgreen',
    },
    tabActiveIkea: {
        backgroundColor: 'lightyellow',
    },
    tabDisActive: {
        backgroundColor: 'white',
    },
    customTabRoot: {
        backgroundColor: "gray"
    },
    customTabIndicator: {
        backgroundColor: "#afeeee"
    }

}))


const DeliveryTimeDialog = (props) => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '/e' || currentUrl.slice(-2) === '_e'
    const classes = useStyles()

    const token = props.cookies.get('current-token')
    const [myProfile, setMyProfile] = useState()
    useEffect(() => {
        // Profileを取得
        axios.get(MY_PROFILE_URL, {headers: {'Authorization': `Token ${token}`}})
            .then(res => {
                setMyProfile(res.data[0])
            })
    }, [token])


    // 0: costco, 1: husky select
    const {open, setOpen} = props

    const handleClose = () => {
        setOpen(false)
    }

    const [areaScheduler, setAreaScheduler] = useState()
    useEffect(() => {
        axios.get(COSTCO_AREA_SCHEDULER_URL)
            .then(res => {
                setAreaScheduler(res.data)
            })
    }, [])

    const [validCityName, setValidCityName] = useState()
    useEffect(() => {
        if (myProfile) {
            if (isValidArea(myProfile.city_name)) {
                setValidCityName(myProfile.city_name)
            }
        }
    }, [myProfile])


    return (
        <div>
            <Dialog
                style={{marginTop: '2.2rem', marginBottom: '2.2rem'}}
                maxWidth='xs'
                open={open}
                onClose={handleClose}>
                <div style={{border: '7px solid #afeeee'}}>
                    <IconButton onClick={() => handleClose()} className={classes.closeButton}>
                        <CloseIcon/>
                    </IconButton>
                    <DialogTitle className={classes.titleWrapper}>
                        <Typography style={{fontSize: '20px'}}>
                            {isEnglish ? 'Delivery frame' : 'お届け枠状況'}
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{paddingBottom: '0'}}>
                        {areaScheduler && <DeliveryTime areaScheduler={areaScheduler} validCityName={validCityName}/>}
                    </DialogContent>
                </div>
            </Dialog>
        </div>
    )
}

export default withCookies(DeliveryTimeDialog)