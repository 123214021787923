import React from "react";
import Footer from "../Footer";
import AdminHome from "../admin/AdminHome";
import NavBar from "../NavBar/NavBar";


const AdminPage = () => {
    return (
        <div className='pageWrapper'>
            <NavBar/>
            <AdminHome/>
            <Footer/>
        </div>
    )
}


export default AdminPage