import React, {useState} from "react";
import TopPageTemplate from "../templates/TopPageTemplate";

const TopPage = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [isDisplay, setIsDisplay] = useState(false)
    const huskyWalk = 'husky_walk_2x.gif'

    setTimeout(() => {
        setIsOpen(true)
    }, 1200)
    setTimeout(() => {
        setIsDisplay(true)
    }, 2700)

    return (
        <>
            <div
                style={{
                    backgroundColor: 'white',
                    position: 'fixed',
                    top: '0px',
                    left: '0px',
                    width: '100vw',
                    height: '100vh',
                    zIndex: '9999',
                    textAlign: 'center',
                    opacity: isOpen ? 0 : 1,
                    // opacity: 1,
                    transition: '1.5s',
                    display: isDisplay ? 'none' : 'block'
                }}
            >
                <img src={huskyWalk} alt="topImage"
                    // width='25%'
                     height='45px'
                     style={{marginTop: '40vh'}}
                />
            </div>
            <TopPageTemplate/>
        </>
    )
}

export default TopPage