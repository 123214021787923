import React from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer";
import {Container} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";


const useStyles = makeStyles((theme) => ({
    title: {
        marginTop: theme.spacing(1),
        textAlign: 'center',
        fontFamily: 'American Typewriter',
        fontWeight: 'bolder'
    },
    titleImg: {
        marginTop: theme.spacing(2),
        width: '50%',
        [theme.breakpoints.only('xs')]: {
            width: '100%',
        },
    },
}))

const ReceiptDownloadError = () => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'
    const classes = useStyles()
    const titleJp = 'images/title_error_jp.jpeg'
    const titleEng = 'images/title_error_eng.jpeg'

    return (
        <div className="pageWrapper">
            <NavBar/>

            {/*エラーページメイン*/}
            <Container maxWidth='lg' style={{paddingLeft: '8px', paddingRight: '8px'}}>
                <div style={{borderBottom: 'solid #afeeee'}}>
                    <div style={{textAlign: 'center'}}>
                        <img src={isEnglish ? titleEng : titleJp} alt="title" className={classes.titleImg}/>
                    </div>
                    <div>
                        <Button onClick={() => window.location.href = isEnglish ? '/e': '/'}>
                            {isEnglish ? 'Back Home' : 'ホーム戻る'}
                        </Button>

                        <Button style={{float: 'right'}} onClick={() => window.location.href = isEnglish ? 'contact_e': 'contact'}>
                            {isEnglish ? 'Contact us' : 'お問い合わせ'}
                        </Button>
                    </div>
                </div>

                <Container maxWidth='md'>
                    <div style={{whiteSpace: 'pre-wrap', marginTop: '2rem', marginBottom: '2rem'}}>
                        {isEnglish ?
                            'The request could not be sent successfully.\n\nWe apologize for this, please try again.' :
                            '正常にリクエスト送信ができませんでした。\n\n大変申し訳ございませんが再度お試しください。'
                        }
                    </div>
                </Container>

            </Container>

            {/*エラーページ終わり*/}

            <Footer/>
        </div>
    )
}

export default ReceiptDownloadError