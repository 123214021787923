import React from 'react';
import {createMuiTheme, ThemeProvider} from "@material-ui/core";
import TopPage from "./components/pages/TopPage";
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import LoginPage from "./components/pages/LoginPage";
import RegisterPage from "./components/pages/RegisterPage";
import ProfilePage from "./components/pages/ProfilePage";
import {withCookies} from "react-cookie";
import CostcoPage from "./components/pages/CostcoPage";
import PrivacyPolicyPage from "./components/pages/PrivacyPolicyPage";
import OperatingInfoPage from "./components/pages/OperatingInfoPage";
import UsageContractPage from "./components/pages/UsageContractPage";
import PasswordReset from "./components/pages/PasswordReset";
import ForgetPasswordPage from "./components/pages/ForgetPasswordPage";
import ContactUsPage from "./components/pages/ContactUsPage";
import HowToUsePage from "./components/pages/HowToUsePage";
import LoginForCostcoPage from "./components/pages/LoginForCostcoPage";
import CostcoConfirm from "./components/Costco/CostcoConfirm";
import UserPage from "./components/pages/UserPage";
import PurchaseHistoryPage from "./components/pages/PurchaseHistoryPage";
import AdminPage from "./components/pages/AdminPage";
import DisplayPricePage from "./components/pages/DisplayPricePage";
import ErrorPage from "./components/pages/ErrorPage";
import HowToCreditOnDelivery from "./components/pages/HowToCreditOnDelivery";
import KenNobukawaPage from "./components/pages/KenNobukawaPage";
import CovidPage from "./components/pages/CovidPage";
import PossessionCouponPage from "./components/pages/PossessionCouponPage";
import InviteFriendPage from "./components/pages/InviteFriendPage";
import PromotionPage from "./components/pages/PromotionPage";
import ReceiptDownloadPage from "./components/pages/ReceiptDownloadPage";
import CostcoOrderSummaryPage from "./components/pages/CosctoOrderSummaryPage";
import TmpPage from "./components/pages/TmpPage";
import Tmp2Page from "./components/pages/Tmp2Page";
import NotFoundPage from "./components/pages/NotFoundPage";
import AreaSchedulerPage from "./components/pages/AreaSchedulerPage";
import CompanyPage from "./components/pages/CompanyPage";

// テーマ
const theme = createMuiTheme({
    palette: {
        primary: {
            // main: '#00ced1',
            main: '#afeeee',
        },
        secondary: {
            main: '#f44336'
        },
    },
})

const App = () => {

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Switch>
                    {/*日本語ページ*/}
                    <Route exact path='/' component={TopPage}/>
                    <Route exact path='/login' component={LoginPage}/>
                    <Route exact path='/login_costco' component={LoginForCostcoPage}/>
                    <Route exact path='/register' component={RegisterPage}/>
                    <Route exact path='/profile' component={ProfilePage}/>
                    <Route exact path='/costco' component={CostcoPage}/>
                    <Route exact path='/privacy_policy' component={PrivacyPolicyPage}/>
                    <Route exact path='/info' component={OperatingInfoPage}/>
                    <Route exact path='/usage_contract' component={UsageContractPage}/>
                    <Route exact path='/forget_password' component={ForgetPasswordPage}/>
                    <Route exact path='/password_reset' component={PasswordReset}/>
                    <Route exact path='/contact' component={ContactUsPage}/>
                    <Route exact path='/how_to_use' component={HowToUsePage}/>
                    <Route exact path='/cart_confirm' component={CostcoConfirm}/>
                    <Route exact path='/my_page' component={UserPage}/>
                    <Route exact path='/history' component={PurchaseHistoryPage}/>
                    <Route exact path='/error' component={ErrorPage}/>
                    <Route exact path='/credit_on_delivery' component={HowToCreditOnDelivery}/>
                    <Route exact path='/ceo_profile' component={KenNobukawaPage}/>

                    <Route exact path='/covid' component={CovidPage}/>
                    <Route exact path='/coupon' component={PossessionCouponPage}/>
                    <Route exact path='/invite' component={InviteFriendPage}/>
                    <Route exact path='/promotion' component={PromotionPage}/>
                    <Route exact path='/receipt_dl' component={ReceiptDownloadPage}/>
                    <Route exact path='/notice' component={TmpPage}/>
                    <Route exact path='/notice2' component={Tmp2Page}/>
                    <Route exact path='/company' component={CompanyPage}/>


                    {/*英語ページ*/}
                    <Route exact path='/e' component={TopPage}/>
                    <Route exact path='/login_e' component={LoginPage}/>
                    <Route exact path='/login_costco_e' component={LoginForCostcoPage}/>
                    <Route exact path='/register_e' component={RegisterPage}/>
                    <Route exact path='/profile_e' component={ProfilePage}/>
                    <Route exact path='/costco_e' component={CostcoPage}/>
                    <Route exact path='/privacy_policy_e' component={PrivacyPolicyPage}/>
                    <Route exact path='/info_e' component={OperatingInfoPage}/>
                    <Route exact path='/usage_contract_e' component={UsageContractPage}/>
                    <Route exact path='/forget_password_e' component={ForgetPasswordPage}/>
                    <Route exact path='/contact_e' component={ContactUsPage}/>
                    <Route exact path='/how_to_use_e' component={HowToUsePage}/>
                    <Route exact path='/my_page_e' component={UserPage}/>
                    <Route exact path='/history_e' component={PurchaseHistoryPage}/>
                    <Route exact path='/error_e' component={ErrorPage}/>
                    <Route exact path='/credit_on_delivery_e' component={HowToCreditOnDelivery}/>
                    <Route exact path='/ceo_profile_e' component={KenNobukawaPage}/>

                    <Route exact path='/covid_e' component={CovidPage}/>
                    <Route exact path='/coupon_e' component={PossessionCouponPage}/>
                    <Route exact path='/invite_e' component={InviteFriendPage}/>
                    <Route exact path='/promotion_e' component={PromotionPage}/>
                    <Route exact path='/receipt_dl_e' component={ReceiptDownloadPage}/>
                    <Route exact path='/notice_e' component={TmpPage}/>
                    <Route exact path='/notice2_e' component={Tmp2Page}/>
                    <Route exact path='/company_e' component={CompanyPage}/>


                    <Route exact path='/admin' component={AdminPage}/>
                    <Route exact path='/admin_price' component={DisplayPricePage}/>
                    <Route exact path='/costco_order_summary' component={CostcoOrderSummaryPage}/>
                    <Route exact path='/area_scheduler' component={AreaSchedulerPage}/>

                    <Route component={NotFoundPage}/>
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default withCookies(App);
