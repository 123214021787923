import React from "react";
import Register from "../Register";
import Footer from "../Footer";
import NavBar from "../NavBar/NavBar";
import RegisterTop from "../register/RegisterTop";


const RegisterPage = () => {

    return (
        <div className='pageWrapper'>
            <NavBar/>
            {/*<Register/>*/}
            <RegisterTop/>
            <Footer/>
        </div>
    )
}

export default RegisterPage