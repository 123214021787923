import React, {useEffect, useState} from "react";
import axios from "axios";
import {COSTCO_FIRST_ORDER_URL, COSTCO_ORDER_URL, MY_PROFILE_URL} from "../../Messages/urls";
import {withCookies} from "react-cookie";
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
        // minWidth: 650,
    },
});


const CostcoOrderSummary = (props) => {

    const adminIdList = [2, 3, 7, 8, 10]
    const token = props.cookies.get('current-token')
    const classes = useStyles()
    const [myProfile, setMyProfile] = useState()
    const [order, setOrder] = useState()
    const [firstOrder, setFirstOrder] = useState()

    const createData = (month, numOrder, firstOrder, Order, gap, monthOverMonth, previousMonthDifference) => {
        return {month, numOrder, firstOrder, Order, gap, monthOverMonth, previousMonthDifference}
    }

    const calcNumOrder = () => {
        let numMonthDict = {}
        if (order) {
            for (let key in order) {
                let targetOrder = order[key]
                if (targetOrder['all_complete']) {
                    let orderDate = targetOrder['delivery_date'].split(' ')[0]
                    let year = targetOrder['created_at'].substr(0, 4)
                    let zFillMonth = ('00' + orderDate.split('/')[0]).slice(-2)
                    let orderMonth = year + '-' + zFillMonth
                    if (!(orderMonth in numMonthDict)) {
                        numMonthDict[orderMonth] = 1
                    } else {
                        numMonthDict[orderMonth] += 1
                    }
                }
            }
        }
        return numMonthDict
    }

    const calcEachMonthOrderTotal = () => {
        let salesMonthDict = {}
        if (order) {
            for (let key in order) {
                let targetOrder = order[key]
                if (targetOrder['all_complete']) {
                    let orderDate = targetOrder['delivery_date'].split(' ')[0]
                    let year = targetOrder['created_at'].substr(0, 4)
                    let zFillMonth = ('00' + orderDate.split('/')[0]).slice(-2)
                    let orderMonth = year + '-' + zFillMonth
                    if (!(orderMonth in salesMonthDict)) {
                        salesMonthDict[orderMonth] = targetOrder['total']
                    } else {
                        salesMonthDict[orderMonth] += targetOrder['total']
                    }
                }
            }
        }
        return salesMonthDict
    }

    const calcEachMonthFirstOrderTotal = () => {
        let salesMonthDict = {}
        if (firstOrder) {
            for (let key in firstOrder) {
                let targetOrder = firstOrder[key]
                let orderDate = targetOrder['delivery_date'].split(' ')[0]
                let year = targetOrder['created_at'].substr(0, 4)
                let zFillMonth = ('00' + orderDate.split('/')[0]).slice(-2)
                let orderMonth = year + '-' + zFillMonth
                if (!(orderMonth in salesMonthDict)) {
                    salesMonthDict[orderMonth] = targetOrder['total']
                } else {
                    salesMonthDict[orderMonth] += targetOrder['total']
                }
            }
        }
        return salesMonthDict
    }

    const numOrderDict = calcNumOrder()
    const orderDict = calcEachMonthOrderTotal()
    const firstOrderDict = calcEachMonthFirstOrderTotal()

    const rows = []

    for (let key in orderDict) {
        rows.push(createData(key, numOrderDict[key], firstOrderDict[key], orderDict[key], firstOrderDict[key] - orderDict[key]))
    }

    rows.sort(function (a, b) {
        if (a.month < b.month) {
            return -1;
        } else {
            return 1;
        }
    });

    const createMonthRateFormat = (ratio) => {
        if (ratio >= 1.0) {
            const result = Math.round((ratio - 1) * 100)
            return (
                <TableCell align="right" style={{color: 'green'}}>↑ {result} %</TableCell>
            )
        } else {
            const result = Math.round((1 - ratio) * 100)
            return (
                <TableCell align="right" style={{color: 'red'}}>↓ {result} %</TableCell>
            )
        }
    }

    const createMonthDiffFormat = (diff) => {
        if (diff > 0) {
            return (
                <TableCell align="right" style={{color: 'green'}}>+{diff}</TableCell>
            )
        } else {
            return (
                <TableCell align="right" style={{color: 'red'}}>{diff}</TableCell>
            )
        }
    }


    useEffect(() => {
        if (!token) {
            window.location.href = 'login'
        }
    }, [token])

    useEffect(() => {
        // Profileを取得
        axios.get(MY_PROFILE_URL, {headers: {'Authorization': `Token ${token}`}})
            .then(res => {
                setMyProfile(res.data[0])
            })
    }, [token])

    useEffect(() => {
        axios.get(COSTCO_ORDER_URL)
            .then(res => {
                setOrder(res.data)
            })

        axios.get(COSTCO_FIRST_ORDER_URL)
            .then(res => {
                setFirstOrder(res.data)
            })
    }, [])

    return (
        <>
            {myProfile && adminIdList.includes(myProfile.id) &&
            <div>
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align='right'>Month</TableCell>
                                <TableCell align='right'>オーダー件数</TableCell>
                                <TableCell align="right">First Order</TableCell>
                                <TableCell align="right">Order</TableCell>
                                <TableCell align="right">gap</TableCell>
                                <TableCell align="right">先月比(order)</TableCell>
                                <TableCell align="right">先月差(order)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow key={row.month}>
                                    <TableCell align="right">{row.month}</TableCell>
                                    <TableCell align="right">{row.numOrder}</TableCell>
                                    <TableCell align="right">{Number(row.firstOrder).toLocaleString()}</TableCell>
                                    <TableCell align="right">{Number(row.Order).toLocaleString()}</TableCell>
                                    <TableCell align="right">{Number(row.gap).toLocaleString()}</TableCell>
                                    {index === 0 ?
                                        <>
                                            <TableCell align="right"> </TableCell>
                                            <TableCell align="right"> </TableCell>
                                        </> :

                                        <>
                                            {createMonthRateFormat(row.Order / rows[index - 1].Order)}
                                            {createMonthDiffFormat(row.Order - rows[index - 1].Order)}
                                        </>
                                    }

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>


            </div>}
        </>
    )
}

export default withCookies(CostcoOrderSummary)