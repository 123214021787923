import React, {useContext} from "react";
import Grid from "@material-ui/core/Grid";
import CategoryList from "./CategoryList";
import ProductList from "./ProductList";
import {CostcoContext} from "./CostcoHome";
import PopUpDialog from "../Top/PopUpDialog";


const Products = () => {
    const {classes} = useContext(CostcoContext)

    return (
        <Grid container>
            <PopUpDialog/>

            <Grid item lg={2}>
                <CategoryList/>
            </Grid>
            <Grid item lg={10} className={classes.heroButtons}>
                <ProductList/>
            </Grid>
        </Grid>
    )
}

export default Products