import React, {useContext} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axios from "axios";
import {AUTHENTICATION_CODE_URL, AUTHENTICATION_MAIL_URL} from "../../Messages/urls";
import {RegisterContext} from "./RegisterTop";


const EmailForm = () => {
    const {
        isEnglish,
        setActiveStep,
        invitationCode,
        setAuthenticationCode,
        email,
        setEmail
    } = useContext(RegisterContext)

    const inputChangeEmail = () => event => {
        setEmail(event.target.value)
    }

    const sendAuthenticationMail = async () => {
        const data = {
            'email': email,
            'invitation_code': invitationCode
        }
        await axios.post(AUTHENTICATION_CODE_URL, data, {
            headers: {'Content-Type': 'application/json'}
        }).then(res => {
            setAuthenticationCode(res.data.code)
        })
        setActiveStep(1)
        await axios.get(`${AUTHENTICATION_MAIL_URL}${email}/${isEnglish ? "eng" : "jp"}`)
    }


    return (
        <div>
            <TextField
                variant="outlined" margin="normal"
                fullWidth
                placeholder='support@husky-cart.com'
                label={isEnglish ? 'Email' : 'メールアドレス'}
                name="email"
                value={email}
                onChange={inputChangeEmail()}
            />
            <Button onClick={sendAuthenticationMail}
                    style={{marginTop: '1rem'}}
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    disabled={email.length <= 6}
            >
                {isEnglish ? 'Send' : '送信'}
            </Button>

        </div>
    )
}

export default EmailForm