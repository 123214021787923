// NavBar
export const HuskyCart = 'Husky Cart'
export const LOGIN = 'LOGIN'
export const REGISTER = 'REGISTER'
export const BACK = 'BACK'
export const FORGET_PASSWORD = 'Forgot password?'
export const MY_PROFILE = ' My Profile'

// MiddleTop
export const STORE_LIST = '【Store List】'  // 【ストア一覧】
export const COSTCO = 'Costco'  // コストコ
// アメリカ大手スーパーマーケット商品がど迫力！！
export const COSTCO_DETAIL = 'Major American supermarket products are so powerful!'
export const ANYCART = 'Any Cart'
// 欲しいもの予算などから提案いたします！何でもカゴ
export const ANYCART_DETAIL = 'We\'ll make suggestions based on what you want, your budget, etc.!'
export const COMING_SOON = 'Coming soon'
export const STORE_OVERVIEW = 'Store Overview'  // 店舗概要

// LowerTop
export const AREA_TITLE = '【Areas currently being delivered】'  // 【現在配達対応中エリア】
// export const COVERAGE_AREA = '・港区全域 ・渋谷区（一部） ・目黒区（一部） ・川崎区（一部）'
export const COVERAGE_AREA = '・Minato-ku ・Shibuya-ku ・Meguro-ku ・Kawasaki-ku'

// Footer
export const HOW_TO_USE = 'How to use'  // 使い方
export const FREQ_QUESTIN = 'Frequently Asked Questions'  // よくある質問
export const CONTACT_US = 'Contact us'
export const PRIVACY_POLICY = 'Privacy policy'

// Profile
export const LABEL_NAME = 'Name'  // 名前
export const LABEL_ADDRESS = 'Address'  // 住所
export const LABEL_PHONE_NUMBER = 'Phone Number'  // 電話番号

// Button
export const BUTTON_UPDATE = 'UPDATE'  // 更新
export const BUTTON_EDIT = 'EDIT'  // 編集
export const BUTTON_CREATE = 'CREATE'  // 作成
export const BUTTON_SEND = 'SEND'  // 送信

// Any Cart
export const LABEL_PRODUCT_NAME = 'Product Name'  // 商品名
export const LABEL_BUDGET = 'Budget'  // 予算
export const LABEL_EXTRA_DETAIL = 'Extra Detail'  //  その他詳細
export const LABEL_NAME_ON_CARD = 'Name on card'  //　カード氏名
export const LABEL_CARD_NUMBER = 'Card number'  //　カード番号
export const LABEL_EXPIRY_DATE = 'Expiry date'  //　期限
export const LABEL_CVV = 'CVV'  //  CVV
export const PAYMENT_METHOD = 'Payment method'  // 支払い方法
export const PRODUCT_INFO = 'Product info'  // 製品情報
export const ORDER_SUMMARY = 'Order summary'  // 注文の概要
export const YOUR_ORDER = 'Your Order'  // 注文
export const USER_INFO = 'User Info'  // ユーザー情報
export const PAYMENT_DETAILS = 'Payment details'  // 支払い情報詳細
export const NEXT = 'Next'  // 次へ
export const REVIEW_YOUR_ORDER = 'Review your order'  // 注文概要
export const PLACE_ORDER = 'Place order'  // 注文を確定する
export const THANKS_ORDER = 'Thank you for your order.'  // ご注文ありがとうございます。
// Costco
export const DETAIL = 'Detail'  // 詳細
export const ADD_CART = 'Add Cart'  // カートに追加
export const CATEGORY = 'Category List'  // カテゴリー一覧