import React from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer";
import InviteFriend from "../Profile/InviteFriend";

const InviteFriendPage = () => {
    return (
        <div className="pageWrapper">
            <NavBar/>
            <InviteFriend/>
            <Footer/>
        </div>
    )
}

export default InviteFriendPage