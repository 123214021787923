import React, {useContext, useEffect, useState} from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {CostcoContext} from "../CostcoHome";
import axios from "axios"
import {COSTCO_AREA_SCHEDULER_URL} from "../../../Messages/urls";
import {CircularProgress} from "@material-ui/core";


const timeZone = [
    '12:00 ~ 13:00',
    '13:00 ~ 14:00',
    '14:00 ~ 15:00',
    '15:00 ~ 16:00',
    '16:00 ~ 17:00',
    '17:00 ~ 18:00',
    '18:00 ~ 19:00',
    '19:00 ~ 20:00',
    '20:00 ~ 21:00',
    '21:00 ~ 22:00',
]

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const CostcoReservationForm = (props) => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'
    const {setDeliveryDay, myProfile} = useContext(CostcoContext)
    const {setIsSelected} = props

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const date2String = (date) => {
        const month = date.getMonth() + 1
        const day = date.getDate()
        const dayOfWeek = date.getDay()
        const dayOfWeekStr = isEnglish ? ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][dayOfWeek] : ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek]
        return month + "/" + day + "(" + dayOfWeekStr + ")"
    }
    const createDateList = () => {
        const dateList = []
        const today = new Date()
        for (let i = 1; i <= 7; i++) {
            if (i !== 1) {
                today.setDate(today.getDate() + 1)
            }
            dateList.push(date2String(today))
        }
        return dateList
    }

    const selected = (id) => {
        const availables = document.getElementsByClassName("available")
        const availablesArray = Array.from(availables);
        availablesArray.forEach(x => x.style.backgroundColor = "white")
        availablesArray.forEach(x => x.textContent = "○")

        document.getElementById(id).style.backgroundColor = "#afeeee"
        document.getElementById(id).textContent = isEnglish ? "Selected" : "選択中"

        setDeliveryDay(id)
        setIsSelected(true)
    }

    const convertSymbol = (value, id) => {
        let closeFrameNum = 0
        if (id.split('_')[0] === '1') {
            const today = new Date()
            const nowHour = today.getHours()
            closeFrameNum = nowHour >= 18 ? 10 : nowHour - 9
        }
        const time = Number(id.split('_')[1])

        if (closeFrameNum > time) {
            return <TableCell align="center">×</TableCell>
        }
        switch (value) {
            case '0':
                return <TableCell align="center">×</TableCell>
            case '1':
                return <TableCell align="center" className="available" id={id}
                                  onClick={() => selected(id)}>○</TableCell>
            default:
                return <TableCell align="center">×</TableCell>
        }
    }


    const [scheduler, setScheduler] = useState()
    useEffect(() => {
        if (myProfile) {
            const query = isEnglish ? 'english_name' : 'name'
            axios.get(`${COSTCO_AREA_SCHEDULER_URL}?${query}=${myProfile.city_name}`)
                .then(res => {
                    setScheduler(res.data[0])
                })
        }
    }, [myProfile])


    return (
        <>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="scrollable"
                      scrollButtons="auto">
                    {createDateList().map((date, index) => (
                        <Tab label={date} {...a11yProps(index)} style={{textTransform: 'none'}}/>
                    ))}
                </Tabs>
            </AppBar>

            {scheduler ? createDateList().map((date, index) => (
                    <TabPanel value={value} index={index} style={{borderRadius: 'none', paddingBottom: '2rem'}}>
                        <TableContainer component={Paper} square>
                            <Table aria-label="simple table">
                                <TableBody>
                                    {scheduler[`day${index + 1}`].split(',').map((value, id) => (
                                        <TableRow key={id}>
                                            <TableCell align='center' style={{width: "50%"}}>
                                                {timeZone[id]}
                                            </TableCell>
                                            {convertSymbol(value, `${index + 1}_${id}`)}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                ))
                :
                <CircularProgress/>
            }


        </>
    )
}

export default CostcoReservationForm