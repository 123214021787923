import React from "react";
import {LABEL_PHONE_NUMBER} from "../../Messages/constantMessages";
import {makeStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import axios from "axios";
import {CONTACT_US_URL} from "../../Messages/urls";


const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%'
    },
    actionButton: {
        marginTop: '1rem',
    }
}));

const Confirm = (props) => {
    const classes = useStyles()
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'
    const {contactData, activeStep, setActiveStep} = props

    const submitMail = () => {
        const name = contactData.last_name + '_' + contactData.first_name
        const phoneNumber = contactData.phone_number ? contactData.phone_number : 'none'
        const email = contactData.email ? contactData.email : 'none'
        const orderNumber = contactData.order_number ? contactData.order_number : 'none'
        const store = contactData.store ? contactData.store : 'none'
        const content = contactData.content ? contactData.content : 'none'
         const language = isEnglish ? 'eng': 'jp'

        axios.get(`${CONTACT_US_URL}${name}/${phoneNumber}/${email}/${orderNumber}/${store}/${content}/${language}`)
        setActiveStep(activeStep + 1)
    }

    return (
        <div>
            <table bgcolor="#999999" border="0" cellPadding="9" cellSpacing="1"
                   className='tableWrapper'
            >
                <tr>
                    <th bgcolor="#EEEEEE">{isEnglish ? 'Name' : '名前'}</th>
                    <td bgcolor="#FFFFFF">{contactData.last_name} {contactData.first_name}</td>
                </tr>
                <tr>
                    <th bgcolor="#EEEEEE">{isEnglish ? LABEL_PHONE_NUMBER : '電話番号'}</th>
                    <td bgcolor="#FFFFFF">{contactData.phone_number}</td>
                </tr>
                <tr>
                    <th bgcolor="#EEEEEE">{isEnglish ? 'Email' : 'メールアドレス'}</th>
                    <td bgcolor="#FFFFFF">{contactData.email}</td>
                </tr>
                <tr>
                    <th bgcolor="#EEEEEE">{isEnglish ? 'Order Number' : '注文番号'}</th>
                    <td className='tableContent' bgcolor="#FFFFFF">{contactData.order_number}</td>
                </tr>
                <tr>
                    <th bgcolor="#EEEEEE">{isEnglish ? 'Content' : '内容'}</th>
                    <td bgcolor="#FFFFFF">{contactData.content}</td>
                </tr>

            </table>


            <Button onClick={() => submitMail()}
                    className={classes.actionButton}
                    fullWidth
                    color='primary'
                    variant='contained'
            >
                {isEnglish ? 'Send' : '送信する'}
            </Button>
            <Button onClick={() => setActiveStep(activeStep - 1)}
                    className={classes.actionButton}
                    fullWidth
                    color='default'
                    variant='contained'
                    style={{marginBottom: '2rem'}}
            >
                {isEnglish ? 'Back' : '戻る'}
            </Button>
        </div>
    )
}

export default Confirm