import React, {useEffect, useState} from "react";
import axios from "axios";
import {COSTCO_AREA_SCHEDULER_URL, MY_PROFILE_URL} from "../../Messages/urls";
import {withCookies} from "react-cookie";
import AdminNoAcess from "../admin/AdminNoAccess";
import SchedulerList from "./SchedulerList";
import SchedulerTable from "./SchedulerTable";
import Button from "@material-ui/core/Button";


const AreaScheduler = (props) => {
    const token = props.cookies.get('current-token')
    const [myProfile, setMyProfile] = useState()
    // admin 内訳 2: kumeta, 3: tanabe, 7: nobukawa, 8: ooikawa, 10: nakamura
    const adminIdList = [2, 3, 7, 8, 10]

    const [areaScheduler, setAreaScheduler] = useState()
    // 0: 更新、1: 表
    const [activeStep, setActiveStep] = useState(0)

    useEffect(() => {
        // Profileを取得
        axios.get(MY_PROFILE_URL, {headers: {'Authorization': `Token ${token}`}})
            .then(res => {
                setMyProfile(res.data[0])
            })
    }, [token])

    useEffect(() => {
        axios.get(COSTCO_AREA_SCHEDULER_URL)
            .then(res => {
                setAreaScheduler(res.data)
            })
    }, [])

    const handleActiveStep = () => {
        if (activeStep === 0) {
            setActiveStep(1)
        } else {
            setActiveStep(0)
        }
    }

    return (
        <div>
            {myProfile && areaScheduler ? (
                    !adminIdList.includes(myProfile.id) ?
                        <AdminNoAcess/> :
                        activeStep === 0 ? (<SchedulerList areaScheduler={areaScheduler}/>) :
                            (<SchedulerTable areaScheduler={areaScheduler}/>)
                ) :
                <AdminNoAcess/>
            }
            <Button type='submit' fullWidth variant='contained' color='primary' onClick={handleActiveStep}
                    style={{marginBottom: '2rem'}}>
                {activeStep === 0 ? 'Scheduler Table' : 'Scheduler Frame'}
            </Button>
        </div>
    )
}


export default withCookies(AreaScheduler)