import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const TransitionComplete = () => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'

    return (
        <div>
            <Typography
                variant='h6'
                style={{marginBottom: '2rem', marginTop: '2rem', textAlign: 'center', whiteSpace: 'pre-wrap'}}
            >
                {/*{isEnglish ? 'Your message has been sent. \n' : '送信しました \n'}*/}
                {isEnglish ? 'An inquiry receipt e-mail has been automatically sent. Please check it.' : 'お問い合わせ受付メールを自動送信しました。ご確認ください。'}
            </Typography>
            <h3 style={{marginTop: '2rem', textAlign: 'center'}}>{isEnglish ? 'If you do not receive the acceptance email' : '受付メールが届かない方'}</h3>
            <ul style={{textAlign: 'left', marginBottom: '2rem'}}>
                <li style={{marginBottom: '1rem'}}>{isEnglish ? 'The e-mail address you entered may be incorrect.' : 'ご入力いただいたメールアドレスが誤っている可能性があります。'}</li>
                <li style={{marginBottom: '1rem'}}>{isEnglish ? 'It may have been sorted into your spam folder. Please check your spam folder if it does not arrive in your inbox.' : '迷惑メールフォルダに振り分けられている可能性があります。受信トレイに届かない場合には迷惑メールフォルダをご確認ください。'}</li>
                <li style={{marginBottom: '1rem'}}>{isEnglish ? 'You may be receiving our messages from a specified domain. Please check your settings.' : 'ドメイン指定受信をされている可能性があります。設定をご確認ください。'}</li>
            </ul>
            <Button
                onClick={() => isEnglish ? window.location.href = '/e' : window.location.href = '/'}
                variant='contained'
                fullWidth
                style={{marginBottom: '2rem'}}
            >
                {isEnglish ? 'home' : 'ホームに戻る'}
            </Button>
        </div>
    )
}

export default TransitionComplete