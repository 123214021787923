import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";

const SchedulerTable = (props) => {
    const {areaScheduler} = props
    const createData = (area, day1, day2, day3, day4, day5, day6, day7) => {
        return {area, day1, day2, day3, day4, day5, day6, day7};
    }
    const prefectureIndex = [16, 17]

    const date2String = (date) => {
        const month = date.getMonth() + 1
        const day = date.getDate()
        const dayOfWeek = date.getDay()
        const dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek]
        return month + "/" + day + "(" + dayOfWeekStr + ")"
    }
    const createDateList = () => {
        const dateList = []
        const today = new Date()
        for (let i = 1; i <= 7; i++) {
            if (i !== 1) {
                today.setDate(today.getDate() + 1)
            }
            dateList.push(date2String(today))
        }
        return dateList
    }

    const convertCsvToSymbol = (csvValue) => {
        const symbolList = []
        csvValue.split(',').map(value => {
            if (value === '1') {
                symbolList.push('○')
            } else {
                symbolList.push('×')
            }
        })
        return symbolList.join(' ')
    }

    const createTable = () => {
        const tableRows = []
        areaScheduler.map((scheduler) => {
            if (!prefectureIndex.includes(scheduler.id)) {
                tableRows.push(
                    createData(
                        scheduler['name'],
                        scheduler['day1'],
                        scheduler['day2'],
                        scheduler['day3'],
                        scheduler['day4'],
                        scheduler['day5'],
                        scheduler['day6'],
                        scheduler['day7'])
                )
            }
        })
        return tableRows
    }


    return (
        <div style={{padding: '0.5rem'}}>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>区域</TableCell>
                            {createDateList().map(day => (
                                <TableCell align="center" key={day}>{day}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {createTable().map(row => (
                            <TableRow key={row.area}>
                                <TableCell component="th" scope="row">{row.area}</TableCell>
                                <TableCell>{convertCsvToSymbol(row.day1)}</TableCell>
                                <TableCell>{convertCsvToSymbol(row.day2)}</TableCell>
                                <TableCell>{convertCsvToSymbol(row.day3)}</TableCell>
                                <TableCell>{convertCsvToSymbol(row.day4)}</TableCell>
                                <TableCell>{convertCsvToSymbol(row.day5)}</TableCell>
                                <TableCell>{convertCsvToSymbol(row.day6)}</TableCell>
                                <TableCell>{convertCsvToSymbol(row.day7)}</TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}


export default SchedulerTable