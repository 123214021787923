import React from "react";
import Profile from "../Profile/Profile";
import NavBar from "../NavBar/NavBar";
import {withCookies} from "react-cookie";
import Footer from "../Footer";


const ProfilePage = () => {

    return (
        <div className='pageWrapper'>
            <NavBar/>
            <Profile/>
            <Footer/>
        </div>
    )
}

export default withCookies(ProfilePage)