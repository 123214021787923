import React from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer";
import Hidden from "@material-ui/core/Hidden";

const HowToUsePage = () => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'

    const howToImageXsJp = 'images/how_to_use_xs_jp.jpeg'
    const howToImageXsEng = 'images/how_to_use_xs_eng.jpeg'
    const howToImageMdJp = 'images/how_to_use_md_jp.jpeg'
    const howToImageMdEng = 'images/how_to_use_md_eng.jpeg'

    const HowToLg1 = 'images/how_to_lg1_jp.jpeg'
    const HowToLg2 = 'images/how_to_lg2_jp.jpeg'
    const HowToLg3 = 'images/how_to_lg3_jp.jpeg'
    const HowToLg4 = 'images/how_to_lg4_jp.jpeg'
    const HowToLg1Eng = 'images/how_to_lg1_eng.jpeg'
    const HowToLg2Eng = 'images/how_to_lg2_eng.jpeg'
    const HowToLg3Eng = 'images/how_to_lg3_eng.jpeg'
    const HowToLg4Eng = 'images/how_to_lg4_eng.jpeg'


    return (
        <div className="pageWrapper">
            <NavBar/>
            <Hidden smUp>
                {/*スマホサイズ*/}
                <h3 style={{textAlign: 'center'}}>
                    {isEnglish ? 'How to use' : '使い方'}
                </h3>
                <img src={isEnglish ? howToImageXsEng : howToImageXsJp} alt="images" width='100%'/>
            </Hidden>

            {/*PCサイズ*/}
            <Hidden xsDown>

                <img src={isEnglish ? HowToLg1Eng : HowToLg1} alt="" width='100%' style={{verticalAlign: 'top', marginTop: '2rem'}}/>
                <img src={isEnglish ? HowToLg2Eng : HowToLg2} alt="" width='100%' style={{verticalAlign: 'top'}}/>
                <img src={isEnglish ? HowToLg3Eng : HowToLg3} alt="" width='100%' style={{verticalAlign: 'top'}}/>
                <img src={isEnglish ? HowToLg4Eng : HowToLg4} alt="" width='100%' style={{verticalAlign: 'top'}}/>

            </Hidden>
            <Footer/>
        </div>

    )
}

export default HowToUsePage