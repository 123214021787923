import React from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer";
import '../styles/operatingInfo.css'
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";


const OperatingInfoPage = () => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'
    // const {isEnglish} = useContext(LanguageContext)

    return (
        <div className='pageWrapper'>
            <NavBar/>
            <Container maxWidth='lg' style={{marginBottom: '16px'}}>
                {isEnglish ?
                    <div>
                        {/*スマホサイズ*/}
                        <Hidden smUp>
                            <h3 style={{textAlign: 'center'}}>
                                About us
                            </h3>
                        </Hidden>

                        {/*iPadサイズ*/}
                        <Hidden xsDown lgUp>
                            <h2 style={{textAlign: 'center'}}>
                                About us
                            </h2>
                        </Hidden>

                        {/*PCサイズ*/}
                        <Hidden mdDown>
                            <h1 style={{textAlign: 'center'}}>About us</h1>
                        </Hidden>

                        <table bgcolor="#999999" border="0" cellPadding="9" cellSpacing="1"
                               className='tableWrapper'
                        >
                            <tr>
                                <th bgcolor="#EEEEEE">Company Profile</th>
                                <td bgcolor="#FFFFFF">
                                    {/*Ken Nobukawa*/}
                                    <a href="https://hubmedia.co.jp/"
                                       target='_blank'
                                       style={{color: 'blue', textDecoration: 'none'}}>
                                        Huskycart LLC
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">CEO</th>
                                <td bgcolor="#FFFFFF">
                                    <a href="/ceo_profile_e"
                                       style={{color: 'blue', textDecoration: 'none'}}>Ken Nobukawa</a>
                                </td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">location</th>
                                <td bgcolor="#FFFFFF">〒107-0062 2-2-15, Minamiaoyama, Minatoku-ku, Tokyo</td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">phone number:</th>
                                <td bgcolor="#FFFFFF">080-4780-0026 (Reception hours Monday-Sunday 10am-6pm)</td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">Email address</th>
                                <td bgcolor="#FFFFFF">
                                    {/*support@husky-cart.com*/}
                                    <a href="mailto:support@husky-cart.com"
                                       style={{textDecoration: 'none', color: 'blue'}}>
                                        support@husky-cart.com
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">Payment method</th>
                                <td bgcolor="#FFFFFF">Cash on delivery, Credit card, Credit on delivery</td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">Product delivery time</th>
                                <td bgcolor="#FFFFFF"> Same-day delivery. Shortest 2 hour~</td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">Price</th>
                                <td bgcolor="#FFFFFF">The price displayed on this website is the Huskycart price so it
                                    differs from the store price.
                                </td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">Necessary charges other than the product</th>
                                <td bgcolor="#FFFFFF" style={{whiteSpace: 'pre-wrap'}}>
                                    {"Ordering Fees: Only ¥800（Including delivery）"}
                                    {/*<Hidden smUp>*/}
                                    {/*    {"Shopping fee :\nlowest price ¥0\n(available to choice\nthe price)\n" +*/}
                                    {/*    "Delivery fee : \nlowest price ¥0\n(available to choice\nthe price)"}*/}
                                    {/*</Hidden>*/}

                                    {/*/!*PCサイズ*!/*/}
                                    {/*<Hidden smDown>*/}
                                    {/*    {"Shopping fee :\nlowest price ¥0\n(available to choice the price)\n" +*/}
                                    {/*    "Delivery fee : \nlowest price ¥0\n(available to choice the price)"}*/}
                                    {/*</Hidden>*/}
                                </td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">Return/Cancellation</th>
                                <td bgcolor="#FFFFFF">No returns after delivery/Cancel orders up to the day before
                                    shopping
                                </td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">About defective products</th>
                                <td bgcolor="#FFFFFF">For items that are different from your order or damaged or
                                    defective during delivery, we will replace it with a non-defective product if it is
                                    not in stock, refund it (transfer fee paid by us). Please contact us by email or
                                    phone within the next day after the item arrives.
                                    <br/>
                                    *Please note that we will not accept returns unless you contact us within the next
                                    day after the item arrives.
                                </td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">Out of Stock Products</th>
                                <td bgcolor="#FFFFFF" className='tableContent'>
                                    It is possible that some items may be out of stock depending on the condition of the store on that day. Please note that we are unable to change the date of your order. If you choose to ask for an alternative item and we are unable to reach you, we will cancel the item.
                                </td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">Media</th>
                                <td bgcolor="#FFFFFF" className='tableContent' style={{whiteSpace: 'pre-wrap'}}>
                                    <a href="https://hubmedia.co.jp/costco/" target="_blank" style={{textDecoration: 'none', color: 'blue'}}>Costco-johokan</a>
                                    <br />
                                    <a href="https://hubmedia.co.jp/delivery/" target="_blank" style={{textDecoration: 'none', color: 'blue'}}>Delivery-johokan</a>
                                </td>
                            </tr>
                        </table>
                    </div>
                    :
                    <div>
                        {/*スマホサイズ*/}
                        <Hidden smUp>
                            <h3 style={{textAlign: 'center'}}>特商法に基づく表示</h3>
                        </Hidden>

                        {/*iPadサイズ*/}
                        <Hidden xsDown lgUp>
                            <h2 style={{textAlign: 'center'}}>特商法に基づく表示</h2>
                        </Hidden>

                        {/*PCサイズ*/}
                        <Hidden mdDown>
                            <h1 style={{textAlign: 'center'}}>特商法に基づく表示</h1>
                        </Hidden>

                        <table bgcolor="#999999" border="0" cellPadding="9" cellSpacing="1"
                               className='tableWrapper'
                        >
                            <tr>
                                <th bgcolor="#EEEEEE">運営会社</th>
                                <td bgcolor="#FFFFFF">
                                    {/*信川 拳*/}
                                    <a href="https://hubmedia.co.jp/"
                                       target='_blank'
                                       style={{color: 'blue', textDecoration: 'none'}}>
                                        Huskycart合同会社
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">販売責任者</th>
                                <td bgcolor="#FFFFFF">
                                    {/*信川 拳*/}
                                    <a href="/ceo_profile"
                                       style={{color: 'blue', textDecoration: 'none'}}>信川 拳</a>
                                </td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">所在地</th>
                                <td bgcolor="#FFFFFF">〒107-0062  東京都港区南青山2-2-15</td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">電話番号</th>
                                <td className='tableContent' bgcolor="#FFFFFF">
                                    {"080-4780-0026 (受付時間 月曜日～日曜日\n午前10時～午後6時まで）"}
                                </td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">メールアドレス</th>
                                <td bgcolor="#FFFFFF">
                                    <a href="mailto:support@husky-cart.com"
                                       style={{textDecoration: 'none', color: 'blue'}}>
                                        support@husky-cart.com
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">支払方法</th>
                                <td bgcolor="#FFFFFF">代金引換、クレジットカード決済、配達時クレジット払い</td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">商品引渡し時期</th>
                                <td bgcolor="#FFFFFF" style={{whiteSpace: 'pre-wrap'}}>
                                    {"ご注文から最短即日に発送いたします。\n(配達日を選択できます。)"}</td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">表示価格</th>
                                <td bgcolor="#FFFFFF">本webサイトに表示されている価格はHuskycart価格の為、実際の店頭価格とは異なります。</td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">商品以外の必要料金</th>
                                <td bgcolor="#FFFFFF" className='tableContent'>
                                    {/*{"ご購入の際\n代行料:最安0円\n(お好きな金額を選べます)\n配送料:最安0円\n(お好きな金額を選べます)"}*/}
                                    {"ご注文の際の手数料：一律、800円（配送料込み）"}
                                </td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">返品・キャンセルについて</th>
                                <td bgcolor="#FFFFFF" className='tableContent'>
                                    {"配達後の返品は不可\n注文キャンセルは配達日前日まで可"}
                                </td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">不良品について</th>
                                <td bgcolor="#FFFFFF">ご注文内容と異なる、または配送時の破損・不良品につきましては、在庫がある場合は良品と交換、在庫がない場合はご返金（振込手数料当社負担）にて対応いたします。
                                    <br/>
                                    ※商品到着後の翌日以内にご連絡のない場合、ご返品は受け付けられませんのでご注意ください。
                                </td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">在庫切れ商品について</th>
                                <td bgcolor="#FFFFFF" className='tableContent'>
                                    {"当日の店舗状況により、在庫切れ商品がある可能性がございます。また買い物代行日の変更は受付られませんのでご了承ください。代替品希望(要連絡)を選択され、連絡が繋がらない場合は商品をキャンセルとさせていただきます。"}
                                </td>
                            </tr>
                            <tr>
                                <th bgcolor="#EEEEEE">運営メディア</th>
                                <td bgcolor="#FFFFFF" className='tableContent' style={{whiteSpace: 'pre-wrap'}}>
                                    <a href="https://hubmedia.co.jp/costco/" target="_blank" style={{textDecoration: 'none', color: 'blue'}}>コストコ情報館</a>
                                    <br />
                                    <a href="https://hubmedia.co.jp/delivery/" target="_blank" style={{textDecoration: 'none', color: 'blue'}}>デリバリー情報館</a>
                                </td>
                            </tr>
                        </table>
                    </div>
                }

            </Container>
            <Footer/>
        </div>
    )
}

export default OperatingInfoPage