import React, {useState} from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import axios from "axios";
import {COSTCO_AREA_SCHEDULER_URL} from "../../Messages/urls";

const useStyles = makeStyles((theme) => ({
    selected: {
        backgroundColor: '#afeeee',
    },
    closed: {
        backgroundColor: '#c0c0c0',
    },
    noChange: {
        backgroundColor: 'white',
    },
    open: {
        backgroundColor: '#90ee90'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    button: {
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}))

const timeZone = [
    '12:00 ~ 13:00',
    '13:00 ~ 14:00',
    '14:00 ~ 15:00',
    '15:00 ~ 16:00',
    '16:00 ~ 17:00',
    '17:00 ~ 18:00',
    '18:00 ~ 19:00',
    '19:00 ~ 20:00',
    '20:00 ~ 21:00',
    '21:00 ~ 22:00',
]

const TabPanel = (props) => {
    const {children, value, index, ...other} = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const SchedulerList = (props) => {
    const {areaScheduler} = props

    const classes = useStyles()
    const prefectureIndexList = [0, 11]

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const [cityNameIndex, setCityNameIndex] = useState(0)
    const handleChangeCityName = (event) => {
        setCityNameIndex(event.target.value)
        setChangeList([])
        setOpenList([])
        setCloseList([])
    };

    const date2String = (date) => {
        const month = date.getMonth() + 1
        const day = date.getDate()
        const dayOfWeek = date.getDay()
        const dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek]
        return month + "/" + day + "(" + dayOfWeekStr + ")"
    }
    const createDateList = () => {
        const dateList = []
        const today = new Date()
        for (let i = 1; i <= 7; i++) {
            if (i !== 1) {
                today.setDate(today.getDate() + 1)
            }
            dateList.push(date2String(today))
        }
        return dateList
    }

    const [changeList, setChangeList] = useState([])
    const handleChangeList = (id) => {
        if (changeList.includes(id)) {
            setChangeList(changeList.filter((changedId) => (changedId !== id)))
        } else {
            setChangeList([...changeList, id]);
        }
    }
    const convertSymbol = (value, id) => {
        let closeFrameNum = 0
        if (id.split('_')[0] === '1') {
            const today = new Date()
            const nowHour = today.getHours()
            closeFrameNum = nowHour >= 18 ? 10 : nowHour - 9
        }
        const time = Number(id.split('_')[1])

        if (closeFrameNum > time) {
            return <TableCell align="center" className={classes.closed}>×</TableCell>
        }

        switch (value) {
            case '0':
                return <TableCell align="center" className={changeList.includes(id) && classes.selected}
                                  onClick={() => handleChangeList(id)}>{changeList.includes(id) ? '○' : '×'}</TableCell>
            case '1':
                return <TableCell align="center" className={changeList.includes(id) && classes.selected}
                                  onClick={() => handleChangeList(id)}
                                  id={id}>{changeList.includes(id) ? '×' : '○'}</TableCell>
            default:
                return <TableCell align="center">×</TableCell>
        }
    }


    const [openList, setOpenList] = useState([])
    const [closeList, setCloseList] = useState([])
    const handlePrefectureSymbol = (id) => {
        // none -> open -> close -> none
        if (!openList.includes(id) && !closeList.includes(id)) {
            setOpenList([...openList, id]);
        } else {
            if (openList.includes(id)) {
                setOpenList(openList.filter((changedId) => (changedId !== id)))
                setCloseList([...closeList, id]);
            } else {
                setCloseList(closeList.filter((changedId) => (changedId !== id)))
            }
        }
    }
    const judgeSymbolPrefecture = (id) => {
        if (!openList.includes(id) && !closeList.includes(id)) {
            return '--'
        } else {
            if (openList.includes(id)) {
                return '○'
            } else {
                return '×'
            }
        }
    }
    const judgePrefectureClass = (id) => {
        if (!openList.includes(id) && !closeList.includes(id)) {
            return classes.noChange
        } else {
            if (openList.includes(id)) {
                return classes.open
            } else {
                return classes.closed
            }
        }
    }
    const convertSymbolPrefecture = (value, id) => {
        return <TableCell align="center" className={judgePrefectureClass(id)}
                          onClick={() => handlePrefectureSymbol(id)}>{judgeSymbolPrefecture(id)}</TableCell>
    }

    const overwriteScheduler = async (cityIndex) => {
        const targetScheduler = areaScheduler[cityIndex]
        let combinedValue = ''
        for (let i = 1; i < 8; i++) {
            combinedValue += targetScheduler[`day${i}`] + ','
        }
        let combinedList = combinedValue.split(',')

        openList.map((change_id) => {
            let day = Number(change_id.split('_')[0])
            let time = Number(change_id.split('_')[1])
            let targetIndex = (day - 1) * 10 + time
            combinedList[targetIndex] = '1'
        })

        closeList.map((change_id) => {
            let day = Number(change_id.split('_')[0])
            let time = Number(change_id.split('_')[1])
            let targetIndex = (day - 1) * 10 + time
            combinedList[targetIndex] = '0'
        })

        for (let i = 0; i < 7; i++) {
            targetScheduler[`day${i + 1}`] = combinedList.slice(i * 10, i * 10 + 10).join(',')
        }
        try {
            const res = await axios.put(`${COSTCO_AREA_SCHEDULER_URL}${areaScheduler[cityIndex]['id']}/`, targetScheduler)
            console.log('update', areaScheduler[cityIndex].name)
            setChangeList([])
        } catch (e) {
            console.log("error from update Area Scheduler", e)
        }
    }

    const updateAreaScheduler = async (cityIndex) => {
        const targetScheduler = areaScheduler[cityIndex]
        let combinedValue = ''
        for (let i = 1; i < 8; i++) {
            combinedValue += targetScheduler[`day${i}`] + ','
        }
        let combinedList = combinedValue.split(',')

        changeList.map((change_id) => {
            let day = Number(change_id.split('_')[0])
            let time = Number(change_id.split('_')[1])
            let targetIndex = (day - 1) * 10 + time
            combinedList[targetIndex] = combinedList[targetIndex] === '0' ? '1' : '0'
        })
        for (let i = 0; i < 7; i++) {
            targetScheduler[`day${i + 1}`] = combinedList.slice(i * 10, i * 10 + 10).join(',')
        }
        try {
            const res = await axios.put(`${COSTCO_AREA_SCHEDULER_URL}${areaScheduler[cityIndex]['id']}/`, targetScheduler)
            console.log('update', areaScheduler[cityIndex].name)
            setChangeList([])
        } catch (e) {
            console.log("error from update Area Scheduler", e)
        }
    }

    const updateScheduler = async () => {
        if (prefectureIndexList.includes(cityNameIndex)) {
            //  東京、神奈川それぞれ全て変更
            let targetPrefectureIndexList = []
            if (cityNameIndex === 0) {
                //  東京全部変更
                targetPrefectureIndexList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
            } else {
                //  神奈川全部変更
                targetPrefectureIndexList = [12, 13, 14, 15, 16]
            }
            targetPrefectureIndexList.forEach(element => overwriteScheduler(element))
            setChangeList([])
            setOpenList([])
            setCloseList([])
        } else {
            await updateAreaScheduler(cityNameIndex)
        }
    }

    const allOpen = () => {
        const targetScheduler = areaScheduler[cityNameIndex]
        const targetDaySchedulerList = targetScheduler[`day${value + 1}`].split(',')
        const change = []
        if (prefectureIndexList.includes(cityNameIndex)) {
            targetDaySchedulerList.map((v, index) => {
                change.push(`${value + 1}_${index}`)
            })
            setCloseList([])
            setOpenList(change)
        } else {
            targetDaySchedulerList.map((v, index) => {
                let id = `${value + 1}_${index}`
                if (v === '0') {
                    change.push(id)
                }
                setChangeList(change);
            })
        }
    }

    const allClose = () => {
        const targetScheduler = areaScheduler[cityNameIndex]
        const targetDaySchedulerList = targetScheduler[`day${value + 1}`].split(',')
        const change = []
        targetDaySchedulerList.map((v, index) => {
            let id = `${value + 1}_${index}`
            if (v === '1') {
                change.push(id)
            }
        })
        if (prefectureIndexList.includes(cityNameIndex)) {
            setOpenList([])
            setCloseList(change)
        } else {
            setChangeList(change);
        }
    }

    const allClear = () => {
        setOpenList([])
        setCloseList([])
        setChangeList([])
    }

    return (
        <Container maxWidth='xs' style={{marginBottom: '2rem'}}>
            <FormControl variant="outlined" margin="normal" style={{width: '100%'}}>
                <InputLabel
                    id="prefecture-simple-select-outlined-label">区域</InputLabel>
                <Select
                    labelId="prefecture-simple-select-outlined-label"
                    id="prefecture-simple-select-outlined"
                    label={'区域'}
                    name="cityName"
                    value={cityNameIndex}
                    onChange={handleChangeCityName}
                >
                    {areaScheduler.map((schedule, index) => (
                        <MenuItem value={index}>{schedule['name']}</MenuItem>
                    ))}
                </Select>
            </FormControl>


            <Paper square style={{backgroundColor: '#afeeee'}}>
                <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
                    {createDateList().map((date, index) => (
                        <Tab label={date} {...a11yProps(index)} style={{textTransform: 'none'}}/>
                    ))}
                </Tabs>
            </Paper>
            {createDateList().map((date, index) => (
                <TabPanel value={value} index={index} style={{borderRadius: 'none', paddingBottom: '2rem'}}>
                    <TableContainer component={Paper} square>
                        <Table aria-label="simple table">
                            <TableBody>
                                {areaScheduler[cityNameIndex][`day${index + 1}`].split(',').map((value, id) => (
                                    <TableRow key={id}>
                                        <TableCell align='center' style={{width: "50%"}}>
                                            {timeZone[id]}
                                        </TableCell>
                                        {prefectureIndexList.includes(cityNameIndex) ?
                                            convertSymbolPrefecture(value, `${index + 1}_${id}`) :
                                            convertSymbol(value, `${index + 1}_${id}`)
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
            ))}


            <div className={classes.buttons}>
                <Button type='submit' variant='contained' color='primary' style={{float: 'left'}}
                        onClick={allClose} className={classes.button}>全部閉める</Button>

                <Button type='submit' variant='contained' color='primary'
                        onClick={allClear} className={classes.button}>リセット</Button>

                <Button type='submit' variant='contained' color='primary' style={{float: 'right'}}
                        onClick={allOpen} className={classes.button}>全部開ける</Button>
            </div>

            <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                disabled={changeList.length === 0 && openList.length === 0 && closeList.length === 0}
                onClick={updateScheduler}
            >更新</Button>
        </Container>
    )
}


export default SchedulerList