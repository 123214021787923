import React from "react";
import TopMain from "../Top/TopMain";
// import TopNavBar from "../NavBar/TopNavbar";
import TopFooter from "../Top/TopFooter";
import NavBar from "../NavBar/NavBar";


const TopPageTemplate = () => {
    return (
        <div className='pageWrapper'>
            <NavBar/>
            <TopMain/>
            <TopFooter/>
        </div>
    )
}

export default TopPageTemplate