import React from "react";
import Footer from "../Footer";
import NavBar from "../NavBar/NavBar";
import AreaScheduler from "../AreaScheduler /AreaScheduler";


const AreaSchedulerPage = () => {
    return (
        <div className='pageWrapper'>
            <NavBar/>
            <AreaScheduler/>
            <Footer/>
        </div>
    )
}


export default AreaSchedulerPage