import React, {useEffect, useState} from "react";
import TableCell from "@material-ui/core/TableCell";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


const timeZone = [
    '12:00 ~ 13:00',
    '13:00 ~ 14:00',
    '14:00 ~ 15:00',
    '15:00 ~ 16:00',
    '16:00 ~ 17:00',
    '17:00 ~ 18:00',
    '18:00 ~ 19:00',
    '19:00 ~ 20:00',
    '20:00 ~ 21:00',
    '21:00 ~ 22:00',
]

const TabPanel = (props) => {
    const {children, value, index, ...other} = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const DeliveryTime = (props) => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '/e' || currentUrl.slice(-2) === '_e'

    const {areaScheduler, validCityName} = props
    const tokyoArea = areaScheduler.slice(0, 10)
    const kanagawaArea = areaScheduler.slice(10, 15)

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const [cityNameIndex, setCityNameIndex] = useState(5)
    const handleChangeCityName = (event) => {
        setCityNameIndex(event.target.value)
    };

    const date2String = (date) => {
        const month = date.getMonth() + 1
        const day = date.getDate()
        const dayOfWeek = date.getDay()
        const dayOfWeekStr = isEnglish ? ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][dayOfWeek] : ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek]
        return month + "/" + day + "(" + dayOfWeekStr + ")"
    }
    const createDateList = () => {
        const dateList = []
        const today = new Date()
        for (let i = 1; i <= 7; i++) {
            if (i !== 1) {
                today.setDate(today.getDate() + 1)
            }
            dateList.push(date2String(today))
        }
        return dateList
    }

    const convertSymbol = (value, id) => {
        let closeFrameNum = 0
        if (id.split('_')[0] === '1') {
            const today = new Date()
            const nowHour = today.getHours()
            closeFrameNum = nowHour >= 18 ? 10 : nowHour - 9
        }
        const time = Number(id.split('_')[1])

        if (closeFrameNum > time) {
            return <TableCell align="center">×</TableCell>
        }
        switch (value) {
            case '0':
                return <TableCell align="center">×</TableCell>
            case '1':
                return <TableCell align="center" id={id}>○</TableCell>
            default:
                return <TableCell align="center">×</TableCell>
        }
    }

    useEffect(() => {
        if (validCityName) {
            const name = isEnglish ? 'english_name' : 'name'
            areaScheduler.map((scheduler, index) => {
                if (scheduler[name] === validCityName) {
                    setCityNameIndex(index)
                }
            })
        }
    }, [validCityName])


    return (
        <>
            {!validCityName &&
            <FormControl variant="outlined" margin="normal" style={{width: '100%'}}>
                <InputLabel
                    id="prefecture-simple-select-outlined-label">{isEnglish ? 'Area' : '区域'}</InputLabel>
                <Select
                    labelId="prefecture-simple-select-outlined-label"
                    id="prefecture-simple-select-outlined"
                    label={isEnglish ? 'Area' : '区域'}
                    name="cityName"
                    value={cityNameIndex}
                    disabled={validCityName}
                    onChange={handleChangeCityName}
                >
                    {areaScheduler.map((schedule, index) => (
                        <MenuItem value={index} disabled={[0, 11].includes(index)}>
                            {isEnglish ? schedule['english_name'] : schedule['name']}</MenuItem>
                    ))}
                </Select>
            </FormControl>}

            <Paper square style={{backgroundColor: '#afeeee'}}>
                <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
                    {createDateList().map((date, index) => (
                        <Tab label={date} {...a11yProps(index)} style={{textTransform: 'none'}}/>
                    ))}
                </Tabs>
            </Paper>
            {createDateList().map((date, index) => (
                <TabPanel value={value} index={index} style={{borderRadius: 'none', paddingBottom: '2rem'}}>
                    <TableContainer component={Paper} square>
                        <Table aria-label="simple table">
                            <TableBody>
                                {areaScheduler[cityNameIndex][`day${index + 1}`].split(',').map((value, id) => (
                                    <TableRow key={id}>
                                        <TableCell align='center' style={{width: "50%"}}>
                                            {timeZone[id]}
                                        </TableCell>
                                        {convertSymbol(value, `${index + 1}_${id}`)}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
            ))}
        </>
    )
}

export default DeliveryTime