import React, {useState} from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from "@material-ui/core/styles";
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import {Typography} from "@material-ui/core";
import {removeQuery} from "../utils/func";


const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        margin: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: '13px',
        top: '13px',
        color: 'black',
    },
    titleWrapper: {
        color: '#424242',
        paddingLeft: '2rem',
    },
    contentWrapper: {
        maxWidth: '300px',
    },
    huskyImg: {
        textAlign: 'center',
        // width: 'auto',
        // height: '160 * 1.333983106 px',
        height: '149px',
        // 横:縦 = 3953: 6096 = 1: 1.542119909
        marginBottom: '1rem', // アクションがない時つける
    },
    sekkenImg: {
        textAlign: 'center',
        width: 'auto',
        // height: '160 * 1.333983106 px',
        height: '130px',
        // 横:縦 = 3953: 6096 = 1: 1.542119909
        marginBottom: '1rem',
    },
    layerImage: {
        textAlign: 'center',
        width: '120px',
        height: '185.05438908px',
        position: 'relative',
    },
    overlapImage: {
        height: '90px',
        position: 'absolute',
        bottom: '130px',
        left: '170px',
    },
    link: {
        textDecoration: 'none',
        color: 'blue',
    },
    radius: {
        borderRadius: '30%'
    },
}))

const TopDialog = (props) => {
    const classes = useStyles()

    // const currentUrl = window.location.href
    const currentUrl = removeQuery(window.location.href)
    const isEnglish = currentUrl.slice(-2) === '/e' || currentUrl.slice(-2) === '_e'

    const huskyImg = 'images/under-construction.png'
    const huskyApo = 'images/husky_apo.png'
    const rainImg = 'images/rain_0.png'
    const huskyCart = 'images/cart_husky.png'
    const stopPoll = 'images/stop_poll.png'
    const rothiImg = 'images/dec_rothi.png'
    const huskyHappy = 'images/husky_happy.png'
    const sekken = 'images/sekken.png'
    const inviteHusky = 'images/invite_husky.png'
    const footerImage = 'images/husky_apo.png'
    const chikenImage = 'images/rothi_png.png'
    const freeTrack = 'images/free_track.png'
    const tenPersentOff = 'images/tenPercentOff.png'
    const coupon = 'images/coupon.png'
    const costcoInfo = 'images/costco_info.png'
    const closedImg = 'images/dec_closed.png'
    const discountImg = 'images/discount.png'
    const starImg = 'images/star.png'
    const giaImg = 'images/gia.png'
    const feeUpdate = 'images/fee_update.png'
    const outOfStockChiken = 'images/out_of_stock_chiken.png'
    const freeDeliveryBlue = 'images/free-delivery-blue.png'

    // const [open, setOpen] = React.useState(true)
    const {open, setOpen} = props
    const handleClose = () => {
        setOpen(false)
    }
    const scrollToAvailableArea = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 480
        handleClose()
    }

    const [activeStep, setActiveStep] = useState(0)

    return (
        <div>
            <Dialog
                maxWidth='lg'
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={{border: '7px solid #afeeee'}}>
                    <IconButton onClick={() => handleClose()} className={classes.closeButton}>
                        <CloseIcon/>
                    </IconButton>

                    <DialogTitle id="alert-dialog-title" className={classes.titleWrapper}>
                        <Typography style={{fontSize: '20px'}}>
                            {isEnglish ? 'NEWS' : '最新情報'}
                        </Typography>
                    </DialogTitle>
                    <DialogContent className={classes.contentWrapper}>
                        {activeStep === 10 &&
                            <div>
                                <DialogContentText
                                    style={{whiteSpace: 'pre-wrap', width: '250px', textAlign: 'center'}}>
                                    <a href={isEnglish ? '/notice_e' : '/notice'} target='_blank'
                                       rel="noopener noreferrer"
                                       style={{color: 'blue', textDecoration: 'none'}}>
                                        {isEnglish ?
                                            'Summer\nNotice of closure'
                                            :
                                            '夏季\n休業のお知らせ'}
                                    </a>

                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <img src={closedImg} alt="under-construction" className={classes.huskyImg}
                                         style={{marginTop: '0', marginBottom: '0'}}/>
                                </div>
                            </div>
                        }
                        {activeStep === 10 &&
                            <div>
                                <DialogContentText
                                    style={{whiteSpace: 'pre-wrap', width: '250px', textAlign: 'center'}}>
                                    <a href={isEnglish ? '/notice_e' : '/notice'}
                                       style={{color: 'blue', textDecoration: 'none'}}>
                                        {isEnglish ?
                                            'Now, rotisserie chicken\nis out of stock'
                                            :
                                            'ロティサリーチキン\n販売停止中'}
                                    </a>

                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <img src={outOfStockChiken} alt="under-construction" className={classes.huskyImg}
                                         style={{marginTop: '0', marginBottom: '0'}}/>
                                </div>
                            </div>
                        }
                        {activeStep === 0 &&
                            <div>
                                <DialogContentText
                                    style={{whiteSpace: 'pre-wrap', width: '250px', textAlign: 'center'}}>
                                    <a href={isEnglish ? 'https://husky-cart.online/#promotion' : 'https://husky-cart.online/#プロモーション'}
                                       style={{color: 'blue', textDecoration: 'none'}}>
                                        {isEnglish ?
                                            'No fee for over\nproduct price ¥20,000'
                                            :
                                            '商品代金20,000円\n以上で手数料無料'}
                                    </a>

                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <img src={freeDeliveryBlue} alt="under-construction" className={classes.huskyImg}
                                         style={{marginTop: '0', marginBottom: '0', width: '149px'}}/>
                                </div>
                            </div>
                        }
                        {activeStep === 1 &&
                            <div>
                                <DialogContentText
                                    style={{whiteSpace: 'pre-wrap', width: '250px', textAlign: 'center'}}>
                                    <a href={isEnglish ? 'https://husky-cart.online/#promotion2' : 'https://husky-cart.online/#プロモーション2'}
                                       style={{color: 'blue', textDecoration: 'none'}}>
                                        {isEnglish ?
                                            'No fee after review\nCheck the procedure'
                                            :
                                            'クチコミで手数料無料\n手順を確認'}
                                    </a>

                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <img src={starImg} alt="under-construction" className={classes.huskyImg}
                                         style={{marginTop: '0', marginBottom: '0'}}/>
                                </div>
                            </div>
                        }
                        {activeStep === 2 &&
                            <div>
                                <DialogContentText
                                    style={{whiteSpace: 'pre-wrap', width: '250px', textAlign: 'center'}}>
                                    <a href={isEnglish ? 'https://husky-cart.online/#Function-change' : 'https://husky-cart.online/#機能変更'}
                                        // target='_blank' rel="noopener noreferrer"
                                       style={{color: 'blue', textDecoration: 'none'}}>
                                        {isEnglish ?
                                            'New function add / change\nClick here for details'
                                            :
                                            '新機能追加・変更\n詳細はこちら'}
                                    </a>

                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <img src={giaImg} alt="under-construction" className={classes.huskyImg}
                                         style={{marginTop: '0', marginBottom: '0'}}/>
                                </div>
                            </div>
                        }
                        {activeStep === 3 &&
                            <div>
                                <DialogContentText
                                    style={{whiteSpace: 'pre-wrap', width: '250px', textAlign: 'center'}}>
                                    <a href={isEnglish ? 'https://husky-cart.online/#price-revision' : 'https://husky-cart.online/#%E6%96%99%E9%87%91%E6%94%B9%E5%AE%9A'}
                                       style={{color: 'blue', textDecoration: 'none'}}>
                                        {isEnglish ?
                                            // 'MAR. 8th, Fee is free\nClick here'
                                            'Price revision\nInformation'
                                            :
                                            '料金改定の\nお知らせ'}
                                        {/*'3月8日、手数料無料\n詳細はこちら'}*/}
                                    </a>

                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <img src={feeUpdate} alt="under-construction" className={classes.huskyImg}
                                         style={{marginTop: '0', marginBottom: '0'}}/>
                                </div>
                            </div>
                            // <div>
                            //     <DialogContentText
                            //         style={{whiteSpace: 'pre-wrap', width: '250px', textAlign: 'center'}}
                            //     >
                            //         <a href={isEnglish ? '/covid_e' : '/covid'} target='_blank'
                            //            style={{color: 'blue', textDecoration: 'none'}}
                            //         >
                            //             {isEnglish ?
                            //                 '4/12 ~ 4/16【Limited edition】\nALL 10%OFF' :
                            //                 '4/12 ~ 4/16【期間限定】\n全商品10%OFF'}
                            //         </a>
                            //
                            //     </DialogContentText>
                            //     <div style={{textAlign: 'center'}}>
                            //         <img src={tenPersentOff} alt="under-construction" className={classes.huskyImg}
                            //              style={{marginTop: '0', marginBottom: '0'}}
                            //         />
                            //     </div>
                            // </div>
                        }

                        {activeStep === 4 &&
                            <div>
                                <DialogContentText
                                    style={{whiteSpace: 'pre-wrap', width: '250px', textAlign: 'center'}}
                                    // style={{whiteSpace: 'pre-wrap', width: '263px'}}
                                >
                                    <a href={isEnglish ? '/covid_e' : '/covid'}
                                       style={{color: 'blue', textDecoration: 'none'}}>
                                        {isEnglish ?
                                            'Covid-19\nInfection Control' :
                                            '新型コロナウイルス\n感染対策について'}
                                    </a>

                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <img src={sekken} alt="under-construction" className={classes.huskyImg}
                                         style={{marginTop: '0', marginBottom: '0'}}
                                        // style={{marginTop: '3rem', marginBottom: '0'}}
                                    />
                                </div>
                            </div>
                        }

                        <DialogActions style={{justifyContent: 'space-between'}}>
                            <Button
                                onClick={() => setActiveStep(activeStep - 1)}
                                color="default"
                                disabled={activeStep === 0}
                                variant='contained'>
                                {isEnglish ? 'back' : '前へ'}
                            </Button>
                            <p style={{opacity: '0.54'}}>&lt; {activeStep + 1} / 5 &gt;</p>
                            <Button
                                onClick={() => setActiveStep(activeStep + 1)}
                                color="default"
                                disabled={activeStep === 4}
                                variant='contained'>
                                {isEnglish ? 'next' : '次へ'}
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </div>
            </Dialog>
        </div>
    )
}

export default TopDialog