// const IPURL = 'http://localhost:8000/'
// export const AUTHEN_URL = IPURL+'authen/'
// export const CREATE_USER_URL = IPURL+'api/user/create/'
// export const MY_PROFILE_URL = IPURL+'api/user/myprofile/'
// export const CREATE_PROFILE_URL = IPURL+'api/user/profile/'
//
// export const COSTCO_PRODUCT_URL = IPURL+'api/costco/products/'
// export const COSTCO_CATEGORY_URL = IPURL+'api/costco/categories/'
//
// export const ANYCART_PRODUCT_URL = IPURL+'api/anycart/product/'


// const IPURL = 'http://54.168.205.191:8000/'
const IPURL = 'https://husky-cart.com:8000/'

export const AUTHEN_URL = IPURL + 'authen/'
export const CREATE_USER_URL = IPURL + 'api/user/create/'
export const MY_PROFILE_URL = IPURL + 'api/user/myprofile/'
export const CREATE_PROFILE_URL = IPURL + 'api/user/profile/'
export const USER_URL = IPURL + 'api/user/user/'
export const CONTACT_US_URL = IPURL + 'api/user/contact_us/'
export const REGISTER_MAIL_URL = IPURL + 'api/costco/register_mail/'
export const USER_ACTIVATION_URL = IPURL + 'api/costco/user_activation/'
export const AUTHENTICATION_CODE_URL = IPURL + 'api/user/authentication_code/'
export const AUTHENTICATION_MAIL_URL = IPURL + 'api/costco/authentication_mail/'


export const COSTCO_PRODUCT_URL = IPURL + 'api/costco/products/'
export const COSTCO_CATEGORY_URL = IPURL + 'api/costco/categories/'
export const COSTCO_SCHEDULE_URL = IPURL + 'api/costco/schedule/'
export const COSTCO_AREA_SCHEDULER_URL = IPURL + 'api/costco/area_scheduler/'
export const COSTCO_CARTITEM_URL = IPURL + 'api/costco/cart_item/'
export const COSTCO_ORDER_URL = IPURL + 'api/costco/order/'
export const COSTCO_FIRST_ORDER_URL = IPURL + 'api/costco/first_order/'
export const COSTCO_CONFIRM_MAIL_URL = IPURL + 'api/costco/confirm_mail/'
export const COSTCO_PAYMENT_URL = IPURL + 'api/costco/zeus_payment/'
export const COSTCO_COUPON_URL = IPURL + 'api/costco/coupon/'
export const COSTCO_MAIL_LIST_URL = IPURL + 'api/costco/mail_list/'
export const COSTCO_INVITATION_URL = IPURL + 'api/costco/invitation/'
export const COSTCO_PERSONAL_COUPON_URL = IPURL + 'api/costco/personal_coupon/'

export const HS_PRODUCT_URL = IPURL + 'api/hs/products/'
export const HS_CATEGORY_URL = IPURL + 'api/hs/categories/'
export const HS_SCHEDULE_URL = IPURL + 'api/hs/schedule/'
export const HS_CARTITEM_URL = IPURL + 'api/hs/cart_item/'
export const HS_ORDER_URL = IPURL + 'api/hs/order/'
export const HS_FIRST_ORDER_URL = IPURL + 'api/hs/first_order/'
export const HS_CONFIRM_MAIL_URL = IPURL + 'api/hs/confirm_mail/'
export const HS_PAYMENT_URL = IPURL + 'api/hs/hs_zeus_payment/'

export const IKEA_PRODUCT_URL = IPURL + 'api/ikea/products/'
export const IKEA_CATEGORY_URL = IPURL + 'api/ikea/categories/'
export const IKEA_SCHEDULE_URL = IPURL + 'api/ikea/schedule/'
export const IKEA_CARTITEM_URL = IPURL + 'api/ikea/cart_item/'
export const IKEA_ORDER_URL = IPURL + 'api/ikea/order/'
export const IKEA_FIRST_ORDER_URL = IPURL + 'api/ikea/first_order/'
export const IKEA_CONFIRM_MAIL_URL = IPURL + 'api/ikea/confirm_mail/'
export const IKEA_PAYMENT_URL = IPURL + 'api/ikea/ikea_zeus_payment/'

export const MB_CATEGORY_URL = IPURL + 'api/mb/categories/'
export const MB_PRODUCT_URL = IPURL + 'api/mb/products/'
export const MB_COUPON_URL = IPURL + 'api/mb/coupon/'
export const MB_PAYMENT_URL = IPURL + 'api/mb/mb_zeus_payment/'
export const MB_CART_ITEM_URL = IPURL + 'api/mb/cart_items/'
export const MB_ORDER_URL = IPURL + 'api/mb/order/'
export const MB_ORDER_MAIL_URL = IPURL + 'api/mb/mb_confirm_mail/'
export const MB_TOPPING_URL = IPURL + 'api/mb/topping/'

export const ANYCART_PRODUCT_URL = IPURL + 'api/anycart/product/'

export const FORGET_PASSWORD_URL = IPURL + 'password_reset/'
export const PASSWORD_RESET_CONFIRM_URL = IPURL + 'password_reset/confirm/'

export const PAYMENT_URL = 'https://linkpt.cardservice.co.jp/cgi-bin/secure.cgi'