import React from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer";
import {Container, Grid} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from "@material-ui/core/Link";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
    titleImg: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        width: '100%',

    },
    promotionList: {
        margin: '0 auto',
        textAlign: 'center',
        width: '80%',
        height: '4rem',
        border: 'solid 1px',
        borderRadius: '20px',
        marginBottom: '1rem',
    },
    dateContainer: {
        border: 'solid 1px',
        width: '3rem',
        height: '3rem',
        borderRadius: '50%',
        marginLeft: '1rem',
        marginTop: '0.5rem',
        lineHeight: '3rem',
    },
    promotionContent: {
        lineHeight: '4rem',
    }
}))

const PromotionPage = () => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '/e' || currentUrl.slice(-2) === '_e'

    const classes = useStyles()
    const titleImageJp = 'images/title_promotion_jp.jpg'
    const titleImageEng = 'images/title_promotion_eng.jpg'
    const promotionXsJp = 'images/promotion_xs_jp.jpeg'
    const promotionXsEng = 'images/promotion_xs_eng.jpeg'
    const promotionLgJp = 'images/promotion_lg_jp.jpeg'
    const promotionLgEng = 'images/promotion_lg_eng.jpeg'

    return (
        <div className="pageWrapper">
            <NavBar/>
            <Container maxWidth='lg' style={{padding: '0'}}>
                <Container maxWidth='xs'>
                    <img src={isEnglish ? titleImageEng : titleImageJp}
                         alt="title" width='100%' className={classes.titleImg}/>
                </Container>
                <Hidden smUp>
                    <img src={isEnglish ? promotionXsEng : promotionXsJp} alt="img" width='100%'/>
                </Hidden>
                <Hidden xsDown>
                    <img src={isEnglish ? promotionLgEng : promotionLgJp} alt="img" width='100%'/>
                </Hidden>


                <Grid container style={{marginBottom: '2rem'}}>
                    <Grid item xs={6}>
                        <Link href={isEnglish ? '/e' : '/'} variant="body2" color='textSecondary'
                              style={{paddingLeft: '12px'}}>
                            {isEnglish ? 'BACK' : '戻る'}
                        </Link>
                    </Grid>
                </Grid>
            </Container>

            <Footer/>
        </div>
    )
}

export default PromotionPage