import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CartProducts from "./CartProducts";
import {CostcoContext} from "./CostcoHome";
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from "axios"
import {COSTCO_COUPON_URL, COSTCO_ORDER_URL, COSTCO_PERSONAL_COUPON_URL} from "../../Messages/urls";
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";


const NumberFormatCustom = (props) => {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix="¥"
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const CostcoCart = () => {
    const {
        cart,
        setCart,
        products,
        activeStep,
        setActiveStep,
        classes,
        totalAmount,
        setMemo,
        setCancel,
        tip,
        setTip,
        couponPrice,
        setCouponPrice,
        myProfile,
        isCouponApplied,
        setIsCouponApplied,
        setCouponCode,
        isNoFee,
        isFirstTime,
        setIsFirstTime,
    } = useContext(CostcoContext)
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'

    const [code, setCode] = useState('blank')
    const [couponMessage, setCouponMessage] = useState('')
    const [couponDialog, setCouponDialog] = useState(false)
    const [myCouponList, setMyCouponList] = useState([])

    // const shoppingFee = isNoFee || isFirstTime ? 0 : 250

    const thresholdItemPrice = 20000
    const isOverThreshold = totalAmount >= thresholdItemPrice
    const shoppingFee = isNoFee || isFirstTime || isOverThreshold ? 0 : 800

    // const deliveryFee = isNoFee || isFirstTime ? 0 : 250
    const deliveryFee = 0


    const minPrice = 5000

    // const isExceed = false


    const calcFebPromotionDiscount = () => {
        return 0
        // const amount = totalAmount + shoppingFee + deliveryFee + (Math.floor(totalAmount * tip * 0.001) * 10) - couponPrice
        // if (amount >= 20000) {
        //     return 1000
        // } else if (amount >= 10000) {
        //     return 500
        // } else {
        //     return 0
        // }
    }

    const handleMemo = () => event => {
        const value = event.target.value
        setMemo(value)
    }

    const handleCancel = () => event => {
        const value = event.target.value
        setCancel(value)
    }

    const handleTip = () => event => {
        const value = event.target.value
        setTip(value)
    }

    const handleCouponCode = () => event => {
        const value = event.target.value
        setCode(value)
    }

    const getCoupon = async () => {

        if (code.length > 5) {
            // 個人クーポン
            const personalCoupon = await axios.get(`${COSTCO_PERSONAL_COUPON_URL}?user=${myProfile.user_profile}&code=${code}&is_used=false`)
            const personalCouponData = personalCoupon.data[0]
            if (personalCouponData) {
                setCouponPrice(personalCouponData.discounted_price)
                setCouponMessage('')
                setIsCouponApplied(true)
                setCouponCode(code)
                return
            }
            // 普通のクーポン
            const coupon = await axios.get(`${COSTCO_COUPON_URL}?code=${code}&active=true`)
            const couponData = coupon.data[0]
            if (!couponData) {
                setCouponMessage(isEnglish ? 'Invalid coupon code' : '無効なクーポンコードです')
                return
            }
            const usedUserArray = couponData.used_user
            if (usedUserArray.includes(myProfile.user_profile)) {
                // 使用済みな物はダメ
                setCouponMessage(isEnglish ? 'Invalid coupon code' : '無効なクーポンコードです')
            } else {
                setCouponPrice(couponData.discounted_price)
                setCouponMessage('')
                setIsCouponApplied(true)
                setCouponCode(code)
            }
        } else {
            setCouponMessage(isEnglish ? 'Invalid coupon code' : '無効なクーポンコードです')
        }
    }

    const applyMyCoupon = (coupon) => {
        setCouponPrice(coupon.discounted_price)
        setCouponMessage('')
        setIsCouponApplied(true)
        setCouponCode(coupon.code)
        setCouponDialog(false)
    }

    useEffect(() => {
        setCancel()
        setMemo()
        setTip(0)
    }, [])

    useEffect(() => {
        if (myProfile) {
            axios.get(`${COSTCO_PERSONAL_COUPON_URL}?user=${myProfile.user_profile}&is_used=false`)
                .then(res => {
                    setMyCouponList(res.data)
                })
        }
    }, [myProfile])

    useEffect(() => {
        if (myProfile) {
            axios.get(`${COSTCO_ORDER_URL}?user=${myProfile.user_profile}`)
                .then(res => {
                    setIsFirstTime(res.data.length === 0)
                })
        }
    })

    const resultAmount = totalAmount + shoppingFee + deliveryFee + (Math.floor(totalAmount * tip * 0.001) * 10) - couponPrice - calcFebPromotionDiscount()
    const isExceed = resultAmount < minPrice

    return (
        <>
            {cart.length !== 0 ?
                <>
                    <Grid container className={classes.checkOutContainer}>
                        <Grid item lg={12}>
                            <CartProducts products={products}
                                          cart={cart}
                                          setCart={setCart}
                                          classes={classes}
                                          isEnglish={isEnglish}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={6} style={{marginTop: '94px'}}>
                            <Typography variant='h4'
                                        style={{
                                            marginLeft: '9px',
                                            fontSize: '15px',
                                            whiteSpace: 'pre-wrap',
                                            width: '100%',
                                        }}>
                                {isEnglish && `Products : ¥`}
                                {!isEnglish && `商品代金 : ¥`}
                                <span id='totalAmount'>{totalAmount}</span>
                            </Typography>

                            <Typography variant='h4'
                                        style={{
                                            marginTop: '13px',
                                            marginLeft: '9px',
                                            fontSize: '15px',
                                            whiteSpace: 'pre-wrap',
                                            width: '100%',
                                        }}>
                                {/*{isOverThreshold ?*/}
                                {/*    (isEnglish ? `Fee : ¥${0}` : `手数料 : ¥${0}`):*/}
                                {/*    (isEnglish ? `Fee : ¥${shoppingFee}` : `手数料 : ¥${shoppingFee}`)*/}
                                {/*}*/}
                                {isEnglish ? `Fee : ¥${shoppingFee}` : `手数料 : ¥${shoppingFee}`}
                                {isNoFee &&
                                    <span style={{color: 'green'}}>{isEnglish ? '\n(promotion)' : '\n(プロモーション)'}</span>}
                                {isFirstTime && !isNoFee &&
                                    <span
                                        style={{color: 'green'}}>{isEnglish ? '\n(First order only)' : '\n(初回特典)'}</span>}

                                {isOverThreshold &&
                                    <span
                                        style={{color: 'green'}}>{isEnglish ? '\n(promotion)' : '\n(プロモーション)'}</span>}
                            </Typography>

                            {/*<Typography variant='h4'*/}
                            {/*            style={{*/}
                            {/*                marginTop: '13px',*/}
                            {/*                marginLeft: '9px',*/}
                            {/*                fontSize: '15px',*/}
                            {/*                whiteSpace: 'pre-wrap',*/}
                            {/*                width: '100%',*/}
                            {/*            }}>*/}
                            {/*    {isEnglish ? `Delivery fee : ¥${deliveryFee}` : `配送料 : ¥${deliveryFee}`}*/}
                            {/*    {isNoFee &&*/}
                            {/*    <span style={{color: 'green'}}>{isEnglish ? '\n(promotion)' : '\n(プロモーション)'}</span>}*/}
                            {/*    {isFirstTime && !isNoFee &&*/}
                            {/*    <span*/}
                            {/*        style={{color: 'green'}}>{isEnglish ? '\n(First order only)' : '\n(初回限定の特典)'}</span>}*/}
                            {/*</Typography>*/}

                            <Typography variant='h4'
                                        style={{
                                            marginTop: '13px',
                                            marginLeft: '9px',
                                            fontSize: '15px',
                                            whiteSpace: 'pre-wrap', width: '100%',
                                        }}>
                                {isEnglish ? 'Tip : ¥' : 'チップ : ¥'}{Math.floor(totalAmount * tip * 0.001) * 10}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{marginTop: '71px'}}>
                            <FormControl style={{width: '175px', textAlign: 'left', marginBottom: '14px'}}>
                                <InputLabel id="demo-simple-select-label">
                                    {isEnglish ? 'If out of stock item' : '在庫切れ商品の対応'}
                                </InputLabel>
                                <Select
                                    onChange={handleCancel()}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={1}
                                >
                                    <MenuItem value={1}>{isEnglish ? 'Cancel' : 'キャンセル'}</MenuItem>
                                    <MenuItem value={2}>{isEnglish ? 'Substitute(no call)' : '代替品(お任せ)'}</MenuItem>
                                    <MenuItem value={3}>{isEnglish ? 'Substitute(call)' : '代替品(要連絡)'}</MenuItem>
                                    {isEnglish &&
                                        <MenuItem value={4}>{isEnglish ? 'Substitute(text)' : '代替品(テキスト)'}</MenuItem>}
                                </Select>
                            </FormControl>
                            <br/>
                            <FormControl style={{width: '175px', textAlign: 'left', marginBottom: '1rem'}}>
                                <InputLabel id="demo-simple-select-label">
                                    {isEnglish ? 'Tip (product\'s %)' : 'チップ (商品代金の%)'}
                                </InputLabel>
                                <Select
                                    onChange={handleTip()}
                                    defaultValue={0}
                                >
                                    <MenuItem value={0}>{isEnglish ? 'none' : '無し'}</MenuItem>
                                    <MenuItem value={5}>{isEnglish ? '5%' : '5%'}</MenuItem>
                                    <MenuItem value={10}>{isEnglish ? '10%' : '10%'}</MenuItem>
                                    {/*<MenuItem value={15}>{isEnglish ? '15%' : '15%'}</MenuItem>*/}
                                    {/*<MenuItem value={20}>{isEnglish ? '20%' : '20%'}</MenuItem>*/}
                                </Select>
                            </FormControl>


                        </Grid>
                        <Grid item xs={12}>
                            <p style={{margin: '10px 0 0 9px'}}>{isEnglish ? 'Use coupon' : 'クーポンを使用'}</p>

                            <TextField
                                style={{marginLeft: '9px', width: '60%', marginTop: '5px'}}
                                onChange={handleCouponCode()}
                                placeholder={isEnglish ? 'Coupon code' : 'クーポンコード'}
                                disabled={isCouponApplied}
                            />
                            <IconButton>
                                <AddIcon onClick={() => setCouponDialog(true)}/>
                            </IconButton>
                            <Button
                                style={{marginRight: '6px', float: 'right'}}
                                disabled={isCouponApplied}
                                variant='contained'
                                onClick={() => getCoupon()}>
                                {isEnglish ? 'Apply' : '適用'}
                            </Button>

                            {couponMessage && <p style={{
                                color: 'red',
                                marginLeft: '9px',
                                marginBottom: '3px',
                                marginTop: '0'
                            }}>{couponMessage}</p>}
                            {isCouponApplied &&
                                <p style={{
                                    color: 'blue',
                                    marginLeft: '9px',
                                    marginBottom: '3px',
                                    marginTop: '0'
                                }}>{isEnglish ? 'Coupon has been applied' : 'クーポンが適用されました'}</p>}
                        </Grid>
                        <Grid item xs={12}>
                            <p style={{margin: '10px 0 5px 9px'}}>{isEnglish ? 'Memo' : 'メモ'}</p>
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                onChange={handleMemo()}
                                rows={2}
                                placeholder={isEnglish ?
                                    'Ex, ○○ is out of stock on website but if there is at store, please add one.'
                                    :
                                    '例、在庫切れになっている〇〇の在庫がもしあれば、\n1つ追加お願いします。'}
                                variant="outlined"
                                fullWidth
                                style={{width: '96%', marginLeft: '9px', whiteSpace: 'pre-wrap'}}
                            />
                        </Grid>

                        <Grid item xs={6} style={{marginBottom: '4.5rem'}}>
                            <Typography variant='h4'
                                        style={{color: 'red', marginTop: '25px', marginLeft: '9px', fontSize: '25px'}}>
                            <span
                                style={{color: "black"}}>{isEnglish ? 'Total : ' : '合計 : '}
                            </span>
                                <span>¥</span>
                                <span>{(resultAmount) < 0 ? 0 : resultAmount}</span>
                            </Typography>
                            {isCouponApplied &&
                                <Typography style={{color: 'red', marginLeft: '9px'}}>
                                    {isEnglish ? '(Discount : ' : '(割引 : '}¥-{couponPrice})
                                </Typography>}
                            {calcFebPromotionDiscount() > 1 &&
                                <span
                                    style={{
                                        color: 'green',
                                        marginLeft: '9px'
                                    }}>{isEnglish ? `\n(promotion -¥${calcFebPromotionDiscount()})` :
                                    `\n(プロモーション -¥${calcFebPromotionDiscount()})`}</span>}
                        </Grid>

                        <Grid item xs={6} style={{marginBottom: '4.5rem', textAlign: 'center'}}>
                            <div style={{width: '97%'}}>
                                {!isExceed ?
                                    <Button variant='contained'
                                            fullWidth
                                            color='primary'
                                            style={{marginTop: '15px'}}
                                            onClick={() => {
                                                setActiveStep(activeStep + 1);
                                                document.body.scrollTop = document.documentElement.scrollTop = 0;
                                            }}
                                    >
                                        {isEnglish ? 'go to check' : 'レジへ進む'}
                                    </Button> :
                                    <Button variant='contained'
                                            fullWidth
                                            disabled
                                            style={{marginTop: '15px'}}
                                    >
                                        {isEnglish ? 'go to check' : 'レジへ進む'}
                                    </Button>

                                }
                                {isExceed &&
                                    <p
                                        className='min-price-message'
                                    >{isEnglish ? `Minimum Amount : ¥${minPrice}` : `最低注文額 : ¥${minPrice}`}</p>
                                }

                            </div>
                        </Grid>

                    </Grid>

                </>
                :
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant='h6' style={{'textAlign': 'center'}}>
                            {isEnglish ? 'Your shopping cart is empty.' : 'カートが空です'}
                        </Typography>
                    </Grid>
                </Grid>
            }

            <Dialog open={couponDialog} onClose={() => setCouponDialog(false)}>
                <div style={{border: '7px solid #afeeee'}}>
                    <IconButton onClick={() => setCouponDialog(false)} className={classes.closeButton}>
                        <CloseIcon/>
                    </IconButton>
                    <DialogTitle className={classes.titleWrapper}>
                        <Typography style={{fontWeight: 'bold', fontFamily: 'American Typewriter', fontSize: '20px'}}>
                            {isEnglish ? 'My Coupon' : '所持クーポン'}
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{height: '300px', padding: '0.5rem'}}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center'>{isEnglish ? 'Code' : 'コード'}</TableCell>
                                        <TableCell align='right'>{isEnglish ? 'Discount' : '割引価格'}</TableCell>
                                        <TableCell align='right'>{isEnglish ? 'Action' : 'アクション'}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {myCouponList.map((coupon) => (
                                        <TableRow key={coupon.code}>
                                            <TableCell align='center'>{coupon.code}</TableCell>
                                            <TableCell align='right'>¥{coupon.discounted_price}</TableCell>
                                            <TableCell align='right'>
                                                <Button variant='contained'
                                                        onClick={() => applyMyCoupon(coupon)}>
                                                    {isEnglish ? 'Apply' : '適用'}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {myCouponList.length === 0 &&
                            <div style={{textAlign: 'center'}}>
                                <p>{isEnglish ? 'You don\'t have any coupons' : '現在お持ちのクーポンはございません'}</p>
                            </div>
                        }

                    </DialogContent>
                </div>
            </Dialog>
        </>

    )
}

export default CostcoCart