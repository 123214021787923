import React, {useEffect, useState} from "react";
import {withCookies} from "react-cookie";
import axios from "axios";
import {COSTCO_PERSONAL_COUPON_URL, MY_PROFILE_URL} from "../../Messages/urls";
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Container from "@material-ui/core/Container";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import {BACK} from "../../Messages/constantMessages";
import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles((theme) => ({
    titleImg: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    back: {
        padding: 0,
        marginTop: '1rem',
    },
}));


const PossessionCoupon = (props) => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '/e' || currentUrl.slice(-2) === '_e'
    const token = props.cookies.get('current-token')

    const classes = useStyles()

    const title_eng = 'images/title_my_coupon_eng.jpg'
    const title_jp = 'images/title_my_coupon_jp.jpg'

    const [myProfile, setMyProfile] = useState()
    const [myCouponList, setMyCouponList] = useState([])

    useEffect(() => {
        // Profileを取得
        axios.get(MY_PROFILE_URL, {headers: {'Authorization': `Token ${token}`}})
            .then(res => {
                setMyProfile(res.data[0])
            })
    }, [token])

    useEffect(() => {
        if (myProfile) {
            axios.get(`${COSTCO_PERSONAL_COUPON_URL}?user=${myProfile.user_profile}&is_used=false`)
                .then(res => {
                    setMyCouponList(res.data)
                })
        }
    }, [myProfile])

    return (
        <Container maxWidth='xs' style={{marginTop: '1rem'}}>
            {isEnglish ?
                <img src={title_eng} alt="title" className={classes.titleImg}/>
                :
                <img src={title_jp} alt="title" className={classes.titleImg}/>
            }
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'>{isEnglish ? 'Code' : 'コード'}</TableCell>
                            <TableCell align='right'>{isEnglish ? 'Discount price' : '割引価格'}</TableCell>
                            <TableCell align='right'>{isEnglish ? 'Copy' : 'コピー'}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {myCouponList.map((coupon, index) => (
                            <TableRow key={coupon.code}>
                                <TableCell align='center'>{coupon.code}</TableCell>
                                <TableCell align='right'>¥{coupon.discounted_price}</TableCell>
                                <TableCell align='right'>
                                    <CopyToClipboard text={coupon.code}>
                                        <Tooltip
                                            title={isEnglish ? 'Copied!' : 'コピーされました!'}
                                            arrow
                                            placement='top'
                                            disableFocusListener
                                            disableHoverListener
                                            enterTouchDelay={50}>
                                            <IconButton aria-label="settings">
                                                <FileCopyIcon fontSize='large'/>
                                            </IconButton>
                                        </Tooltip>
                                    </CopyToClipboard>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Grid container className={classes.back}>
                <Grid item xs>
                    <Link href={isEnglish ? '/my_page_e' : '/my_page'} variant="body2" color='textSecondary'>
                        {isEnglish ? BACK : '戻る'}
                    </Link>
                </Grid>
            </Grid>

        </Container>
    )
}

export default withCookies(PossessionCoupon)