import React, {useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import InputForm from "./InputForm";
import Confirm from "./Confirm";
import TransitionComplete from "./TransitionComplete";
import {withCookies} from "react-cookie";
import axios from "axios";
import {MY_PROFILE_URL} from "../../Messages/urls";
import FAQ from "./FAQ";


const ContactUsHome = (props) => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'
    // const {isEnglish} = useContext(LanguageContext)

    // 0: 入力フォーム, 1: 確認フォーム, 2: 送信完了画面
    const [activeStep, setActiveStep] = useState(0)
    // プロフィール取得
    const token = props.cookies.get('current-token')
    const [contactData, setContactData] = useState({
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        order_number: '',
        store: '',
        content: '',
        image: '',
    })

    useEffect(() => {
        // Profileを取得
        axios.get(MY_PROFILE_URL, {headers: {'Authorization': `Token ${token}`}})
            .then(res => {
                setContactData({
                    first_name: res.data[0].first_name,
                    last_name: res.data[0].last_name,
                    phone_number: res.data[0].phone_number,
                })
            })
    }, [token])


    return (
        <div>
            <Container maxWidth='xs'>
                {activeStep === 0 && <FAQ/>}
                {/*スマホサイズ*/}
                <Hidden smUp>
                    <h3 style={{textAlign: 'center'}}>
                        {isEnglish ? 'Contact Us' : 'お問い合わせ'}
                    </h3>
                </Hidden>

                {/*iPadサイズ*/}
                <Hidden xsDown lgUp>
                    <h2 style={{textAlign: 'center'}}>
                        {isEnglish ? 'Contact Us' : 'お問い合わせ'}
                    </h2>
                </Hidden>

                {/*PC*/}
                <Hidden mdDown>
                    <h1 style={{textAlign: 'center'}}>
                        {isEnglish ? 'Contact Us' : 'お問い合わせ'}
                    </h1>
                </Hidden>

                {/*入力フォーム*/}
                {activeStep === 0 &&
                <InputForm
                    contactData={contactData}
                    setContactData={setContactData}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                />
                }

                {/*確認ページ*/}
                {activeStep === 1 &&
                <Confirm
                    contactData={contactData}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                />
                }

                {/*送信完了画面*/}
                {activeStep === 2 &&
                <TransitionComplete/>
                }
            </Container>
        </div>
    )
}

export default withCookies(ContactUsHome)