import React from "react";
import NavBar from "../NavBar/NavBar";
import Container from "@material-ui/core/Container";
import Footer from "../Footer";

const Tmp2Page = () => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'

    return (
        <div className="pageWrapper">
            <NavBar/>
            <Container maxWidth='xs'>
                <h3 style={{textAlign: 'center', marginTop: '3rem', marginBottom: '2rem', whiteSpace: 'pre-wrap'}}>
                    {isEnglish ? 'Christmas / New Year Notice of closure' : '「クリスマス・年末年始」休業のお知らせ'}
                </h3>
                <p style={{whiteSpace: 'pre-wrap'}}>
                    {isEnglish ?
                        'The following schedule will be temporarily closed as a measure to prevent delivery delays and coronavirus infection due to the effects of Costco congestion. We apologize for any inconvenience.\n\n' +
                        '【Christmas】 December 24 - 26th\n' +
                        '【New Year】December 29 - 31st, January 1st - 10th'
                        :
                        'コストコ混雑の影響による配送遅延、コロナウイルス感染予防対策として、下記日程を臨時休業とさせていただきます。ご不便お掛けし大変申し訳ございません。\n\n' +
                        '【クリスマス】12月24日〜26日\n' +
                        '【年末年始】12月29日〜31日、1月1日〜10日'
                    }
                </p>

            </Container>
            <Footer/>
        </div>

    )
}

export default Tmp2Page