import React, {useEffect, useState} from "react";
import "./receipt.css";
import axios from "axios";
import {COSTCO_CARTITEM_URL, COSTCO_FIRST_ORDER_URL, COSTCO_ORDER_URL, MY_PROFILE_URL} from "../../Messages/urls";
import ReceiptOrderList from "./ReceiptOrderList";
import IconButton from '@material-ui/core/IconButton';
import html2canvas from "html2canvas"
import PrintIcon from '@material-ui/icons/Print';
import {withCookies} from "react-cookie";
import ReceiptDownloadError from "./ReceiptDownloadError";


const ReceiptDownload = (props) => {
    const token = props.cookies.get('current-token')
    const [myProfile, setMyProfile] = useState()

    const orderNumber = window.location.href.split('?')[1]
    const [order, setOrder] = useState()
    const [firstOrderItem, setFirstOrderItem] = useState()
    const [cartItem, setCartItem] = useState()
    const [outOfStockItem, setOutOfStockItem] = useState()
    const [isOff, setIsOff] = useState(false)

    let isEnglish = false

    if (order) {
        if (order.payment_method.substr(0, 1) === 'C' || order.payment_method.substr(0, 1) === 'S') {
            isEnglish = true
        }
    }

    if (order && myProfile) {
        if (order.user !== myProfile.user_profile) {
            window.location.href = '/'
        }
    }

    const isAuthenticated = () => {
        if (order && myProfile) {
            if (order.user !== myProfile.user_profile) {
                return false
            }
        }
        return true
    }

    useEffect(() => {
        if (!token) {
            window.location.href = isEnglish ? 'login_e' : 'login'
        }
        // Profileを取得
        axios.get(MY_PROFILE_URL, {headers: {'Authorization': `Token ${token}`}})
            .then(res => {
                setMyProfile(res.data[0])
            })
    }, [token])

    const MonthNameList = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December']

    useEffect(() => {
        if (order && firstOrderItem) {
            let firstOrderItemArr = []
            let orderItemArr = []
            let outOfStockArr = []
            for (let key in firstOrderItem) {
                firstOrderItemArr.unshift(firstOrderItem[key])
            }
            for (let key in order.cart_item) {
                orderItemArr.unshift(order.cart_item[key])
            }
            for (let key in firstOrderItemArr) {
                if (!orderItemArr.includes(firstOrderItemArr[key])) {
                    outOfStockArr.unshift(firstOrderItemArr[key])
                }
            }

            setOutOfStockItem(outOfStockArr)
        }
    }, [order, firstOrderItem])

    useEffect(() => {
        const getMyOrder = async () => {
            const orderResponse = await axios.get(`${COSTCO_ORDER_URL}?id=${orderNumber}`)
            orderResponse.data && setOrder(orderResponse.data[0])
        }
        const getFirstOrderItem = async () => {
            const firstOrderResponse = await axios.get(`${COSTCO_FIRST_ORDER_URL}?id=${orderNumber}`)
            firstOrderResponse.data && setFirstOrderItem(firstOrderResponse.data[0].cart_item)
            firstOrderResponse.data && setIsOff(firstOrderResponse.data[0].memo.includes('10%off'))
        }
        const getCartItem = async () => {
            const item = await axios.get(`${COSTCO_CARTITEM_URL}`)
            item.data && setCartItem(item.data)
        }

        getMyOrder()
        getFirstOrderItem()
        getCartItem()
    }, [orderNumber])

    const saveAsImage = uri => {
        const downloadLink = document.createElement("a")
        if (typeof downloadLink.download === 'string') {
            downloadLink.href = uri
            //  ファイル名
            downloadLink.download = 'receipt.png'
            // Firefox では body の中にダウンロードリンクがないといけないので一時的に追加
            document.body.appendChild(downloadLink)
            // ダウンロードリンクが設定された a タグをクリック
            downloadLink.click();
            // Firefox 対策で追加したリンクを削除しておく
            document.body.removeChild(downloadLink);
        } else {
            window.open(uri)
        }
    }

    const downloadImage = () => {
        const targetImage = document.getElementById('download_content')
        html2canvas(targetImage).then(canvas => {
            const targetImageUri = canvas.toDataURL("img/png")
            saveAsImage(targetImageUri)
        })
    }

    const zeroPadding = (id) => {
        // 注文番号を4桁の0埋めにする
        return (Array(4).join('0') + id).slice(-4);
    }

    const remove_zero_padding = (str) => {
        // 0から始まる場合外して返す
        if (str.substr(0, 1) === '0') {
            return str.substr(1, 1)
        }
        return str
    }

    const getToday = () => {
        const today = new Date()
        return `${today.getFullYear()}年${today.getMonth() + 1}月${today.getDate()}日`
    }

    const getTodayEng = () => {
        const today = new Date()
        return `${MonthNameList[today.getMonth()]} ${today.getDate()} ${today.getFullYear()}`
    }

    const getOrderDay = () => {
        // 注文日を返す
        let date = ''
        if (order) {
            const year = order.created_at.substr(0, 4)
            const month = remove_zero_padding(order.created_at.substr(5, 2))
            const day = remove_zero_padding(order.created_at.substr(8, 2))
            date = `${year}年${month}月${day}日`
        }
        return date
    }

    const getOrderDayEng = () => {
        // 注文日を英語で返す February 2, 2021 (2021/2/2)
        let date = ''
        if (order) {
            const year = order.created_at.substr(0, 4)
            const month = remove_zero_padding(order.created_at.substr(5, 2))
            const day = remove_zero_padding(order.created_at.substr(8, 2))
            date = `${MonthNameList[Number(month) - 1]} ${day} ${year}`
        }
        return date
    }

    const getDeliveryDay = () => {
        let date = ''
        if (order) {
            const year = order.created_at.substr(0, 4)
            const month = order.delivery_date.split(' ')[0].split('/')[0]
            const day = order.delivery_date.split(' ')[0].split('/')[1]
            const time = order.delivery_date.split('  ')[1]
            date = `${year}月${month}月${day}日 ${time}`
        }
        return date
    }

    const getDeliveryDayEng = () => {
        let date = ''
        if (order) {
            const year = order.created_at.substr(0, 4)
            const month = order.delivery_date.split(' ')[0].split('/')[0]
            const day = order.delivery_date.split(' ')[0].split('/')[1]
            const time = order.delivery_date.split('  ')[1]
            date = `${MonthNameList[Number(month) - 1]} ${day} ${year} ${time}`
        }
        return date
    }

    return (
        <div>
            {isAuthenticated() ?
                <div id='download_content'>
                    <div
                        style={{
                            margin: '0 auto',
                            marginBottom: '1rem',
                            marginTop: '1rem',
                            width: '90%',
                            position: 'relative'
                        }}>
                        <img src="images/navbar_logo.png" width="111" style={{position: 'absolute'}}/>

                        <table width="100%"
                               border="0"
                               align="right"
                               cellPadding="0" cellSpacing="0"
                               style={{fontSize: '12px'}}
                        >
                            <tbody>
                            <tr>
                                <td valign="top" align="right">
                                    <b>{isEnglish ? 'Printed date' : '発行日'}： </b>
                                    {isEnglish ? getTodayEng() : getToday()}
                                </td>
                                {/*<td valign="top" align="right">*/}
                                {/*    <b>_________________________様</b>*/}
                                {/*</td>*/}
                            </tr>
                            <tr>
                                <td valign="top" align="right">
                                    <b>{isEnglish ? 'Order Placed' : '注文日'}：</b>
                                    {isEnglish ? getOrderDayEng() : getOrderDay()}
                                </td>
                            </tr>
                            <tr>
                                <td valign="top" align="right">
                                    <b>{isEnglish ? 'Order Number' : '注文番号'}：</b>
                                    #{order && zeroPadding(order.id)}
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div style={{textAlign: 'center', paddingTop: '5rem'}}>
                            <h4>{isEnglish ? 'Receipt' : '領収書'}</h4>
                        </div>

                        <div style={{textAlign: 'center'}}>
                            {isEnglish ?
                                <b>To:_________________________</b> :
                                <b>_________________________様</b>}
                        </div>
                    </div>
                    <br/>
                    <table width="90%" border="0" cellSpacing="0" cellPadding="0" bgcolor="#ffffff"
                           align="center">
                        <tbody>
                        <tr>
                            <td>
                                <br/>

                                <table width="100%" border="0" cellSpacing="0" cellPadding="0"
                                       bgcolor="#000000"
                                       align="center">
                                    <tbody>
                                    <tr bgcolor="#000000">
                                        <td>
                                            <table width="100%" border="0" cellSpacing="3" cellPadding="0"
                                                   align="center" bgcolor="#000000">
                                                <tbody>
                                                <tr bgcolor="#ffffff">
                                                    <td valign="top" colSpan="2">
                                                        <table width="100%" border="0" cellSpacing="0"
                                                               cellPadding="5">
                                                            <tbody>
                                                            <tr bgcolor="#ffffff">
                                                                <td>
                                                                    <b className="sans">
                                                                        <div className="center">
                                                                            {isEnglish ?
                                                                                `Delivered in ${getDeliveryDayEng()}` :
                                                                                `${getDeliveryDay()} 配達`
                                                                            }
                                                                        </div>
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#ffffff" valign="top" colSpan="2">
                                                        <table width="100%" border="0" cellSpacing="0"
                                                               cellPadding="2">
                                                            <tbody>
                                                            <tr valign="top">
                                                                <td width="100%">
                                                                    <table border="0" cellSpacing="0"
                                                                           cellPadding="2" align="right">
                                                                        <tbody>
                                                                        <tr valign="top">
                                                                            <td align="right">
                                                                                &nbsp;
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <table border="0" cellSpacing="2"
                                                                           cellPadding="0" width="100%">
                                                                        <tbody>
                                                                        <tr valign="top">
                                                                            <td valign="top">
                                                                                <b>{isEnglish ? 'Products' : '商品'}</b>
                                                                            </td>
                                                                            <td align="right" valign="top">
                                                                                <b>{isEnglish ? 'Price' : '価格'}</b>
                                                                            </td>
                                                                        </tr>
                                                                        {order &&
                                                                        <ReceiptOrderList
                                                                            isEnglish={isEnglish}
                                                                            firstOrderItem={order.cart_item}
                                                                            cartItems={cartItem}
                                                                            isOutOfStock={false}
                                                                            isOff={isOff}
                                                                        />}

                                                                        {/*{outOfStockItem && Object.keys(outOfStockItem).length !== 0 &&*/}
                                                                        {/*<>*/}
                                                                        {/*    <tr valign="top">*/}
                                                                        {/*        <td valign="top">*/}
                                                                        {/*            <b>{isEnglish ? 'Out of stock items' : '以下、在庫切れ商品'}</b>*/}
                                                                        {/*        </td>*/}
                                                                        {/*    </tr>*/}
                                                                        {/*    <ReceiptOrderList*/}
                                                                        {/*        isEnglish={isEnglish}*/}
                                                                        {/*        firstOrderItem={outOfStockItem}*/}
                                                                        {/*        cartItems={cartItem}*/}
                                                                        {/*        isOutOfStock={true}*/}
                                                                        {/*    />*/}
                                                                        {/*</>}*/}
                                                                        </tbody>
                                                                    </table>
                                                                    <br/>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <br/>

                                <table width="100%" border="0" cellSpacing="0" cellPadding="0"
                                       bgcolor="#000000" align="center">
                                    <tbody>
                                    <tr bgcolor="#000000">
                                        <td>
                                            <table width="100%" border="0" cellSpacing="3"
                                                   cellPadding="0"
                                                   align="center" bgcolor="#000000">
                                                <tbody>
                                                <tr bgcolor="#ffffff">
                                                    <td valign="top" colSpan="2">
                                                        <table width="100%" border="0" cellSpacing="0"
                                                               cellPadding="5">
                                                            <tbody>
                                                            <tr bgcolor="#ffffff">
                                                                <td>
                                                                    <b className="sans">
                                                                        <div
                                                                            className="center">{isEnglish ? 'Payment Information' : '支払い情報'}</div>
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#ffffff" valign="top" colSpan="2">

                                                        <table width="100%" border="0" cellSpacing="0"
                                                               cellPadding="2">
                                                            <tbody>
                                                            <tr valign="top">
                                                                <td width="100%">
                                                                    <table border="0" cellSpacing="0"
                                                                           cellPadding="2"
                                                                           align="right">
                                                                        <tbody>
                                                                        <tr valign="top">
                                                                            <td align="right">
                                                                                <table border="0"
                                                                                       cellPadding="0"
                                                                                       cellSpacing="1">

                                                                                    <tbody>
                                                                                    <tr valign="top">
                                                                                        <td valign="top" align='right'>
                                                                                            <b>{isEnglish ? 'Price' : 'ご請求額'}</b>
                                                                                        </td>

                                                                                    </tr>
                                                                                    <tr valign="top">
                                                                                        <td nowrap="nowrap"
                                                                                            align="right">
                                                                                            {isEnglish ? 'Products' : '商品の小計'}：
                                                                                        </td>
                                                                                        <td nowrap="nowrap"
                                                                                            align="right">
                                                                                            ¥{order && order.products_price}
                                                                                        </td>
                                                                                    </tr>
                                                                                    {order && order.shopping_fee > 250 ?
                                                                                        <>
                                                                                            <tr valign="top">
                                                                                                <td nowrap="nowrap"
                                                                                                    align="right">
                                                                                                    {isEnglish ? 'Fee' : '手数料'}：
                                                                                                </td>
                                                                                                <td nowrap="nowrap"
                                                                                                    align="right">
                                                                                                    ¥{order && order.shopping_fee}
                                                                                                </td>
                                                                                            </tr>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <tr valign="top">
                                                                                                <td nowrap="nowrap"
                                                                                                    align="right">
                                                                                                    {isEnglish ? 'Shopping fee' : '代行料'}：
                                                                                                </td>
                                                                                                <td nowrap="nowrap"
                                                                                                    align="right">
                                                                                                    ¥{order && order.shopping_fee}
                                                                                                </td>
                                                                                            </tr>

                                                                                            <tr valign="top">
                                                                                                <td nowrap="nowrap"
                                                                                                    align="right">
                                                                                                    {isEnglish ? 'Delivery fee' : '配送料'}：
                                                                                                </td>
                                                                                                <td nowrap="nowrap"
                                                                                                    align="right">
                                                                                                    ¥{order && order.delivery_fee}
                                                                                                </td>
                                                                                            </tr>
                                                                                        </>
                                                                                    }


                                                                                    <tr valign="top">
                                                                                        <td nowrap="nowrap"
                                                                                            align="right">
                                                                                            {isEnglish ? 'Tip' : 'チップ'}：
                                                                                        </td>
                                                                                        <td nowrap="nowrap"
                                                                                            align="right">
                                                                                            ¥{order &&
                                                                                        `${order.products_price * order.tip * 0.01} (${order.tip}%)`
                                                                                        }
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr valign="top">
                                                                                        <td nowrap="nowrap"
                                                                                            align="right">
                                                                                            {isEnglish ? 'Discount' : '割引'}：
                                                                                        </td>
                                                                                        <td nowrap="nowrap"
                                                                                            align="right">
                                                                                            -¥{order && order.coupon_price}
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr valign="top">
                                                                                        <td nowrap="nowrap"
                                                                                            align="right">
                                                                                            {isEnglish ? 'Total' : '合計'}：
                                                                                        </td>
                                                                                        <td nowrap="nowrap"
                                                                                            align="right">
                                                                                            ¥{order && order.total}
                                                                                        </td>
                                                                                    </tr>

                                                                                    </tbody>
                                                                                </table>

                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>

                                                                    <b>{isEnglish ? 'Payment method' : '支払い方法'}： </b>
                                                                    <br/>
                                                                    {order && order.payment_method}
                                                                    <br/>
                                                                    <br/>
                                                                    <b>{isEnglish ? 'Delivery address' : '請求先住所'}：</b>
                                                                    <div
                                                                        className="displayAddressDiv">
                                                                        <ul className="displayAddressUL">
                                                                            <li className="displayAddressLI displayAddressFullName">
                                                                                {order && order.name}
                                                                            </li>

                                                                            <li className="displayAddressLI displayAddressStateOrRegionAddressLine1">
                                                                                {order && order.address}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>

                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <div className="center" style={{width: '90%', margin: '0 auto', marginTop: '1rem'}}>
                        {isEnglish ? 'Thank you very much for your visit. Please contact us if you would like a receipt that includes sales tax.' : 'ご利用いただき誠にありがとうございました。消費税額等が記載された領収書をご希望の場合はお問い合わせください。尚、当社は適格請求書発行事業者ではありません。'}
                    </div>
                    <div className="center">
                        <b>&copy;2020 Husky Cart.</b>
                        <IconButton size="large" onClick={() => window.print()} style={{paddingTop: '6px'}}>
                            <PrintIcon fontSize="inherit"/>
                        </IconButton>
                    </div>
                </div>
                :
                <ReceiptDownloadError/>
            }
        </div>
    )
}

export default withCookies(ReceiptDownload)