export const isValidArea = (areaName) => {
    const validAreaNames = [
        'Shinjuku',
        'Chiyoda',
        'Chuo',
        'Koto',
        'Minato',
        'Shibuya',
        'Shinagawa',
        'Meguro',
        'Setagaya',
        'Ota',
        'Kawasaki-shi Kawasaki',
        'Kawasaki-shi Saiwai',
        'Kawasaki-shi Nakahara',
        'Yokohama-shi Kohoku',
        'Yokohama-shi Tsurumi',
        '新宿区',
        '千代田区',
        '中央区',
        '江東区',
        '港区',
        '渋谷区',
        '品川区',
        '目黒区',
        '世田谷区',
        '大田区',
        '川崎市 川崎区',
        '川崎市 幸区',
        '川崎市 中原区',
        '横浜市 港北区',
        '横浜市 鶴見区',
    ]
    return validAreaNames.includes(areaName)
}


export const isValidPrefecture = (prefecture) => {
    const validPrefectures = [
        'Tokyo', '東京都', 'Kanagawa', '神奈川県'
    ]
    return validPrefectures.includes(prefecture)
}

export const isMatchLanguagePrefecture = (prefecture, isEnglish) => {
    if (isEnglish) {
        return ['Tokyo', 'Kanagawa'].includes(prefecture)
    } else {
        return ['東京都', '神奈川県'].includes(prefecture)
    }
}


export const isValidCombination = (prefecture, cityName) => {
    const tokyoEngNames = [
        'Shinjuku',
        'Chiyoda',
        'Chuo',
        'Koto',
        'Minato',
        'Shibuya',
        'Shinagawa',
        'Meguro',
        'Setagaya',
        'Ota',
    ]
    const tokyoJpNames = [
        '新宿区',
        '千代田区',
        '中央区',
        '江東区',
        '港区',
        '渋谷区',
        '品川区',
        '目黒区',
        '世田谷区',
        '大田区',
    ]
    const kanagawaEngNames = [
        'Kawasaki-shi Kawasaki',
        'Kawasaki-shi Saiwai',
        'Kawasaki-shi Nakahara',
        'Yokohama-shi Kohoku',
        'Yokohama-shi Tsurumi',
    ]
    const kanagawaJpNames = [
        '川崎市 川崎区',
        '川崎市 幸区',
        '川崎市 中原区',
        '横浜市 港北区',
        '横浜市 鶴見区',
    ]

    if (isValidPrefecture(prefecture) && isValidArea(cityName)) {
        switch (prefecture) {
            case 'Tokyo':
                return tokyoEngNames.includes(cityName)
            case '東京都':
                return tokyoJpNames.includes(cityName)
            case 'Kanagawa':
                return kanagawaEngNames.includes(cityName)
            case '神奈川県':
                return kanagawaJpNames.includes(cityName)
            default:
                return false
        }
    }
    return false
}

export const removeQuery = (url) => {
    if (url.indexOf('?') !== -1) {
        return  url.split('?')[0]
    }
    return url
}