import React, {useContext, useState} from "react";
import {RegisterContext} from "./RegisterTop";
import NumberFormat from "react-number-format";
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";

const CodeForm = () => {
    const {isEnglish, setActiveStep, email, authenticationCode} = useContext(RegisterContext)

    const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
        const {onChange, ...other} = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                isNumericString
            />
        );
    });

    NumberFormatCustom.propTypes = {
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    const styles = {
        codeInputFirst: {
            fontSize: '46px',
            textAlign: 'right',
            width: '56px',
            height: '56px'
        },
        codeInput: {
            marginLeft: '1rem',
            fontSize: '46px',
            textAlign: 'center',
            width: '56px',
            height: '56px',
        }
    }

    const [codeDict, setCodeDict] = useState({c1: '', c2: '', c3: '', c4: '', code: ''});
    const handleCodeDictChange = () => e => {
        const {name, value} = e.target;
        setCodeDict(prevState => ({
            ...prevState,
            [name]: value
        }));
        setCodeDict(prevState => ({
            ...prevState,
            code: prevState.c1 + prevState.c2 + prevState.c3 + prevState.c4
        }));
    }

    const handleFocus = (cn) => {
        const focusDict = {
            'c1': codeDict.code.length === 0,
            'c2': codeDict.code.length === 1,
            'c3': codeDict.code.length === 2,
            'c4': codeDict.code.length === 3
        }
        if (codeDict.code.length === 3) {
            if (codeDict.c1 === '') {
                focusDict['c4'] = false
                focusDict['c1'] = true
            }
            if (codeDict.c2 === '') {
                focusDict['c4'] = false
                focusDict['c2'] = true
            }
            if (codeDict.c3 === '') {
                focusDict['c4'] = false
                focusDict['c3'] = true
            }
        }
        return focusDict[cn]
    }

    const isCorrectCode = codeDict.code === authenticationCode
    const isIncorrectCode = codeDict.code.length === 4 && !isCorrectCode

    return (
        <div>
            <p>
                {isEnglish ?
                    `Please enter the 4-digit code sent to ${email}` :
                    `${email}に送信された4桁の認証コードを入力してください。`}
            </p>
            <div style={{textAlign: 'center'}}>
                <TextField
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                        style: styles.codeInputFirst,
                    }}
                    variant="outlined"
                    value={codeDict.c1}
                    name='c1'
                    onChange={handleCodeDictChange()}
                    autoFocus={handleFocus('c1')}
                    onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 1)
                    }}
                />
                <TextField
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                        style: styles.codeInput,
                    }}
                    variant="outlined"
                    value={codeDict.c2}
                    name='c2'
                    onChange={handleCodeDictChange()}
                    autoFocus={handleFocus('c2')}
                    onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 1)
                    }}
                />
                <TextField
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                        style: styles.codeInput,
                    }}
                    variant="outlined"
                    value={codeDict.c3}
                    name='c3'
                    onChange={handleCodeDictChange()}
                    autoFocus={handleFocus('c3')}
                    onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 1)
                    }}
                />
                <TextField
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                        style: styles.codeInput,
                        maxLength: 0,
                    }}
                    variant="outlined"
                    value={codeDict.c4}
                    name='c4'
                    onChange={handleCodeDictChange()}
                    autoFocus={handleFocus('c4')}
                    onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 1)
                    }}
                />

                {isCorrectCode && setActiveStep(2)}
                {isIncorrectCode && <p>{isEnglish ? 'The code is incorrect.' : 'コードが間違っています。'}</p>}

                <h3 style={{marginTop: '5rem'}}>{isEnglish ? 'If you have not received email' : '認証コードが届かない方'}</h3>
                <ul style={{textAlign: 'left'}}>
                    <li style={{marginBottom: '1rem'}}>{isEnglish ? 'The e-mail address you entered may be incorrect.' : 'ご入力いただいたメールアドレスが誤っている可能性があります。'}</li>
                    <li style={{marginBottom: '1rem'}}>{isEnglish ? 'It may have been sorted into your spam folder. Please check your spam folder if it does not arrive in your inbox.' : '迷惑メールフォルダに振り分けられている可能性があります。受信トレイに届かない場合には迷惑メールフォルダをご確認ください。'}</li>
                    <li style={{marginBottom: '1rem'}}>{isEnglish ? 'You may be receiving our messages from a specified domain. Please check your settings.' : 'ドメイン指定受信をされている可能性があります。設定をご確認ください。'}</li>
                </ul>
            </div>
        </div>
    )
}

export default CodeForm