import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        background: '#FFF',
        paddingBottom: '1rem',
        [theme.breakpoints.only('xs')]: {
            paddingBottom: '0',
        },
        [theme.breakpoints.up('md')]: {
            paddingBottom: '0',
        },
    },

    itemImage: {
        borderRadius: '50%',
        cursor: 'pointer',
        // pcサイズ
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '50px',
        height: '50px',

        [theme.breakpoints.only('md')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: '70px',
            height: '70px',
        },
        [theme.breakpoints.only('xs')]: {
            marginLeft: '2px',
            marginRight: '2px',
            // width: '45px',
            // height: '45px',
            width: '30px',
            height: '30px',
        },
    },

    lgHidden: {
        paddingTop: '1rem',
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    }
}));
const SnsLinks = () => {
    const classes = useStyles()
    // アイコン画像
    const insta_icon = 'images/sns_insta_icon.png'
    const facebook_icon = 'images/sns_facebook_sq.png'
    const twitter_icon = 'images/sns_twitter_sq.png'

    // リンク
    const insta_link = 'https://www.instagram.com/husky_cart'
    const facebook_link = 'https://www.facebook.com/huskycart'
    const twitter_link = 'https://twitter.com/husky_cart'

    return (
        <div className={classes.root}>
            {/*SNS ICONS*/}
            <div className={classes.lgHidden}>
                <Box display="flex" justifyContent="center">

                    <Box>
                        <a href={insta_link}
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                            <img
                                className={classes.itemImage}
                                src={insta_icon}
                                alt="insta_icon"
                            />
                        </a>
                    </Box>

                    <Box>
                        <a href={twitter_link}
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                            <img
                                className={classes.itemImage}
                                src={twitter_icon}
                                alt="twitter"
                            />
                        </a>
                    </Box>

                    <Box>
                        <a href={facebook_link}
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                            <img
                                className={classes.itemImage}
                                src={facebook_icon}
                                alt="facebook_icon"
                            />
                        </a>
                    </Box>


                </Box>
            </div>
            <Hidden lgUp>
                <div style={{textAlign: 'center'}}>
                    <p style={{
                        marginTop: '1rem',
                        marginBottom: '42px',
                        fontSize: '12px',
                        color: 'rgba(0, 0, 0, 0.54)'
                    }}>© 2020-2024 Huskycart合同会社</p>
                </div>
            </Hidden>
        </div>
    )
}

export default SnsLinks