import React, {useContext, useEffect, useState} from "react";
import {CostcoContext} from "./CostcoHome";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import axios from "axios"
import {COSTCO_CARTITEM_URL, COSTCO_ORDER_URL} from "../../Messages/urls";


const CartLink = () => {
    const {setCart, products} = useContext(CostcoContext)
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'
    const orderNumber = currentUrl.split('?')[1].split('&')[0]
    const [open, setOpen] = useState(true)
    const [cartItemList, setCartItemList] = useState([])

    let tmpCart = []

    const handleClose = () => {
        setOpen(false)
    }
    const addToCart = (index, quantity) => {
        for (let i = 0; i < quantity; i++) {
            let targetProduct = products.find(product => product.id === index)
            if (targetProduct) {
                tmpCart.unshift(targetProduct)
            }
            console.log(tmpCart)
        }
    }

    const getCartItem = async (cartItemIndex) => {
        await axios.get(`${COSTCO_CARTITEM_URL}?id=${cartItemIndex}`)
            .then(res => {
                addToCart(res.data[0]['product'], res.data[0]['quantity'])
            })
    }

    useEffect(() => {
        axios.get(`${COSTCO_ORDER_URL}?id=${orderNumber}`)
            .then(res => {
                setCartItemList(res.data[0]['cart_item'])
            })
    }, [orderNumber])

    useEffect(() => {
        if (products.length !== 0) {
            for (const key in cartItemList) {
                getCartItem(cartItemList[key])
            }
        }
        setCart(tmpCart)
    }, [cartItemList, products])


    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{isEnglish ? 'Cart has been updated' : 'カートを更新しました'}</DialogTitle>
                <DialogActions style={{margin: '0 auto'}}>
                    <Button onClick={handleClose} color="default" variant='contained'>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default CartLink