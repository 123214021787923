import React, {useContext} from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from "@material-ui/core/Typography";
import {CostcoContext} from "./CostcoHome";
import Hidden from "@material-ui/core/Hidden";
import {useWindowDimensions} from "../utils/useWindowDimensions";

const CategoryList = () => {
    const {
        categories,
        filterQuery,
        setFilterQuery,
        setOffset,
        products,
        classes,
        token,
        setLoginDialogOpen
    } = useContext(CostcoContext)
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'
    // const {isEnglish} = useContext(LanguageContext)
    const {width} = useWindowDimensions()
    const scrollTopValue = width >= 1280 ? 100 : 70

    const handleCategoryFilter = (categoryId) => {
        if (!token) {
            setLoginDialogOpen(true);
        } else {
            setOffset(0)
            setFilterQuery({...filterQuery, "category": categoryId})
            document.body.scrollTop = document.documentElement.scrollTop = scrollTopValue;
        }
    }

    const numberOfProductsInCategory = (categoryId) => {
        return (products.filter(product => product.category === categoryId).length)
    }

    return (
        <>
            {/*PCサイズの時のみ表示*/}
            <Hidden mdDown>
                <List className={classes.categoryList}>
                    <ListItem button onClick={() => handleCategoryFilter()}>
                        <ListItemText>
                            <Typography variant='body2'>{isEnglish ? 'All Categories' : '全てのカテゴリー'}</Typography>
                        </ListItemText>
                        <ListItemSecondaryAction>
                            <Typography variant='body2'>({products.length})</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    {categories.map((category) => {
                        return (
                            <ListItem button onClick={() => handleCategoryFilter(category.id)} key={category.id}>
                                <ListItemText>
                                    <Typography
                                        variant='body2'>{isEnglish ? category.english_name : category.name}</Typography>
                                </ListItemText>
                                <ListItemSecondaryAction>
                                    <Typography variant='body2'>({numberOfProductsInCategory(category.id)})</Typography>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )
                    })}
                </List>
            </Hidden>
        </>
    )
}

export default CategoryList
