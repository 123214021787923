import React, {useState} from "react";
import Footer from "../Footer";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";
import axios from "axios";
import {FORGET_PASSWORD_URL} from "../../Messages/urls";
import NavBar from "../NavBar/NavBar";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    span: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'teal',
    },
    spanError: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'fuchsia',
        marginTop: 10,
    },
    margin: {
        margin: theme.spacing(1),
    },
    titleImg: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
    },
}))

const ForgetPasswordPage = () => {
    const classes = useStyles()
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'
    const [email, setEmail] = useState('')
    const [activeStep, setActiveStep] = useState(0)

    const title_eng = 'images/title_change_password_eng.jpeg'
    const title_jp = 'images/title_change_password_jp.jpeg'


    const inputChangeEmail = () => event => {
        setEmail(event.target.value)
    }

    const sendConfirmMail = async (event) => {
        const language = isEnglish ? 'eng' : 'jp'
        event.preventDefault()
        try {
            const res = await axios.post(`${FORGET_PASSWORD_URL}?language=${language}`, {'email': email},
                {headers: {'Content-Type': 'application/json'}})
            console.log(res)
            setActiveStep(activeStep+1)
        } catch (e) {
            console.log('無効なメールアドレスです')
        }
    }
    return (
        <div className='pageWrapper'>
             <NavBar/>
            <Container maxWidth='xs'>
                {/*メール送信フォーム*/}
                {activeStep === 0 &&
                <form onSubmit={sendConfirmMail}>
                    <div className={classes.paper}>
                        {isEnglish ?
                            <img src={title_eng} alt="title" className={classes.titleImg}/>
                            :
                            <img src={title_jp} alt="title" className={classes.titleImg}/>
                        }

                        <FormControl
                            className={clsx(classes.margin, classes.textField)}
                            variant="standard"
                            fullWidth
                        >
                            <TextField
                                variant="standard" margin="normal"
                                fullWidth
                                label={isEnglish ? 'Email' : 'メールアドレス'}
                                name="email"
                                value={email}
                                placeholder='support@husky-cart.com'
                                style={{marginTop: '0'}}
                                onChange={inputChangeEmail()}
                            />
                        </FormControl>

                        <Button
                            className={classes.submit}
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            disabled={!email}
                        >{isEnglish ? 'Sending a confirmation email' : '確認メール送信'}</Button>
                    </div>
                </form>
                }

                {/*メール送信完了画面*/}
                {activeStep === 1 &&
                <div className={classes.paper}>
                    {isEnglish ?
                        <img src={title_eng} alt="title" className={classes.titleImg}/>
                        :
                        <img src={title_jp} alt="title" className={classes.titleImg}/>
                    }

                    {isEnglish ? 'We sent you an email.': 'メールを送信しました'}

                </div>
                }
            </Container>
            <Footer/>
        </div>
    )
}

export default ForgetPasswordPage