import React, {useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ChildCareIcon from '@material-ui/icons/ChildCare';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Typography from "@material-ui/core/Typography";
import Popover from '@material-ui/core/Popover';
import {withCookies} from "react-cookie";
import axios from "axios";
import {COSTCO_ORDER_URL, MY_PROFILE_URL} from "../../Messages/urls";
import {Button} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import PeopleIcon from '@material-ui/icons/People';
import RedeemIcon from '@material-ui/icons/Redeem';
import Link from "@material-ui/core/Link";
import {BACK} from "../../Messages/constantMessages";
import OrderProgressBar from "./OrderProgressBar";


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    icon: {
        margin: theme.spacing(2),
    },
    container: {
        margin: "auto",
    },
    back: {
        padding: 0,
        marginTop: '1rem',
    },
    titleImg: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    iconButton: {
        fontSize: '40px',
        borderRadius: "0",
    },
    buttonWrapper: {
        borderRadius: "0",
        width: '128px',
        paddingTop: '40%',
        paddingBottom: '40%',
    },
    popMessage: {
        padding: theme.spacing(2),
        backgroundColor: 'black',
        color: 'white'
    },
    wrapper: {
        textAlign: 'center',
        height: '40vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonLabel: {
        position: 'absolute',
        top: "80%",
        left: "50%",
        transform: 'translate(-50%, -50%)',
        WebkitTransform: 'translate(-50%, -50%)',
        msTransform: 'translate(-50%, -50%)',
        width: '128px',
        cursor: 'pointer',
        fontSize: '15px',
    },
}));


const UserHome = (props) => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '/e' || currentUrl.slice(-2) === '_e'
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const token = props.cookies.get('current-token')
    const [myProfile, setMyProfile] = useState()

    // admin 内訳 2: kumeta, 3: tanabe, 7: nobukawa, 8: ooikawa, 10: nakamura
    const adminIdList = [2, 3, 7, 8, 10]

    const titleEng = 'images/title_my_account_eng.jpeg'
    const titleJp = 'images/title_my_account_jp.jpeg'
    const profileIcon = 'images/husky_account_icon.jpg'

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (!token) {
            window.location.href = isEnglish ? 'login_e' : 'login'
        }
    }, [token, isEnglish])


    const [order, setOrder] = useState()
    useEffect(() => {
        // Profileを取得
        axios.get(MY_PROFILE_URL, {headers: {'Authorization': `Token ${token}`}})
            .then(res => {
                setMyProfile(res.data[0])
            })

    }, [token])

    useEffect(() => {
        if (myProfile) {
            axios.get(`${COSTCO_ORDER_URL}?user=${myProfile.user_profile}&shopping_complete=true`)
                .then(res => {
                    setOrder(res.data)
                })
        }
    }, [myProfile])

    return (
        <Container maxWidth='xs' style={{marginBottom: '2rem'}}>
            <div className={classes.paper}>
                {isEnglish ?
                    <img src={titleEng} alt="title" className={classes.titleImg}/>
                    :
                    <img src={titleJp} alt="title" className={classes.titleImg}/>}
                <img className={classes.icon}
                     src={profileIcon} alt="icon" width='30%' aria-describedby={id}
                     onClick={handleClick}/>

                <OrderProgressBar isEnglish={isEnglish} order={order}/>

                <Grid container spacing={3} style={{marginTop: '2rem'}}>
                    <Grid item xs={6}>
                        <Card className={classes.root}
                              onClick={() => window.location.href = isEnglish ? 'profile_e' : 'profile'}>
                            <CardActionArea>
                                <CardContent style={{height: '40px'}}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item xs={4} style={{
                                                display: 'flex',
                                                verticalAlign: 'center',
                                                justifyContent: 'center',
                                                flexWrap: 'wrap',
                                            }}>
                                                <ChildCareIcon fontSize='large'/>
                                            </Grid>
                                            {isEnglish ?
                                                <Grid item xs={8} style={{
                                                    textAlign: 'center',
                                                    lineHeight: '40px',
                                                }}>Edit Profile</Grid>
                                                :
                                                <Grid item xs={8} style={{
                                                    textAlign: 'center',
                                                    // lineHeight: '40px',
                                                }}>プロフィール編集</Grid>
                                            }
                                        </Grid>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>

                    <Grid item xs={6}>
                        <Card className={classes.root}
                              onClick={() => window.location.href = isEnglish ? 'history_e' : 'history'}>
                            <CardActionArea>
                                <CardContent style={{height: '40px'}}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item xs={4} style={{
                                                display: 'flex',
                                                verticalAlign: 'center',
                                                justifyContent: 'center',
                                                flexWrap: 'wrap'
                                            }}>
                                                <ShoppingCartIcon fontSize='large'/>
                                            </Grid>
                                            {/*日本語が1行で英語が2行だから分けてる*/}
                                            {isEnglish ?
                                                <Grid item xs={8} style={{
                                                    textAlign: 'center',
                                                    // lineHeight: '40px',
                                                }}>Purchase History</Grid>
                                                :
                                                <Grid item xs={8} style={{
                                                    textAlign: 'center',
                                                    lineHeight: '40px',
                                                }}>購入履歴</Grid>
                                            }

                                        </Grid>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>

                    <Grid item xs={6}>
                        <Card className={classes.root}
                              onClick={() => window.location.href = isEnglish ? 'coupon_e' : 'coupon'}>
                            <CardActionArea>
                                <CardContent style={{height: '40px'}}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item xs={4} style={{
                                                display: 'flex',
                                                verticalAlign: 'center',
                                                justifyContent: 'center',
                                                flexWrap: 'wrap'
                                            }}>
                                                <RedeemIcon fontSize='large'/>
                                            </Grid>
                                            <Grid item xs={8} style={{
                                                textAlign: 'center',
                                                lineHeight: '40px',
                                            }}>{isEnglish ? 'My coupon' : 'マイクーポン'}</Grid>
                                        </Grid>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>

                    <Grid item xs={6}>
                        <Card className={classes.root}
                              onClick={() => window.location.href = isEnglish ? 'invite_e' : 'invite'}>
                            <CardActionArea>
                                <CardContent style={{height: '40px'}}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item xs={4} style={{
                                                display: 'flex',
                                                verticalAlign: 'center',
                                                justifyContent: 'center',
                                                flexWrap: 'wrap'
                                            }}>
                                                <PeopleIcon fontSize='large'/>
                                            </Grid>
                                            <Grid item xs={8} style={{
                                                textAlign: 'center',
                                                lineHeight: '40px',
                                            }}>{isEnglish ? 'Invite friend' : 'お友達招待'}</Grid>
                                        </Grid>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container className={classes.back}>
                    <Grid item xs>
                        <Link href={isEnglish ? '/e' : '/'} variant="body2" color='textSecondary'>
                            {isEnglish ? BACK : '戻る'}
                        </Link>
                    </Grid>
                </Grid>

                {myProfile && adminIdList.includes(myProfile.id) &&
                <Button
                    onClick={() => window.location.href = '/admin'}
                    style={{marginTop: '1rem'}}
                    variant='contained'
                    color='default'>
                    Costco</Button>}


                {myProfile && adminIdList.includes(myProfile.id) &&
                <Button
                    onClick={() => window.location.href = '/costco_order_summary'}
                    style={{marginTop: '1rem'}}
                    variant='contained'
                    color='default'>
                    Order Summary</Button>}

                {myProfile && adminIdList.includes(myProfile.id) &&
                <Button
                    onClick={() => window.location.href = '/area_scheduler'}
                    style={{marginTop: '1rem'}}
                    variant='contained'
                    color='default'>
                    Area Scheduler</Button>}

            </div>

        </Container>
    )
}

export default withCookies(UserHome)