import React, {useContext} from "react";
import TextField from "@material-ui/core/TextField";
import {LABEL_PHONE_NUMBER} from "../../Messages/constantMessages";
import {LanguageContext} from "../../contexts/LanguageContext";
import Grid from "@material-ui/core/Grid";

const DisplayProfile = ({myProfile}) => {
    const {isEnglish} = useContext(LanguageContext)

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        disabled
                        variant="outlined" margin="normal"
                        fullWidth
                        label={isEnglish ? 'Last Name' : '苗字'}
                        name="last_name"
                        value={myProfile.last_name}
                        InputProps={{readOnly: true,}}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled
                        variant="outlined" margin="normal"
                        fullWidth
                        label={isEnglish ? 'First Name' : '名前'}
                        name="first_name"
                        value={myProfile.first_name}
                        InputProps={{readOnly: true,}}
                    />
                </Grid>
            </Grid>

            <TextField
                disabled
                variant="outlined" margin="normal"
                fullWidth
                label={isEnglish ? 'Postal Code (no hyphen)' : '郵便番号(ハイフンなし)'}
                name="postal_code"
                value={myProfile.postal_code}
                InputProps={{readOnly: true,}}
            />
            <TextField
                disabled
                variant="outlined" margin="normal"
                fullWidth
                label={isEnglish ? 'Prefecture' : '都道府県'}
                name="prefecture"
                value={myProfile.prefecture}
                InputProps={{readOnly: true,}}
            />
            <TextField
                disabled
                variant="outlined" margin="normal"
                fullWidth
                label={isEnglish ? 'City Name' : '市区町村名'}
                name="city_name"
                value={myProfile.city_name}
                InputProps={{readOnly: true,}}
            />
            <TextField
                disabled
                variant="outlined" margin="normal"
                fullWidth
                label={isEnglish ? 'Place Name and Address' : '地名・番地'}
                name="place_number"
                value={myProfile.place_number}
                InputProps={{readOnly: true,}}
            />
            <TextField
                disabled
                variant="outlined" margin="normal"
                fullWidth
                label={isEnglish ? 'Building name, floor and room number' : '建物名・階・部屋番号'}
                name="building_floor_room_name"
                value={myProfile.building_floor_room_name}
                InputProps={{readOnly: true,}}
            />

            <TextField
                disabled
                variant="outlined" margin="normal"
                fullWidth
                label={isEnglish ? LABEL_PHONE_NUMBER : '電話番号'}
                name="phone_number"
                value={myProfile.phone_number}
                InputProps={{readOnly: true,}}
            />
        </>

    )
}

export default DisplayProfile
