import React from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer";
import '../styles/operatingInfo.css'
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import Button from '@material-ui/core/Button';


const CompanyPage = () => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'

    return (
        <div className='pageWrapper'>
            <NavBar/>
            <Container maxWidth='lg' style={{marginBottom: '16px'}}>
                <div>
                    {/*スマホサイズ*/}
                    <Hidden smUp>
                        <h3 style={{textAlign: 'center'}}>{isEnglish ? 'Company Profile' : '会社概要'}</h3>
                    </Hidden>

                    {/*iPadサイズ*/}
                    <Hidden xsDown lgUp>
                        <h2 style={{textAlign: 'center'}}>{isEnglish ? 'Company Profile' : '会社概要'}</h2>
                    </Hidden>

                    {/*PCサイズ*/}
                    <Hidden mdDown>
                        <h1 style={{textAlign: 'center'}}>{isEnglish ? 'Company Profile' : '会社概要'}</h1>
                    </Hidden>

                    <table bgcolor="#999999" border="0" cellPadding="9" cellSpacing="1"
                           className='tableWrapper'
                    >
                        <tr>
                            <th bgcolor="#ffffe0" width='60'>{isEnglish ? 'Service name' : 'サービス名'}</th>
                            <td bgcolor="#FFFFFF">
                                <a href="/"
                                   style={{
                                       color: 'blue',
                                       textDecoration: 'none'
                                   }}>{isEnglish ? 'Huskycart' : 'Huskycart(ハスキーカート)'}</a>
                            </td>
                        </tr>
                        <tr>
                            <th bgcolor="#ffffe0" width='60'>{isEnglish ? 'Service Launch' : 'サービス開始'}</th>
                            <td bgcolor="#FFFFFF">
                                {isEnglish ? 'October 1st, 2020〜' : '2020年10月1日〜'}
                            </td>
                        </tr>
                        <tr>
                            <th bgcolor="#EEEEEE" width='60'>{isEnglish ? 'Company Name' : '会社名'}</th>
                            <td bgcolor="#FFFFFF">
                                {isEnglish ? 'Huskycart LLC' : 'Huskycart合同会社'}
                                {/*<a href="/ceo_profile"*/}
                                {/*   style={{color: 'blue', textDecoration: 'none'}}>信川 拳</a>*/}
                            </td>
                        </tr>
                        <tr>
                            <th bgcolor="#EEEEEE">{isEnglish ? 'Date of Establishment' : '設立日'}</th>
                            <td bgcolor="#FFFFFF">{isEnglish ? 'May 2, 2022' : '2022年5月2日'}</td>
                        </tr>
                        <tr>
                            <th bgcolor="#EEEEEE">{isEnglish ? 'Capital stock' : '資本金'}</th>
                            <td className='tableContent' bgcolor="#FFFFFF">
                                {isEnglish ? '1,000,000 yen' : '100万円'}
                            </td>
                        </tr>
                        <tr>
                            <th bgcolor="#EEEEEE">{isEnglish ? 'Location' : '所在地'}</th>
                            <td bgcolor="#FFFFFF">
                                {isEnglish ? '2-2-15 Minami-Aoyama, Minato-ku, Tokyo' : '東京都港区南青山2-2-15'}
                            </td>
                        </tr>
                        <tr>
                            <th bgcolor="#EEEEEE">{isEnglish ? 'CEO' : '代表者'}</th>
                            <td bgcolor="#FFFFFF">{isEnglish ? 'Ken Nobukawa' : '信川 拳'}</td>
                        </tr>
                        <tr>
                            <th bgcolor="#EEEEEE">{isEnglish ? 'Business' : '事業内容'}</th>
                            <td bgcolor="#FFFFFF" style={{whiteSpace: 'pre-wrap'}}>
                                {isEnglish ?
                                    '・Delivery service \n' +
                                    '・Planning, production, sales, distribution, operation and management of EC (electronic commerce) sites and various other websites. \n' +
                                    '・Any and all businesses incidental or related to the preceding items'
                                    :
                                    '・買い物代行業 \n' +
                                    '・EC(電子商取引)サイト、その他各種ウェブサイトの企画、制作、販売、配信、運営及び管理 \n' +
                                    '・前各号に附帯関連する一切の事業'}
                            </td>
                        </tr>
                    </table>
                </div>

            </Container>
            <Footer/>
        </div>
    )
}

export default CompanyPage