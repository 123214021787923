import React, {createContext, useState} from 'react'


export const LanguageContext = createContext()

const LanguageContextProvider = (props) => {
    const [isEnglish, setIsEnglish] = useState(false)
    const toggleLanguage = () => {
        setIsEnglish(!isEnglish)
    }
    return (
        <LanguageContext.Provider value={{isEnglish, setIsEnglish, toggleLanguage}}>
            {props.children}
        </LanguageContext.Provider>
    )
}

export default LanguageContextProvider