import React, {useEffect, useState} from "react";
import axios from "axios";
import {MY_PROFILE_URL} from "../../Messages/urls";
import {withCookies} from "react-cookie";
import AdminNoAcess from "./AdminNoAccess";
import AdminOrder from "./AdminOrder";


const AdminHome = (props) => {
    const token = props.cookies.get('current-token')
    const [myProfile, setMyProfile] = useState()
    // admin 内訳 2: kumeta, 3: tanabe, 7: nobukawa, 8: ooikawa, 10: nakamura
    const adminIdList = [2, 3, 7, 8, 10]

    useEffect(() => {
        // Profileを取得
        axios.get(MY_PROFILE_URL, {headers: {'Authorization': `Token ${token}`}})
            .then(res => {
                setMyProfile(res.data[0])
            })
    }, [token])

    return (
        <div>
            {myProfile ? (
                    !adminIdList.includes(myProfile.id) ?
                        <AdminNoAcess/> :
                        <AdminOrder/>
                ) :
                <AdminNoAcess/>
            }
        </div>
    )
}


export default withCookies(AdminHome)