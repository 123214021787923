import React from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {makeStyles} from "@material-ui/core/styles";
import {withCookies} from 'react-cookie';
import WhenLoggedIn from "./WhenLoggedIn";
import WhenLoggedOut from "./WhenLoggedOut";
import Hidden from "@material-ui/core/Hidden";
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import {removeQuery} from "../utils/func";


const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        margin: '5px auto auto auto',
        zIndex: '100'
    },
    titleButton: {
        color: 'white'
    },
    appbar: {
        backgroundColor: '#afeeee',
        zIndex: 10,
        // position: 'relative',
    },
    buttonMargin: {
        marginLeft: theme.spacing(1)
    },
    logo: {
        flexGrow: 1
    },
    toolBar: {
        paddingRight: '10px',
        paddingLeft: '8px',
        height: '70px',
        [theme.breakpoints.up('lg')]: {
            height: '100px',
        },
    },
    languageButtonLg: {
        padding: '2px 0px 1px 8px',
        borderRadius: '5%',
        border: '2px solid #afeeee',
        '&:hover': {
            border: '2px solid',
        },
    }
}))

const NavBar = (props) => {
    const classes = useStyles()
    // ログイン状態の判断はtokenがあるかないか
    const token = props.cookies.get('current-token')

    const currentUrl = removeQuery(window.location.href)
    // console.log(removeQuery(window.location.href))
    // const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '/e' || currentUrl.slice(-2) === '_e'

    const isTopPage = currentUrl.slice(-1) === '/' || currentUrl.slice(-2) === '/e'

    const englishURL = isTopPage ? '/e' : currentUrl + '_e'
    const japaneseURL = isTopPage ? '/' : currentUrl.replace('_e', '')

    // 英語: 1, 日本語: 0
    const language = isEnglish ? 1 : 0

    const logo = 'images/navbar_logo.png'

    return (
        <AppBar position='static' className={classes.appbar}>
            <Toolbar className={classes.toolBar}>

                {/*スマホサイズ*/}
                <Hidden lgUp>
                    <div className={classes.logo}>
                        <img src={logo}
                             alt="logo"
                             height='60px'
                             style={{marginTop: '5px'}}
                             onClick={() => window.location.href = isEnglish ? '/e' : '/'}
                        />
                    </div>

                    <FormControl style={{marginRight: '0.5rem'}}>
                        <Select
                            MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                }
                            }}
                            disableUnderline
                            value={language}>
                            <MenuItem disabled={!isEnglish} value={0}
                                      onClick={() => window.location.href = japaneseURL}>
                                日本語
                            </MenuItem>
                            <MenuItem disabled={isEnglish} value={1} onClick={() => window.location.href = englishURL}>
                                English
                            </MenuItem>
                        </Select>
                    </FormControl>

                    <div style={{float: 'right'}}>
                        {token ?
                            <WhenLoggedIn/> :
                            <WhenLoggedOut/>}
                    </div>

                </Hidden>

                {/*PCサイズ*/}
                <Hidden mdDown>
                    <div className={classes.logo}>
                        <img src={logo}
                             alt="logo"
                             height='80px'
                             style={{marginTop: '5px', marginLeft: '20px'}}
                             onClick={() => window.location.href = isEnglish ? '/e' : '/'}
                        />
                    </div>

                    <div style={{float: 'right'}}>
                        {token ?
                            <WhenLoggedIn/> :
                            <WhenLoggedOut/>}
                    </div>

                    <FormControl className={classes.languageButtonLg} style={{marginRight: '2rem'}}>
                        <Select
                            style={{fontSize: '16px'}}
                            MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                }
                            }}
                            disableUnderline
                            value={language}>
                            <MenuItem disabled={!isEnglish} value={0}
                                      onClick={() => window.location.href = japaneseURL}>
                                日本語
                            </MenuItem>
                            <MenuItem disabled={isEnglish} value={1} onClick={() => window.location.href = englishURL}>
                                English
                            </MenuItem>
                        </Select>
                    </FormControl>

                </Hidden>

            </Toolbar>
        </AppBar>
    )
}

export default withCookies(NavBar)
