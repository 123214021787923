import React from "react";
import LowerTop from "./LowerTop";
import UpperTop from "./UpperTop";
import SnsLinks from "./SnsLinks";


const TopMain = () => {
    return (
        <>
            <UpperTop/>
            <LowerTop/>
            <SnsLinks/>
        </>
    )
}

export default TopMain
