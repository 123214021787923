import React, {useEffect, useState} from "react";
import axios from "axios";
import {COSTCO_CARTITEM_URL, COSTCO_PRODUCT_URL} from "../../Messages/urls";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";


const useStyles = makeStyles((theme) => ({
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        // paddingTop: '56.25%', // 16:9
        paddingTop: '70%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
}))


const AdminProductList = (props) => {
    const {cartItemId} = props
    const classes = useStyles()
    const [cartItem, setCartItem] = useState()
    const [productId, setProductId] = useState()
    const [product, setProduct] = useState()
    const [checked, setChecked] = useState(false)

    const handleChange = (event) => {
        setChecked(event.target.checked)
    }

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (cartItemId) {
            axios.get(`${COSTCO_CARTITEM_URL}?active=&id=${cartItemId}`)
                .then(res => {
                    setCartItem(res.data[0])
                    setProductId(res.data[0].product)
                })
        }
        if (productId) {
            axios.get(`${COSTCO_PRODUCT_URL}?english_etc=&id=${productId}`)
                .then(res => {
                    setProduct(res.data[0])
                })
        }
    }, [productId])

    return (
        <div>
            {product &&
            <Card className={classes.card}>
                <CardMedia
                    className={classes.cardMedia}
                    image={product.image.replace('http', 'https')}
                    title="Image title"
                    onClick={() => handleOpen()}
                />
                <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="body2" component="h2">
                        {product.name}
                    </Typography>
                    <Typography variant='body2'>
                        ¥{Number(product.marked_up_price)} x {cartItem.quantity}個
                    </Typography>
                    <Typography variant='caption'>
                        {product.size_units === 'nan' ? "" :
                            (product.size_units)
                        }
                    </Typography>
                    <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{'aria-label': 'primary checkbox'}}
                    />
                </CardContent>
            </Card>
            }

            {product &&
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogActions>
                    <Button onClick={handleClose} color="default">
                        <CloseIcon/>
                    </Button>
                </DialogActions>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <img src={product.image.replace('http', 'https')} alt='product' width='100%' height='100%'/>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            }
        </div>
    )
}

export default AdminProductList