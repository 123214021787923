import React from "react";
import AdminProductList from "./AdminProductList";
import {Grid} from "@material-ui/core";

const AdminDisplayItems = (props) => {
    const {cartItemArray} = props

    return (
        <Grid container spacing={1}>
            {cartItemArray.map((cartItem) => (
                <Grid item xs={6}>
                    <AdminProductList key={cartItem} cartItemId={cartItem}/>
                </Grid>
            ))}
        </Grid>
    )
}

export default AdminDisplayItems