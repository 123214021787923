import React from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer";
import {Container} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    link: {
        color: '#afeeee',
        textDecoration: 'none',
    },
}))
const KenNobukawaPage = () => {
    const classes = useStyles()
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'

    return (
        <div className='pageWrapper' style={{backgroundColor: 'rgb(29, 30, 31)'}}>
            <NavBar/>
            <Container maxWidth='xs'>
                <div style={{textAlign: 'center'}}>
                    <img src='images/ceo_icon.jpg' alt="icon" width='130px'
                         style={{
                             marginTop: '2rem',
                             marginBottom: '1.8rem',
                             marginRight: 'auto',
                             marginLeft: 'auto',
                             borderRadius: '50%',
                             border: '5px #afeeee solid'
                         }}/>
                </div>

                {/*タイトル*/}
                <div style={{textAlign: 'center'}}>
                    {isEnglish ?
                        <h2 style={{fontFamily: 'American Typewriter', color: 'white', marginTop: '0'}}>
                            Ken Nobukawa
                        </h2> :
                        <h2 style={{fontFamily: 'Arial Black', color: 'white', marginTop: '0'}}>
                            信川 拳
                        </h2>
                    }

                </div>
                {/*職歴*/}
                <div style={{marginLeft: '2rem', marginBottom: '4rem'}}>
                    <p style={{color: 'white', whiteSpace: 'pre-wrap'}}>
                        {isEnglish ?
                            '2017〜2019　honestbee Pte. Ltd.' :
                            '2017〜2019　honestbee株式会社'}
                    </p>

                    <p style={{color: 'white', whiteSpace: 'pre-wrap'}}>
                        {isEnglish ?
                            '2019〜2020　Freelance engineer' :
                            '2019〜2020　フリーランスエンジニア'}
                    </p>

                    <p style={{color: 'white', whiteSpace: 'pre-wrap'}}>
                        {isEnglish ?
                            '2020〜Current　Huskycart LLC' :
                            '2020〜現在　Huskycart合同会社'}
                    </p>

                    {/*<p style={{color: 'white', whiteSpace: 'pre-wrap'}}>*/}
                    {/*    2021&nbsp;&nbsp;*/}
                    {/*    {isEnglish ? 'Launch「' : '「'}*/}
                    {/*    {isEnglish ?*/}
                    {/*        <a href="https://costco-johokan.com/" className={classes.link}*/}
                    {/*           target='_blank' rel="noopener noreferrer">Costco-johokan</a> :*/}
                    {/*        <a href="https://costco-johokan.com/" className={classes.link} target='_blank'*/}
                    {/*           rel="noopener noreferrer">コストコ情報館</a>}*/}
                    {/*    {isEnglish ? '」' : '」を開設'}*/}
                    {/*</p>*/}

                </div>


            </Container>


            <Footer/>
        </div>
    )
}

export default KenNobukawaPage