import React, {useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import axios from "axios";
import {COSTCO_PRODUCT_URL} from "../../Messages/urls";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme) => ({
    reviewImage: {
        maxWidth: 50,
        maxHeight: 50
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        margin: '0 auto'
    }
}))

const DisplayCartItem = (props) => {
    const classes = useStyles()
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '/e' || currentUrl.slice(-2) === '_e'
    const {cartItemArray, cartItems, isOff} = props
    const [products, setProducts] = useState()
    const list = []

    const tenPercentOff = price => {
        // 0.9倍して1の位切り捨て
        return Math.floor(price * 0.9 * 0.1) * 10
    }

    useEffect(() => {
        axios.get(COSTCO_PRODUCT_URL)
            .then(res => {
                setProducts(res.data)
            })
    }, [])

    if (products) {
        for (const index in cartItemArray) {
            const cartId = cartItemArray[index]
            for (const cartItemKey in cartItems) {
                if (cartId === cartItems[cartItemKey].id) {
                    const productId = cartItems[cartItemKey].product
                    const targetProduct = products.filter(product => (product.id === Number(productId)))[0]

                    list.push(
                        <Grid item xs={12} key={productId}>
                            <Card>
                                <CardActionArea>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <CardMedia
                                                image={targetProduct.image.replace('http', 'https')}
                                                title={targetProduct.name}
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    display: 'inline-block',
                                                    marginLeft: '1rem',
                                                    marginTop: '1rem',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CardContent style={{display: 'inline-block', paddingBottom: '0'}}>
                                                <Typography gutterBottom variant="subtitle1" component="h2">
                                                    {isEnglish ? targetProduct.english_name : targetProduct.name}
                                                </Typography>
                                                {isOff ?
                                                    //    10%off
                                                    <p style={{marginRight: '0', marginLeft: '0'}}>
                                                        ¥{tenPercentOff(Math.ceil(targetProduct.marked_up_price))} (10%off)
                                                    </p>
                                                    :
                                                    //   通常
                                                    <p style={{marginRight: '0', marginLeft: '0'}}>
                                                        ¥{Math.ceil(targetProduct.marked_up_price)}
                                                    </p>
                                                }
                                            </CardContent>
                                        </Grid>
                                    </Grid>
                                </CardActionArea>

                                <CardActions style={{padding: '0', display: 'flex', justifyContent: 'center'}}>

                                    <p style={{marginRight: '0', marginLeft: '0'}}>
                                        <span>{isEnglish ? 'quantity: ' : '個数: '}</span>{cartItems[cartItemKey].quantity}
                                    </p>

                                    <Typography variant="h6" component="p" style={{marginLeft: '1rem'}}>
                                        ¥{isOff ?
                                        // 10%off
                                        tenPercentOff(targetProduct.marked_up_price) * cartItems[cartItemKey].quantity
                                        :
                                        // 通常
                                        targetProduct.marked_up_price * cartItems[cartItemKey].quantity
                                    }
                                        {/*¥{targetProduct.marked_up_price * cartItems[cartItemKey].quantity}*/}
                                    </Typography>

                                </CardActions>
                            </Card>
                        </Grid>
                    )
                }
            }
        }
    }


    return (
        <ExpansionPanel style={{backgroundColor: '#f6f6f6'}}>
            <ExpansionPanelSummary
                aria-controls="panel1a-content"
                expandIcon={<ExpandMoreIcon/>}
                id="panel1a-header"
            >
                <Typography className={classes.heading}>{isEnglish ? 'Product detail' : '商品詳細'}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Typography>
                    <Grid container spacing={1}>
                        {list}
                    </Grid>
                </Typography>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
}

export default DisplayCartItem