import React, {useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import CostcoAddressForm from "./CostcoAddressForm";
import CostcoReservationForm from "./CostcoReservationForm";
import CostcoChoosePayment from "./CostcoChoosePayment";
import CashOnConfirm from "../CashOnConfirm";
import CostcoThanks from "./CostcoThanks";
import CreditOnConfirm from "../CreditOnConfirm";


const useStyles = makeStyles((theme) => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));


const CostcoCheckout = (props) => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'
    const classes = useStyles()
    const {activeStep, setActiveStep} = props
    const [isFill, setIsFill] = useState(false)
    const [isChange, setIsChange] = useState(false)
    const [isSelected, setIsSelected] = useState(false)
    const japanese_steps = [
        "配達\n住所",
        "配達\n日時",
        "決済\n方法",
    ]

    const english_steps = [
        "Delivery\naddress",
        "Delivery\ntime",
        "Payment\nmethod",
    ]

    const steps = isEnglish ? english_steps : japanese_steps

    const scrollTopValue = 0

    const updateProfile = () => {
        try {
            document.getElementById('update').click()
        } catch (e) {

        }
    }

    const handleNext = () => {
        setActiveStep(activeStep + 1);
        document.body.scrollTop = document.documentElement.scrollTop = scrollTopValue;
    }

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    }

    const getStepContent = (step) => {
        switch (step) {
            case 2:
                return <CostcoAddressForm setIsFill={setIsFill} isChange={isChange} setIsChange={setIsChange}/>
            case 3:
                return <CostcoReservationForm setIsSelected={setIsSelected}/>;
            case 4:
                return <CostcoChoosePayment activeStep={activeStep} setActiveStep={setActiveStep}/>;
            default:
                throw new Error('Unknown step');
        }
    }

    const getFullContent = (step) => {
        switch (step) {
            case 5:
                return <CashOnConfirm activeStep={activeStep} setActiveStep={setActiveStep}/>
            case 6:
                return <CostcoThanks/>
            case 7:
                return <CreditOnConfirm activeStep={activeStep} setActiveStep={setActiveStep}/>
            default:
                throw new Error('Unknown step');
        }
    }

    return (
        <>
            {activeStep >= 5 && getFullContent(activeStep)}

            {/*ステッパーの中身*/}
            {activeStep <= 4 &&
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Stepper activeStep={activeStep - 2} className={classes.stepper} style={{whiteSpace: 'pre-wrap'}}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {getStepContent(activeStep)}
                    {activeStep === 2 &&
                    <div style={{float: 'left'}}>
                        <Button className={classes.button}
                                disabled={!isChange} variant='contained' color='primary'
                                onClick={() => updateProfile()}
                        >
                            {isEnglish ? "update" : '更新'}
                        </Button>
                    </div>
                    }

                    <div className={classes.buttons}>
                        {activeStep !== 5 && (
                            <Button onClick={handleBack} className={classes.button}>
                                {isEnglish ? 'Back' : '戻る'}
                            </Button>
                        )}
                        {activeStep === 4 ?
                            <></>
                            :
                            (activeStep === 2 ?
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                        disabled={!isFill || isChange}
                                    >
                                        {isEnglish ? 'Next' : '進む'}
                                    </Button> :
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                        disabled={!isSelected}
                                    >
                                        {isEnglish ? 'Next' : '進む'}
                                    </Button>
                            )
                        }
                    </div>
                </Paper>
            </main>}
            <CssBaseline/>
        </>
    )
}

export default CostcoCheckout