import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import FAQItems from "./FAQItems";
import Hidden from "@material-ui/core/Hidden";


const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
    },
    qaUl: {
        listStyle: "none",
        paddingLeft: 0,
    },
}));


const FAQ = () => {
    const classes = useStyles()
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'

    const questionListJp = [
        '商品が全て届いてません。',
        'クレジット払いだと在庫切れ商品の料金は請求されてしまうの？',
        '再配達手数料について。',
        '領収書が欲しいです。',
        'ご利用可能エリア外ですが注文したいです。',
        '商品の値札と価格が違います。',
        '登録したメールアドレスを変更したいです。',
        '欲しい商品が取り扱いされていません。',
        'おすすめのコストコ商品が知りたいです。',
        '退会希望です。',
    ]
    const answerListJp = [
        '届いていない商品は在庫切れだった可能性がございます。買い物代行終了時にお送りしたメール、もしくはアカウントの購入履歴（お買い物内容）をご確認ください。※お客様の設定によっては迷惑メールフォルダに振り分けされている場合がございます。',
        'いいえ、されません。注文時点でのクレジット払いは仮決済です。配達完了後に在庫切れ商品の料金を差し引いた、正規の金額が請求されますのでご安心ください。',
        '基本的に再配達手数料はいただいておりません。ご不在にならないように配達時間を1時間単位に設定しておりますのでご協力お願いします。あまりに再配達が多い場合はアカウント停止になる恐れがありますのでご注意ください。',
        'ログイン後、右上のメニューボタンのマイアカウント、購入履歴より領収書をダウンロードできます。',
        '大変申し訳ございません。ご利用可能エリア外の配達は対応していません。また、ご利用可能エリア内での駅や公園などの待ち合わせ配送もできかねます。',
        '本Webサイトに表示されている価格はHuskycart価格の為、実際の店頭価格とは異なります。差額分の利益により本サービスは成り立っているため、ご理解いただければ幸いです。',
        '下記お問い合わせより、新しいメールアドレスで内容にアカウント名を記載して送信ください。メールアドレスの変更が完了しましたら”変更完了メール”をお送りします。※変更が完了後は古いメールアドレスではログインできなくなります。',
        '下記より、ご希望の商品名を記載の上、お問い合わせください。店頭にて在庫確認でき、取り扱い可能な場合に本Webサイトの商品リストに追加させていただきます。',
        'こちらでご紹介しています。',
        'ご登録いただいたアカウント名(フルネーム)、もしくはメールアドレスと退会希望の旨を下記よりお問い合わせください。確認後、アカウント、及びご登録いただいた情報を全て削除いたします。',
    ]

    const questionListEng = [
        'I haven\'t received all of my items.',
        'If I pay by credit card, will I be charged for out-of-stock items?',
        'About re-delivery fee',
        'I would like a receipt.',
        'I am out of the available area, but would like to place an order',
        'The price tag and price of the product are different.',
        'I want to change my registered email.',
        'The item you want is not available.',
        'I wish to cancel my membership.',
    ]

    const answerListEng = [
        'Items you did not receive may have been out of stock. Please check the e-mail we sent you at the end of the shopping process or your account purchase history (purchase details). *It may have been sorted into your spam folder depending on your settings.',
        'No, you will not. Credit card payment at the time of order is a provisional payment. After delivery is complete, you will be billed for the full amount, minus any charges for out-of-stock items.',
        'Basically, we do not charge re-delivery fees. We have set the delivery time in one-hour increments to avoid being missed, so we ask for your cooperation. Please note that your account may be suspended if there are too many re-deliveries.',
        'After logging in, you can download your receipt from My Account, Purchase History in the upper right menu button.',
        'We are very sorry. We do not support deliveries outside of our service area. We are also unable to meet deliveries at train stations or parks within our service area.',
        'The prices shown on this website are Huskycart prices and are different from actual store prices. We hope you will understand that this service is possible due to the profit from the difference.',
        'Please send us your new email address with your account name. We will send you a "Change Completion Email" when the email address change is complete. After the change is complete, you will no longer be able to log in using your old email address.',
        'Please contact us with the name of the product you wish to purchase from the following link. We will add the product to the product list on this website when we can confirm that it is in stock at the store and can be handled.',
        'Please contact us with your full name or e-mail address and your request to cancel your membership. Upon confirmation, we will delete your account and all registered information.'
    ]

    const questionList = isEnglish ? questionListEng : questionListJp
    const answerList = isEnglish ? answerListEng : answerListJp


    const faqItems = questionList.map((question, index) =>
        <li key={index}>
            <FAQItems isEnglish={isEnglish} index={index} question={question} answer={answerList[index]}/>
        </li>
    );

    return (
        <div>
            <Hidden smUp>
                <h3 className={classes.title}>
                    {isEnglish ? 'Q&A' : 'よくあるご質問（Q&A）'}
                </h3>
            </Hidden>

            {/*iPadサイズ*/}
            <Hidden xsDown lgUp>
                <h2 className={classes.title}>
                    {isEnglish ? 'Q&A' : 'よくあるご質問（Q&A）'}
                </h2>
            </Hidden>

            {/*PC*/}
            <Hidden mdDown>
                <h1 className={classes.title}>
                    {isEnglish ? 'Q&A' : 'よくあるご質問（Q&A）'}
                </h1>
            </Hidden>
            <p>{isEnglish ? 'Before contacting us, please refer to this page.' : 'お問い合わせ前に、こちらをご参考ください。'}</p>
            <ul className={classes.qaUl}>
                {faqItems}
            </ul>
            <p>{isEnglish ? 'If you have any other questions, please contact us from below.' : 'その他のご不明点は、下記よりお問い合わせください。'}</p>
        </div>
    )
}

export default FAQ