import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import {useWindowDimensions} from "../utils/useWindowDimensions";
import DeliveryTimeDialog from "./DeliveryTimeDialog";
import {Grid, Hidden} from "@material-ui/core";
import {removeQuery} from "../utils/func";
import TopDialog from "../Top/TopDialog";


const useStyles = makeStyles((theme) => ({
    buttonMargin: {
        marginLeft: theme.spacing(1)
    },
    listItem: {
        border: 'solid 1px rgba(0, 0, 0, 0.54)',
        height: '50px',
        textAlign: 'center',
        display: 'inline-block',
        lineHeight: '32px',
        fontSize: '14px',
    },
    mdMenuItem: {
        fontSize: '12px',
        cursor: 'pointer',
        border: '2px solid #afeeee',
        padding: '8px',
        borderRadius: '5%',
        '&:hover': {
            border: '2px solid',
        },
    },
    lgMenuItem: {
        marginRight: '2rem',
        cursor: 'pointer',
        border: '2px solid #afeeee',
        padding: '8px',
        borderRadius: '5%',
        '&:hover': {
            border: '2px solid',
        },
    }
}))

const WhenLoggedOut = () => {
    // const currentUrl = window.location.href
    const currentUrl = removeQuery(window.location.href)
    const isEnglish = currentUrl.slice(-2) === '/e' || currentUrl.slice(-2) === '_e'

    const promotionURL = isEnglish ? 'https://costco-johokan.com/husky-cart-promotion-e/' : 'https://costco-johokan.com/husky-cart-promotion/'

    const classes = useStyles()

    const {width} = useWindowDimensions();
    const isXs = width < 600
    const drawerMargin = isXs ? '70px' : '64px'

    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }

    const [openDialog, setOpenDialog] = useState(false)
    const handleOpenDialog = () => {
        setOpenDialog(true)
    }
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({...state, [anchor]: open});
    };
    const [state, setState] = React.useState({
        top: false,
    });

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            style={{marginTop: drawerMargin}}
        >
            <List style={{padding: '0'}}>
                <Grid container>
                    <Grid item xs={6}>
                        <ListItem className={classes.listItem} button
                                  style={{borderTop: 'none', borderBottom: 'none', borderRight: 'none'}}
                                  onClick={() => isEnglish ? window.location.href = '/login_e' : window.location.href = '/login'}>
                            {isEnglish ? 'Login' : 'ログイン'}
                        </ListItem>
                    </Grid>
                    <Grid item xs={6}>
                        <ListItem className={classes.listItem} button style={{borderBottom: 'none', borderTop: 'none'}}
                                  onClick={() => window.location.href = isEnglish ? '/register_e' : '/register'}>
                            {isEnglish ? 'Register' : '新規会員登録'}
                        </ListItem>
                    </Grid>
                    <Grid item xs={6}>
                        <a href={promotionURL} target='_blank' rel="noopener noreferrer"
                           style={{textDecoration: "none", color: 'black'}}>
                            <ListItem className={classes.listItem} button
                                      style={{borderBottom: 'none', borderRight: 'none'}}>

                                {isEnglish ? 'Promotion' : 'プロモーション'}
                            </ListItem>
                        </a>
                    </Grid>
                    <Grid item xs={6}>
                        <ListItem className={classes.listItem} button onClick={() => handleOpen()}
                                  style={{borderBottom: 'none'}}>
                            {isEnglish ? 'Delivery frame' : 'お届け枠状況'}
                        </ListItem>
                    </Grid>
                </Grid>
            </List>
        </div>
    );

    return (
        <>
            {/*スマホサイズ*/}
            <Hidden lgUp>
                <IconButton onClick={state.top ? toggleDrawer('top', false) : toggleDrawer('top', true)}
                            style={{
                                backgroundColor: 'white',
                                border: 'solid 1px',
                                padding: '5px',
                                marginRight: '0',
                                zIndex: '101'
                            }}>
                    <MenuIcon style={{fontSize: '30px'}}/>
                </IconButton>
            </Hidden>

            {/*PCサイズ*/}
            <Hidden mdDown>
                <ul style={{listStyle: 'none', display: 'flex', fontSize: '16px'}}>
                    <li className={classes.lgMenuItem}
                        onClick={() => isEnglish ? window.location.href = '/login_e' : window.location.href = '/login'}>{isEnglish ? 'Login' : 'ログイン'}</li>
                    <li className={classes.lgMenuItem}
                        onClick={() => window.location.href = isEnglish ? '/register_e' : '/register'}>{isEnglish ? 'Register' : '新規会員登録'}</li>
                    {/*<li className={classes.lgMenuItem}*/}
                    {/*    onClick={() => window.location.href = isEnglish ? 'https://husky-cart.online/#promotion' : 'https://husky-cart.online/'}>{isEnglish ? 'Promotion' : 'プロモーション'}</li>*/}
                    <li className={classes.lgMenuItem}
                        onClick={() => handleOpen()}>{isEnglish ? 'Delivery frame' : 'お届け枠状況'}</li>
                    <a href={promotionURL} target='_blank' rel="noopener noreferrer"
                       style={{textDecoration: "none", color: 'black'}}>
                        <li className={classes.lgMenuItem}>
                            {isEnglish ? 'Promotion' : 'プロモーション'}
                        </li>
                    </a>
                </ul>
            </Hidden>
            <Drawer
                anchor={'top'}
                open={state['top']}
                onClose={toggleDrawer('top', false)}
                style={{zIndex: '5'}}
            >
                {list('top')}
            </Drawer>
            <DeliveryTimeDialog open={open} setOpen={setOpen}/>
            <TopDialog open={openDialog} setOpen={setOpenDialog}/>
        </>
    )
}

export default WhenLoggedOut