import React, {useContext, useEffect, useState} from "react";
import {CostcoContext} from "./CostcoHome";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CostcoReviewItems from "./CostcoCheckout/CostcoReviewItems";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import axios from 'axios'
import {
    COSTCO_CARTITEM_URL,
    COSTCO_COUPON_URL,
    COSTCO_FIRST_ORDER_URL,
    COSTCO_INVITATION_URL,
    COSTCO_ORDER_URL,
    COSTCO_PERSONAL_COUPON_URL,
    USER_URL
} from "../../Messages/urls";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";


const useStyles = makeStyles((theme) => ({
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: 700,
    },
    title: {
        marginTop: theme.spacing(1),
    },
    heading: {
        margin: '0 auto',
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    topLine: {
        borderTop: 'solid 1px #e0e0e0',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        paddingBottom: '0.5rem'
    },
    content: {
        paddingLeft: '2rem'
    }
}));

const CashOnConfirm = (props) => {
    const classes = useStyles();
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'
    const {
        cart,
        setCart,
        totalAmount,
        products,
        myProfile,
        memo,
        cancel,
        deliveryDay,
        setCancel,
        setMemo,
        setMyProfile,
        setDeliveryDay,
        setOrderNumber,
        setEmail,
        email,
        tip,
        couponPrice,
        couponCode,
        setCouponPrice,
        setCouponCode,
        isNoFee,
        isFirstTime,
        setIsLoading,
    } = useContext(CostcoContext)
    const {activeStep, setActiveStep} = props
    const [dialogOpen, setDialogOpen] = useState(false)

    // 代引きの時だけ1000円引きするキャンペーン
    // const cashOnDiscount = 300
    // const cashOnDiscount = Math.ceil(totalAmount * 0.05 / 10) * 10
    const cashOnDiscount = 0

    // const shoppingFee = isNoFee || isFirstTime ? 0 : 250
    // const shoppingFee = isNoFee || isFirstTime ? 0 : 800
    const thresholdItemPrice = 20000
    const isOverThreshold = totalAmount >= thresholdItemPrice
    const shoppingFee = isNoFee || isFirstTime || isOverThreshold ? 0 : 800
    // const deliveryFee = isNoFee || isFirstTime ? 0 : 250
    const deliveryFee = 0

    const fullName = isEnglish ? `${myProfile.first_name} ${myProfile.last_name}` : `${myProfile.last_name} ${myProfile.first_name}`

    const placeOrder = () => {
        // 注文確定処理
        order()
        setCart([])
        setCancel()
        setMemo()
        setMyProfile()
        setDeliveryDay()
        setCouponCode('')
        setCouponPrice(0)
        setActiveStep(activeStep + 1)
        document.body.scrollTop = document.documentElement.scrollTop = 64
    }

    const order = async () => {
        setIsLoading(true)
        // カート内の商品をCartItemに入れる
        const CartItemIdArray = []
        let dict = {}
        for (const product of cart) {
            const id = product.id
            if (dict[id]) {
                dict[id] += 1
            } else {
                dict[id] = 1
            }
        }
        for (let key in dict) {
            const data = {
                "product": key,
                "quantity": dict[key],
            }
            const cartItemResponse = await axios.post(COSTCO_CARTITEM_URL, data)
            const cartItemId = cartItemResponse.data.id
            CartItemIdArray.push(cartItemId)
        }

        createOrder(CartItemIdArray)

        if (couponPrice !== 0) {
            updateCoupon()
        }

        if (isInvited) {
            updateInvitation()
        }
        setIsLoading(false)
    }

    const calcFebPromotionDiscount = () => {
        return 0
        // const amount = totalAmount + shoppingFee + deliveryFee + (Math.floor(totalAmount * tip * 0.001) * 10) - couponPrice
        // if (amount >= 20000) {
        //     return 1000
        // } else if (amount >= 10000) {
        //     return 500
        // } else {
        //     return 0
        // }
    }

    const createOrder = async (cartItemArray) => {
        const address = `${myProfile.prefecture} ${myProfile.city_name} ${myProfile.place_number} ${myProfile.building_floor_room_name}`
        const delivery_date = `${id2date(deliveryDay)}  ${id2Time(deliveryDay)}`
        const payment = isEnglish ? 'Cash on delivery' : '代引き'
        const fullPrice = (totalAmount + shoppingFee + deliveryFee + (Math.floor(totalAmount * 0.001 * tip) * 10) - couponPrice - cashOnDiscount) < 0 ? 0 : totalAmount + shoppingFee + deliveryFee + (Math.floor(totalAmount * 0.001 * tip) * 10) - couponPrice - cashOnDiscount - calcFebPromotionDiscount()
        const order = {
            "cart_item": cartItemArray,
            "products_price": totalAmount,
            "tip": tip,
            "coupon_price": couponPrice + cashOnDiscount + calcFebPromotionDiscount(),
            "total": fullPrice,
            "user": myProfile.user_profile,
            "name": fullName,
            "address": address,
            "phone_number": myProfile.phone_number,
            "payment_method": payment,
            "delivery_date": delivery_date,
            "shopping_fee": shoppingFee,
            "delivery_fee": deliveryFee,
            "cancel": convertCancel(cancel),
            "memo": memo,
        }
        const orderResponse = await axios.post(COSTCO_ORDER_URL, order, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        setOrderNumber(orderResponse.data.id)

        // if (order.memo === undefined) {
        //     order.memo = '【10%off】'
        // }else {
        //     order.memo = memo + '【10%off】'
        // }

        await axios.post(COSTCO_FIRST_ORDER_URL, order, {
            headers: {
                'Content-Type': 'application/json',
            }
        })

        // AprilPromotion(fullPrice)
        // JunePromotion(fullPrice)
    }

    const updateCoupon = async () => {
        // 個人クーポン
        const personalCoupon = await axios.get(`${COSTCO_PERSONAL_COUPON_URL}?user=${myProfile.user_profile}&code=${couponCode}&is_used=false`)
        const personalCouponData = personalCoupon.data[0]
        if (personalCouponData) {
            const updatedData = {
                "code": personalCouponData.code,
                "is_used": true,
                "user": personalCouponData.user,
                "discounted_price": personalCouponData.discounted_price
            }
            await axios.put(`${COSTCO_PERSONAL_COUPON_URL}${personalCouponData.id}/`, updatedData, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            return
        }
        const couponResponse = await axios.get(`${COSTCO_COUPON_URL}?code=${couponCode}&active=true`)
        const couponData = couponResponse.data[0]
        const usedUserArray = couponData.used_user
        usedUserArray.push(myProfile.user_profile)
        const updatedData = {
            'code': couponCode,
            'discounted_price': couponData.discounted_price,
            'active': true,
            'used_user': usedUserArray,
        }
        await axios.put(`${COSTCO_COUPON_URL}${couponData.id}/`, updatedData, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }

    const getRandomStr = () => {
        const LENGTH = 6 //生成したい文字列の長さ
        const SOURCE = "abcdefghijklmnopqrstuvwxyz0123456789" //元になる文字
        let result = ''

        for (let i = 0; i < LENGTH; i++) {
            result += SOURCE[Math.floor(Math.random() * SOURCE.length)];
        }
        return result //p9zh1z
    }

    const updateInvitation = async () => {
        const invitationResponse = await axios.get(`${COSTCO_INVITATION_URL}?invitee_list=${myProfile.user_profile}`)
        const invitationData = invitationResponse.data[0]
        const inviteeArray = invitationData.invitee_list
        const updatedInviteeArray = inviteeArray.filter(id => id !== myProfile.user_profile)
        const usedUserArray = invitationData.used_user_list
        usedUserArray.push(myProfile.user_profile)
        const updatedData = {
            "invite": invitationData.invite,
            "invitee_list": updatedInviteeArray,
            "used_user_list": usedUserArray,
            "code": invitationData.code
        }
        await axios.put(`${COSTCO_INVITATION_URL}${invitationData.id}/`, updatedData, {
            headers: {
                'Content-Type': 'application/json',
            }
        })

        // 招待者のクーポン作成
        const data = {
            "code": getRandomStr(),
            "is_used": false,
            "user": invitationData.invite,
            // "discounted_price": 2000
            "discounted_price": 1000

        }
        await axios.post(COSTCO_PERSONAL_COUPON_URL, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }

    const tipValue2Str = (value) => {
        switch (value) {
            case 0:
                return isEnglish ? 'none' : 'なし'
            case 5:
                return '5%'
            case 10:
                return '10%'
            case 15:
                return '15%'
            case 20:
                return '20%'
            default:
                return 'value error'
        }
    }

    const convertCancel = (value) => {
        // cancelは数値で入ってくるので文字列に変換
        switch (value) {
            case 1:
                return isEnglish ? 'Cancel' : 'キャンセル'
            case 2:
                return isEnglish ? 'Substitute(no call)' : '代替品(お任せ)'
            case 3:
                return isEnglish ? 'Substitute(call)' : '代替品(要連絡)'
            case 4:
                return 'Substitute(text)'
            default:
                return isEnglish ? 'Cancel' : 'キャンセル'
        }
    }

    const date2String = (date) => {
        const month = date.getMonth() + 1
        const day = date.getDate()
        return month + "/" + day
    }

    const id2date = (id) => {
        const day = new Date()
        switch (Number(id.slice(0, 1))) {
            case 1:
                return date2String(day)
            case 2:
                day.setDate(day.getDate() + 1)
                return date2String(day)
            case 3:
                day.setDate(day.getDate() + 2)
                return date2String(day)
            case 4:
                day.setDate(day.getDate() + 3)
                return date2String(day)
            case 5:
                day.setDate(day.getDate() + 4)
                return date2String(day)
            case 6:
                day.setDate(day.getDate() + 5)
                return date2String(day)
            case 7:
                day.setDate(day.getDate() + 6)
                return date2String(day)
            default:
                return ""
        }
    }

    const id2Time = (id) => {
        switch (Number(id.slice(2))) {
            case 0:
                return '12:00 ~ 13:00'
            case 1:
                return '13:00 ~ 14:00'
            case 2:
                return '14:00 ~ 15:00'
            case 3:
                return '15:00 ~ 16:00'
            case 4:
                return '16:00 ~ 17:00'
            case 5:
                return '17:00 ~ 18:00'
            case 6:
                return '18:00 ~ 19:00'
            case 7:
                return '19:00 ~ 20:00'
            case 8:
                return '20:00 ~ 21:00'
            case 9:
                return '21:00 ~ 22:00'
            default:
                return ""
        }
    }

    const [isInvited, setIsInvited] = useState(false)
    const judgeInvited = async () => {
        const invitationResponse = await axios.get(`${COSTCO_INVITATION_URL}?invitee_list=${myProfile.user_profile}`)
        const invitationData = invitationResponse.data[0]
        try {
            if (invitationData.id) {
                setIsInvited(true)
            }
        } catch (e) {
            setIsInvited(false)
        }
    }


    useEffect(() => {
        axios.get(`${USER_URL}${myProfile.user_profile}/`)
            .then(res => {
                setEmail(res.data.email)
            })
        judgeInvited()
    }, [email, myProfile])


    return (
        <div>
            <Container maxWidth='md' style={{marginTop: '1rem', padding: '0', backgroundColor: '#f6f6f6'}}>
                <ExpansionPanel style={{backgroundColor: '#f6f6f6'}}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>{isEnglish ? 'Product detail' : '商品詳細'}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <CostcoReviewItems cart={cart} products={products}/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <Grid container>
                    <Grid item container direction="column" xs={12} sm={6} className={classes.topLine}
                          style={{border: 'none'}}>
                        <Typography variant="h6" gutterBottom className={classes.title}>
                            {isEnglish ? "Price" : "金額"}
                        </Typography>
                        <Grid container className={classes.content}>
                            <Grid item xs={6}>
                                <Typography gutterBottom>{isEnglish ? 'Products' : '商品代金'}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography gutterBottom>¥{totalAmount}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography gutterBottom>{isEnglish ? 'Fee' : '手数料'}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {isNoFee && <Typography gutterBottom>
                                    {isEnglish ? `¥${shoppingFee} (promotion)` : `¥${shoppingFee} (プロモーション)`}
                                </Typography>}

                                {isFirstTime && !isNoFee && <Typography gutterBottom>
                                    {isEnglish ? `¥${shoppingFee} (First order only)` : `¥${shoppingFee} (初回特典)`}
                                </Typography>}

                                {isOverThreshold && <Typography gutterBottom>
                                    {isEnglish ? `¥${shoppingFee} (promotion)` : `¥${shoppingFee} (プロモーション)`}
                                </Typography>}

                                {!isNoFee && !isFirstTime && !isOverThreshold && <Typography gutterBottom>
                                    <Typography gutterBottom>{`¥${shoppingFee}`}</Typography>
                                </Typography>}
                            </Grid>
                            {/*<Grid item xs={6}>*/}
                            {/*    <Typography gutterBottom>{isEnglish ? 'Delivery fee' : '配送料'}</Typography>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={6}>*/}
                            {/*    {isNoFee && <Typography gutterBottom>*/}
                            {/*        {isEnglish ? `¥${deliveryFee} (promotion)` : `¥${deliveryFee} (プロモーション)`}*/}
                            {/*    </Typography>}*/}

                            {/*    {isFirstTime && !isNoFee && <Typography gutterBottom>*/}
                            {/*        {isEnglish ? `¥${deliveryFee} (First order only)` : `¥${deliveryFee} (初回限定の特典)`}*/}
                            {/*    </Typography>}*/}

                            {/*    {!isNoFee && !isFirstTime && <Typography gutterBottom>*/}
                            {/*        <Typography gutterBottom>{`¥${deliveryFee}`}</Typography>*/}
                            {/*    </Typography>}*/}
                            {/*</Grid>*/}
                            <Grid item xs={6}>
                                <Typography gutterBottom>{isEnglish ? 'Tip' : 'チップ'}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    gutterBottom>¥{Math.floor(tip * totalAmount * 0.001) * 10} ({tipValue2Str(tip)})</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography gutterBottom>{isEnglish ? 'Discount' : '割引'}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    gutterBottom>-¥{couponPrice + cashOnDiscount + calcFebPromotionDiscount()}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography gutterBottom>{isEnglish ? 'Total' : '合計'}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {(totalAmount + shoppingFee + deliveryFee + (Math.floor(totalAmount * 0.001 * tip) * 10) - couponPrice - cashOnDiscount) < 0 ?
                                    <Typography
                                        gutterBottom>¥0</Typography> :
                                    <Typography
                                        gutterBottom>¥{totalAmount + shoppingFee + deliveryFee + (Math.floor(totalAmount * 0.001 * tip) * 10) - couponPrice - cashOnDiscount - calcFebPromotionDiscount()}</Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" xs={12} sm={6} className={classes.topLine}>
                        <Typography variant="h6" gutterBottom className={classes.title}>
                            {isEnglish ? "Delivery address" : "配達住所"}
                        </Typography>
                        <Grid container className={classes.content}>
                            <Grid item xs={6}>
                                <Typography gutterBottom>{isEnglish ? 'Name' : '氏名'}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography gutterBottom>{fullName}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography gutterBottom>{isEnglish ? 'Address' : '住所'}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography gutterBottom>
                                    {myProfile.prefecture} {myProfile.city_name} {myProfile.place_number} {myProfile.building_floor_room_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography gutterBottom>{isEnglish ? 'Phone number' : '電話番号'}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography gutterBottom>{myProfile.phone_number}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" xs={12} sm={6} className={classes.topLine}>
                        <Typography variant="h6" gutterBottom className={classes.title}>
                            {isEnglish ? "Delivery time" : "配達日時"}
                        </Typography>
                        <Grid container className={classes.content}>
                            <Grid item xs={6}>
                                <Typography gutterBottom>{isEnglish ? 'Date' : '日付'}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography gutterBottom>{id2date(deliveryDay)}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography gutterBottom>{isEnglish ? 'Delivery time' : '時間'}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography gutterBottom>{id2Time(deliveryDay)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item container direction="column" xs={12} sm={6} className={classes.topLine}
                          style={{borderBottom: 'solid 1px #e0e0e0'}}>

                        <Typography variant="h6" gutterBottom className={classes.title}>
                            {isEnglish ? "ETC" : "その他"}
                        </Typography>
                        <Grid container className={classes.content}>
                            <Grid item xs={6}>
                                <Typography gutterBottom>{isEnglish ? 'Payment method' : '決済方法'}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography gutterBottom>{isEnglish ? 'Cash on delivery' : '代引き'}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography
                                    gutterBottom>{isEnglish ? 'If out of stock item' : '在庫切れ対応'}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography gutterBottom>{convertCancel(cancel)}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography gutterBottom>{isEnglish ? 'Memo' : 'メモ'}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography gutterBottom>{memo}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <div style={{marginTop: '2rem', marginBottom: '2rem'}}>
                <Button onClick={() => setDialogOpen(true)} variant='contained' color='primary' fullWidth>
                    {isEnglish ? 'Place order' : '注文を確定する'}
                </Button>
            </div>
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
            >
                <DialogTitle
                    style={{whiteSpace: 'pre-wrap'}}>{isEnglish ? 'Confirming the order.\nAre you sure?' : '注文を確定します。\nよろしいでしょうか？'}</DialogTitle>

                <DialogActions style={{justifyContent: 'center'}}>
                    <Button
                        onClick={() => {
                            placeOrder();
                            setDialogOpen(false)
                        }}
                        style={{margin: '1rem'}}
                        color="default"
                    >
                        {isEnglish ? 'YES' : 'はい'}
                    </Button>

                    <Button
                        onClick={() => setDialogOpen(false)}
                        style={{margin: '1rem'}}
                        color="default"
                    >
                        {isEnglish ? 'NO' : 'いいえ'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    )
}

export default CashOnConfirm