import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import {animateScroll as scroll} from 'react-scroll';
import IconButton from '@material-ui/core/IconButton';
import PublishIcon from '@material-ui/icons/Publish';
import '../styles/LowerTop.css'
import TopDialog from "./TopDialog";
import {removeQuery} from "../utils/func";
import PopUpDialog from "./PopUpDialog";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // background: '#afeeee',
        background: 'white',
        paddingBottom: '2rem',
        [theme.breakpoints.up('lg')]: {
            paddingBottom: '0',
        },
    },
    accordion: {
        border: '1px solid #48d1cc',
        margin: '0 1rem 0 1rem',
        textAlign: 'center',
        borderRadius: '10px',
        background: 'white',
        [theme.breakpoints.between('sm', 'xl')]: {
            marginTop: '1rem',
        },
    },
    accordionContainer: {
        border: '2px solid #48d1cc',
        color: '#48d1cc',
        fontWeight: 'bold',
        borderRadius: '8px',
        textAlign: 'center',
        height: '2.5rem',
        lineHeight: '2.5rem',
        marginRight: '4px',
        marginLeft: '4px',
        // [theme.breakpoints.up('lg')]: {
        //     height: '2.5rem',
        //     lineHeight: '2.5rem',
        // }
    },
    // 文字を中心に表示するためのpadding
    accordionPadding: {
        paddingTop: '13px',
        paddingLeft: '80px',
        [theme.breakpoints.only('md')]: {
            paddingLeft: '50px',
        },
        [theme.breakpoints.only('sm')]: {
            paddingTop: '16px',
            paddingLeft: '40px',
            fontSize: '13px'
        },
    },
    // アイコンを白にするためのスタイル
    colorWhite: {
        color: 'white',
    },
    toTopButton: {
        position: 'fixed',
        bottom: '215px',
        right: '27px',
        zIndex: '2',
        opacity: '0',
        fontSize: '50px',
        color: 'white',
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        '&:hover': {
            opacity: '0.8',
        },
        // スクロールした時に与えられるクラス
        '&.fadein': {
            opacity: '1'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            bottom: '250px',
            right: '100px',
        },
    },
    cardImageWrapper: {
        marginLeft: '44px',
        [theme.breakpoints.only('sm')]: {
            marginLeft: '20px',
        },
    },
    cardImage: {
        display: 'inline-block',
        width: '25px',
        height: '20px',
        marginLeft: '2px',
        marginRight: '2px',
        [theme.breakpoints.only('sm')]: {
            width: '24px',
            height: '20px',
        },
        [theme.breakpoints.only('md')]: {
            width: '24px',
            height: '20px',
        },
    },
    ulPayment: {
        marginLeft: '30px',
        marginBottom: '0',
        [theme.breakpoints.only('sm')]: {
            marginLeft: '0',
            marginBottom: '0',
            marginTop: '0',
        },
    },
    liCashOnDelivery: {
        marginBottom: '20px',
        [theme.breakpoints.only('sm')]: {
            marginBottom: '0',
        },
    },
    pBrands: {
        marginLeft: '37px',
        marginTop: '5px',
        marginBottom: '5px',
        [theme.breakpoints.only('sm')]: {
            marginLeft: '10px',
            marginTop: '4px',
            marginBottom: '4px',
        },
    },
    upperFee: {
        marginBottom: '8px',
        [theme.breakpoints.only('sm')]: {
            marginTop: '4px',
            marginBottom: '4px',
        },
    },
    lowerFee: {
        [theme.breakpoints.only('sm')]: {
            marginBottom: '4px',
        },
    },
    betweenLi: {
        [theme.breakpoints.only('sm')]: {
            marginBottom: '4px',
        },
    },
    hideBorder: {
        '&.MuiExpansionPanel-root:before': {
            display: 'none',
        },
    },
    copyRightColor: {
        color: 'rgba(0, 0, 0, 0.54)',
        // margin: '0 4px',
        margin: '0'
    }
}));

const LowerTop = () => {
    const classes = useStyles()
    // const currentUrl = window.location.href
    const currentUrl = removeQuery(window.location.href)
    const isEnglish = currentUrl.slice(-2) === '/e' || currentUrl.slice(-3) === 'eng'

    // 画像のパスを定義
    const visa = 'images/card_visa.png'
    const master = 'images/card_master.gif'
    const jcb = 'images/card_jcb.svg.png'
    const amex = 'images/card_amex.png'

    window.addEventListener('scroll', function (e) {
        try {
            // 変化するポイントまでスクロールしたらクラスを追加
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop
            if (scrollTop >= 5) {
                document.getElementById('btn-backtotop').classList.add('fadein')
            } else {
                // 変化するポイント以前であればクラスを削除
                document.getElementById('btn-backtotop').classList.remove('fadein')
            }
        } catch (e) {
        }
    })

    return (
        <div className={classes.root}>
            {/*スマホサイズの時にタップしたらトップに飛ぶようなボタン*/}
            <Hidden smUp>
                <IconButton
                    id='btn-backtotop'
                    className={classes.toTopButton}
                    onClick={() => scroll.scrollToTop()}
                    color='default'
                >
                    <PublishIcon/>
                </IconButton>
            </Hidden>

            {/*iPadサイズ*/}
            <Hidden xsDown lgUp>
                <IconButton
                    id='btn-backtotop'
                    className={classes.toTopButton}
                    onClick={() => scroll.scrollToTop()}
                    color='default'
                >
                    <PublishIcon/>
                </IconButton>
            </Hidden>
            {/*<PopUpDialog/>*/}
        </div>

    )
}
export default LowerTop