import React, {useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import {BACK} from "../../Messages/constantMessages";
import axios from "axios";
import {COSTCO_CARTITEM_URL, COSTCO_FIRST_ORDER_URL, COSTCO_ORDER_URL, MY_PROFILE_URL} from "../../Messages/urls";
import {withCookies} from "react-cookie";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import OrderDetails from "./OrderDetails";
import {Button} from "@material-ui/core";
import DisplayShoppingDetail from "./DisplayShoppingDetail";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(2),
        // backgroundColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary
    },
    container: {
        margin: "auto",
    },
    back: {
        padding: 0,
        margin: theme.spacing(2),
    },
    titleImg: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
        width: '100%',
    },
    submit: {
        margin: theme.spacing(0, 0, 2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    titleHeading: {
        fontSize: theme.typography.pxToRem(15),
        // flexBasis: '33.33%',
        flexShrink: 0,
    },
    centerHeading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        margin: '0 auto',
    }
}));


const PurchaseHistory = (props) => {
    const classes = useStyles()
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '/e' || currentUrl.slice(-2) === '_e'
    const token = props.cookies.get('current-token')

    const [myProfile, setMyProfile] = useState([])
    const [cartItem, setCartItem] = useState()
    const [myOrder, setMyOrder] = useState()
    const [firstOrder, setFirstOrder] = useState()
    const [expanded, setExpanded] = useState('')
    const [orderExpanded, setOrderExpanded] = useState('')
    const [shoppingExpanded, setShoppingExpanded] = useState('')

    const titleEng = 'images/title_history_eng.jpeg'
    const titleJp = 'images/title_history_jp.jpeg'

    const zeroPadding = (id) => {
        // 注文番号を4桁の0埋めにする
        return (Array(4).join('0') + id).slice(-4);
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    }

    const handleOrderChange = (panel) => (event, newOrderExpanded) => {
        setOrderExpanded(newOrderExpanded ? panel : false)
    }

    const handleShoppingChange = (panel) => (event, newOrderExpanded) => {
        setShoppingExpanded(newOrderExpanded ? panel : false)
    }

    const createOrderForm = (order) => {
        return (
            <ExpansionPanel key={order.id} style={{backgroundColor: '#afeeee'}} expanded={expanded === order.id}
                            onChange={handleChange(order.id)}>
                <ExpansionPanelSummary
                    // expandIcon={<AddIcon/>}
                    expandIcon={expanded === order.id ? <RemoveIcon/> : <AddIcon/>}
                    aria-controls="panel1a-content"
                    id={order.id}
                >
                    <Typography className={classes.titleHeading}>
                        {order.created_at.slice(5, 10).replace('-', '/')}
                    </Typography>
                    <Typography className={classes.centerHeading}>
                        #{zeroPadding(order.id)}
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ExpansionPanel key={order.products_price} expanded={orderExpanded === order.id}
                                            onChange={handleOrderChange(order.id)}>
                                <ExpansionPanelSummary
                                    expandIcon={orderExpanded === order.id ? <RemoveIcon/> : <AddIcon/>}
                                    aria-controls="panel1a-content"
                                    id={order.products_price}
                                >
                                    <Typography
                                        className={classes.centerHeading}>{isEnglish ? 'Order Details' : '注文内容'}
                                    </Typography>

                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{backgroundColor: '#f6f6f6', padding: '0'}}>
                                    <OrderDetails firstOrder={firstOrder}
                                                  orderId={order.id}
                                                  cartItems={cartItem}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                        {order.receipt_mail_complete &&
                        <Grid item xs={12}>
                            <ExpansionPanel key={order.shopping_fee} expanded={shoppingExpanded === order.id}
                                            onChange={handleShoppingChange(order.id)}>
                                <ExpansionPanelSummary
                                    expandIcon={orderExpanded === order.id ? <RemoveIcon/> : <AddIcon/>}
                                    aria-controls="panel1a-content"
                                    id={order.shopping_fee}
                                >
                                    <Typography className={classes.centerHeading}
                                                style={{float: 'center'}}>{isEnglish ? 'Shopping Details' : '買い物内容'}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{backgroundColor: '#f6f6f6', padding: '0'}}>
                                    <DisplayShoppingDetail firstOrder={firstOrder}
                                                           orderId={order.id}
                                                           orderItem={order.cart_item}
                                                           cartItems={cartItem}/>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                        }
                        {order.receipt_mail_complete &&
                        <Button fullWidth color='default' variant='contained'
                                onClick={() => window.location.href = `/receipt_dl?${order.id}`}
                                style={{backgroundColor: 'white', margin: '0.5rem', height: '3rem'}}>
                            {isEnglish ? 'Receipt' : '領収書'}
                        </Button>
                        }

                    </Grid>

                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }


    useEffect(() => {
        if (!token) {
            window.location.href = isEnglish ? 'login_e' : 'login'
        }
        const getMyProfile = async () => {
            const resmy = await axios.get(MY_PROFILE_URL,
                {headers: {'Authorization': `Token ${token}`}})
            resmy.data[0] && setMyProfile(resmy.data[0])
        }

        getMyProfile()
    }, [token, isEnglish])

    useEffect(() => {
        const getMyOrder = async () => {
            const order = await axios.get(`${COSTCO_ORDER_URL}?user=${myProfile.user_profile}`)
            order.data && setMyOrder(order.data)
        }
        const getCartItem = async () => {
            const item = await axios.get(`${COSTCO_CARTITEM_URL}?user=${myProfile.user_profile}`)
            item.data && setCartItem(item.data)
        }
        const getFirstOrder = async () => {
            const forder = await axios.get(`${COSTCO_FIRST_ORDER_URL}?user=${myProfile.user_profile}`)
            forder.data && setFirstOrder(forder.data)
        }
        getMyOrder()
        getCartItem()
        getFirstOrder()
    }, [myProfile])

    const list = []
    for (const order in myOrder) {
        list.push(createOrderForm(myOrder[order]))
    }

    return (
        <Container maxWidth='xs' style={{marginBottom: '2rem'}}>
            <div className={classes.paper}>
                {isEnglish ?
                    <img src={titleEng} alt="title" className={classes.titleImg}/>
                    :
                    <img src={titleJp} alt="title" className={classes.titleImg}/>
                }
            </div>
            <div style={{width: '100%'}}>
                {list}
            </div>

            <Grid container className={classes.back}>
                <Grid item xs>
                    <Link href={isEnglish ? '/my_page_e' : '/my_page'} variant="body2" color='textSecondary'>
                        {isEnglish ? BACK : '戻る'}
                    </Link>
                </Grid>
            </Grid>
        </Container>
    )
}

export default withCookies(PurchaseHistory)