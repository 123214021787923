import React from "react";
import {Container, Typography} from "@material-ui/core";
import Paper from '@material-ui/core/Paper';


const DisplayPricePage = (props) => {
    const orderPrice = props.location.search.substring(1).split('?')[0]

    const huskyApo = "images/husky_apo.png"


    return (
        <div>
            <Container maxWidth="xs" style={{textAlign: "center", paddingTop: "2rem"}}>
                <Paper style={{height: "150px"}}>
                    <Typography variant="h2" style={{backgroundColor: "white", paddingTop: "40px"}}>
                        ¥{orderPrice}
                    </Typography>
                </Paper>
                <img src={huskyApo} alt="husky-img" width="70%" style={{marginTop: "2rem"}}/>
            </Container>
        </div>

    )
}

export default DisplayPricePage