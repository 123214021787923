import React, {useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {Collapse} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    topBox: {
        margin: 0,
        borderTop: 'none',
        paddingTop: 0,
        paddingBottom: '15px',
    },
    qaBox: {
        margin: 0,
        padding: '15px 0',
        borderTop: '1px solid #d8dadc',
    },
    question: {
        color: '#069',
        fontWeight: 'bold',
        position: 'relative',
        padding: '0 0 0 35px',
        cursor: 'pointer',
        '&::before': {
            content: "''",
            display: 'block',
            position: 'absolute',
            left: 0,
            top: 0,
            backgroundImage: `url(images/icon-q.png)`,
            backgroundSize: '24px',
            width: '24px',
            height: '24px',
        },
    },
    answer: {
        marginTop: '10px',
        position: 'relative',
        padding: '0 0 0 35px',
        '&::before': {
            content: "''",
            position: 'absolute',
            left: 0,
            top: 0,
            backgroundImage: `url(images/icon-a.png)`,
            backgroundSize: '24px',
            width: '24px',
            height: '24px',
        },
    },
}));


const FAQList = (props) => {
    const classes = useStyles()
    const {isEnglish, index, question, answer} = props

    const [isOpen, setIsOpen] = useState(false)
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    }

    return (
        <li className={classes}>
            {index === 0 ?
                <dl className={classes.topBox}>
                    <dt className={classes.question} onClick={() => toggleOpen()}>{question}</dt>
                    <Collapse in={isOpen}>
                        <dd className={classes.answer}>{answer}</dd>
                    </Collapse>
                </dl>
                :
                <dl className={classes.qaBox}>
                    <dt className={classes.question} onClick={() => toggleOpen()}>{question}</dt>
                    <Collapse in={isOpen}>
                        {index === 8 && isEnglish === false ?
                            <dd className={classes.answer}>{
                                <p>
                                    <a href='https://costco-johokan.com/ranking/osusume-costco-items/'
                                       target='_blank'
                                       style={{textDecoration: 'none', color: 'blue'}}
                                    >
                                        こちら
                                    </a>
                                    でご紹介しています
                                </p>
                            }</dd>
                            :
                            <dd className={classes.answer}>{answer}</dd>
                        }
                    </Collapse>
                </dl>
            }

        </li>
    )
}

export default FAQList