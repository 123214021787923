import React from "react";
import NavBar from "../NavBar/NavBar";
import Container from "@material-ui/core/Container";
import Footer from "../Footer";

const TmpPage = () => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'
    const underConstruction = 'images/under-construction.png'

    return (
        <div className="pageWrapper">
            <NavBar/>
            <Container maxWidth='xs'>

                {/*<h3 style={{textAlign: 'center', marginTop: '3rem', marginBottom: '3rem', whiteSpace: 'pre-wrap'}}>*/}
                {/*    {isEnglish ?*/}
                {/*        'Summer Notice of closure'*/}
                {/*        :*/}
                {/*        '夏季休業のお知らせ'}*/}
                {/*</h3>*/}
                {/*<p style={{whiteSpace: 'pre-wrap', marginBottom: '4rem'}}>*/}
                {/*    {isEnglish ?*/}
                {/*        'The following schedule will be temporarily closed as a measure to prevent delivery delays and coronavirus infection due to the effects of Costco congestion. We apologize for any inconvenience.\n\n\n' +*/}
                {/*        `【Short-time business】Apr. 29・Apr. 30\n\n` +*/}
                {/*        '【Closed all day】May. 1st - 8th\n\n\n' +*/}
                {/*        '※We will stop accepting orders when the number of orders reaches a certain number.'*/}

                {/*        :*/}
                {/*        'コストコ混雑の影響による配送遅延、コロナウイルス感染予防対策として、下記日程を臨時休業とさせていただきます。ご不便お掛けし大変申し訳ございません。\n\n\n' +*/}
                {/*        '【時短営業】4月29日・4月30日\n\n' +*/}
                {/*        '【全日休業】5月1日〜5月8日\n\n\n' +*/}
                {/*        '※時短営業時につきまして、オーダー数が一定の数に達した時点でオーダー受注をストップさせていただきます。'*/}
                {/*    }*/}
                {/*</p>*/}
                {isEnglish ?
                    <>
                        <p style={{whiteSpace: 'pre-wrap', marginBottom: '4rem', marginTop: '2rem'}}>
                            {
                                'Huskycart is shopping at Costco Kawasaki store.\n\n' +
                                'Currently, the hot deli corner of the Costco Kawasaki store is under renovation. ' +
                                'So, all items of hot deli corner are not sold. Therefore, even in our service ' +
                                'Until the work is completed, it will be out of stock.\n\n' +
                                'The reopening date is undecided and is expected to take several months. ' +
                                'As soon as it resumes, we will immediately change it to a state that can be purchased with this service.\n\n' +
                                '【 Main items in the hot deli corner 】\n' +
                                '・Rotisserie chicken\n' +
                                '・Beef garlic rice\n' +
                                '\n' +
                                'We apologize for the inconvenience, but thank you for your continued support of Husky Cart.'
                            }
                        </p>
                        <div style={{textAlign: 'center', marginBottom: '3rem'}}>
                            <img src={underConstruction} alt="under-construction" width='50%'/>
                        </div>
                    </>

                    :

                    <>
                        <p style={{whiteSpace: 'pre-wrap', marginBottom: '4rem', marginTop: '3rem'}}>
                            {
                                '当サービスは、コストコ川崎倉庫店で買い物代行を行なっております。 \n \n' +
                                '現在コストコ川崎倉庫店のホットデリコーナーが改修工事のため、' +
                                'ホットデリコーナーの商品が販売されておりません。そのため当サービスでも' +
                                '改修工事が終了するまでは、在庫切れ対応とさせていただきます。 \n \n' +
                                '再開時期は未定で数ヶ月程かかると予想されております。' +
                                '再開次第、当サービスでも購入可能な状態にすぐさま変更いたします。 \n \n' +
                                '【 ホットデリコーナーの主な商品 】\n' +
                                '・ロティサリーチキン\n' +
                                '・ビーフガーリックライス \n \n' +
                                'ご不便おかけしますが、今後ともハスキーカートをよろしくお願いいたします。'
                            }
                        </p>
                        <div style={{textAlign: 'center', marginBottom: '2rem'}}>
                            <img src={underConstruction} alt="under-construction" width='50%'/>
                        </div>
                    </>

                }


            </Container>
            <Footer/>
        </div>

    )
}

export default TmpPage