import React, {useContext, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Pagination from "material-ui-flat-pagination";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {CostcoContext} from "./CostcoHome";
import CategoryDrawer from "./CategoryDrawer";
import Hidden from "@material-ui/core/Hidden";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import {useMediaQuery} from "@material-ui/core";
import {useWindowDimensions} from "../utils/useWindowDimensions";
import IconButton from "@material-ui/core/IconButton";

const useSize = () => {
    const isLgSize = useMediaQuery((theme) =>
        theme.breakpoints.up('lg')
    )
    return {isLgSize};
};


const ProductList = () => {
    const {
        cart,
        setCart,
        classes,
        filteredProduct,
        products,
        offset,
        setOffset,
        sort,
        setSort,
        setJapaneseSearchQuery,
        setEnglishSearchQuery,
        token,
        setLoginDialogOpen,
    } = useContext(CostcoContext)
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'

    const {isLgSize} = useSize()
    // 初期状態でラベルが表示されないように定義
    // const pageLimit = isLgSize ? 20 : 15
    const pageLimit = isLgSize ? 42 : 18
    const [open, setOpen] = React.useState(false);
    const [modalProduct, setModalProduct] = useState([])
    const [modalImage, setModalImage] = useState('')
    const {width} = useWindowDimensions()
    const scrollTopValue = width >= 1280 ? 100 : 70


    const addToCart = index => {
        setCart(cart.concat(products.find(product => product.id === index)))
    }

    const reverseTenPercentOff = price => {
        // 10%offされてものを直して返す
        return Math.ceil(price / 0.9 * 0.1) * 10
    }

    const handleSort = event => {
        if (!token) {
            setLoginDialogOpen(true);
        } else {
            const column = event.target.value
            setOffset(0)

            switch (column) {
                case 'default':
                    setSort({
                        name: '',
                    })
                    break
                case 'popularItems':
                    setSort({
                        name: 'popularItems',
                        key: 'self_code',
                        order: 1,
                    })
                    break
                case 'nameAscending':  // 商品名昇順
                    setSort({
                        name: 'nameAscending',
                        key: 'name',
                        order: 1
                    })
                    break
                case 'nameDescending':  // 諸品名昇順
                    setSort({
                        name: 'nameDescending',
                        key: 'name',
                        order: -1
                    })
                    break
                case 'priceAscending':  // 値段昇順
                    setSort({
                        name: 'priceAscending',
                        key: 'marked_up_price',
                        order: 1
                    })
                    break
                case 'priceDescending':  // 値段降順
                    setSort({
                        name: 'priceDescending',
                        key: 'marked_up_price',
                        order: -1
                    })
                    break
                case 'newDescending':  // id降順
                    setSort({
                        name: 'newDescending',
                        key: 'id',
                        order: -1,
                    })
                    break
                default:
                    throw new Error('Unknown sort key')
            }
        }
    }

    const handleClickPagination = (off) => {
        if (!token) {
            setLoginDialogOpen(true);
        } else {
            setOffset(off)
            document.body.scrollTop = document.documentElement.scrollTop = scrollTopValue;
        }
    }

    const handleSortDialog = () => {
        if (!token) {
            setLoginDialogOpen(true);
        }
    }

    // const handleChange = (event) => {
    //     setParPage(event.target.value);
    // };

    const handleOpen = (productId) => {
        setOpen(true)
        const product = products.find(product => product.id === Number(productId))
        setModalProduct(product)
        setModalImage(product.image.replace('http', 'https'))
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleJapaneseQuery = () => event => {
        if (!token) {
            setLoginDialogOpen(true);
        } else {
            setJapaneseSearchQuery(event.target.value)
        }
    }

    const handleEnglishQuery = () => event => {
        if (!token) {
            setLoginDialogOpen(true);
        } else {
            setEnglishSearchQuery(event.target.value)
        }
    }


    return (
        <>
            {/*<FormControl className={classes.formControl}>*/}
            {/*    <InputLabel>{isEnglish ? 'Display' : '表示数'}</InputLabel>*/}
            {/*    <Select*/}
            {/*        value={parPage}*/}
            {/*        onChange={handleChange}*/}
            {/*    >*/}
            {/*        <MenuItem value={12}>12</MenuItem>*/}
            {/*        <MenuItem value={24}>24</MenuItem>*/}
            {/*        <MenuItem value={48}>48</MenuItem>*/}
            {/*    </Select>*/}
            {/*</FormControl>*/}
            <Hidden lgUp>
                <CategoryDrawer/>
            </Hidden>

            <div
                className={classes.marginTheme}
                style={{display: "flex", flexDirection: "row-reverse", alignItems: "flex-end", minWidth: 100}}>
                <FormControl className={classes.formControl}>
                    {/*<InputLabel>{isEnglish ? 'Sort' : '表示順'}</InputLabel>*/}
                    <Select
                        style={{fontSize: '14px'}}
                        value={sort.name}
                        onChange={handleSort}
                    >
                        <MenuItem value='default'
                                  style={{fontSize: '14px'}}
                                  onClick={handleSortDialog}>
                            {isEnglish ? 'Sort' : '表示順'}
                        </MenuItem>
                        <MenuItem value='newDescending'
                                  style={{fontSize: '14px'}}
                                  onClick={handleSortDialog}>
                            {isEnglish ? 'New items' : '新商品順'}
                        </MenuItem>
                        <MenuItem value='popularItems'
                                  style={{fontSize: '14px'}}
                                  onClick={handleSortDialog}>
                            {isEnglish ? 'Popular items' : '人気商品順'}
                        </MenuItem>
                        <MenuItem value='priceAscending'
                                  style={{fontSize: '14px'}}
                                  onClick={handleSortDialog}>
                            {isEnglish ? 'Price - Low to High' : '価格の低い順'}
                        </MenuItem>
                        <MenuItem value='priceDescending'
                                  style={{fontSize: '14px'}}
                                  onClick={handleSortDialog}>
                            {isEnglish ? 'Price - High to Low' : '価格の高い順'}
                        </MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    onClick={handleSortDialog}
                    disabled={!token}
                    placeholder={isEnglish ? 'Item search' : '商品検索'}
                    style={{marginLeft: "10px", width: "120px"}}
                    onChange={isEnglish ? handleEnglishQuery() : handleJapaneseQuery()}
                    className={classes.searchField}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon style={{color: 'rgba(0, 0, 0, 0.54)'}}/>
                            </InputAdornment>
                        ),
                        style: {fontSize: '14px'},
                    }}
                />
            </div>

            <Grid container spacing={1}>
                {filteredProduct.slice(offset, offset + pageLimit).map((product) => (
                    <Grid item key={product.id} xs={4} sm={4} md={3} lg={2}>
                        <Card className={classes.card}>
                            {/*アクティブだったら普通に表示*/}
                            {product.active ?
                                (product.image ?
                                        // 普通表示
                                        <CardMedia
                                            className={classes.cardMedia}
                                            image={product.image && product.image.replace('http', 'https')}
                                            onClick={() => handleOpen(product.id)}
                                        />

                                        // 10%off
                                        // <div className='promotion-wrapper'>
                                        //     <p className='promotion'>{isEnglish ? '10%off' : '10%off'}</p>
                                        //     <CardMedia
                                        //         className='promotion-image'
                                        //         image={product.image && product.image.replace('http', 'https')}
                                        //         onClick={() => handleOpen(product.id)}
                                        //     />
                                        // </div>
                                        :
                                        //　画像がないパターン
                                        // <div className='sold-out-wrapper'>
                                        //     <p className='sold-out'
                                        //        style={{opacity: '0.5'}}>{isEnglish ? 'No Image' : 'No Image'}</p>
                                        //     <CardMedia
                                        //         className='sold-out-image'
                                        //         image={product.image && product.image.replace('http', 'https')}
                                        //     />
                                        // </div>
                                        <CardMedia
                                            className={classes.cardMedia}
                                            image={product.image && product.image.replace('http', 'https')}
                                            onClick={() => handleOpen(product.id)}
                                        />
                                )
                                :
                                // 在庫切れ
                                <div className='sold-out-wrapper'>
                                    <p className='sold-out'>{isEnglish ? 'Sold out' : '在庫切れ'}</p>
                                    <CardMedia
                                        className='sold-out-image'
                                        image={product.image && product.image.replace('http', 'https')}
                                    />
                                </div>
                            }
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom className='product-title'
                                            onClick={() => handleOpen(product.id)}>
                                    {isEnglish ? product.english_name : product.name}
                                </Typography>
                                {/*普通パターン*/}
                                <Typography variant='body2' style={{color: 'red'}} className='product-price'
                                            onClick={() => handleOpen(product.id)}>
                                    ¥{product.marked_up_price}
                                </Typography>

                                {/*10%off*/}
                                {/*<Grid container>*/}
                                {/*    <Grid item xs={4}>*/}
                                {/*        <Typography variant='body2' style={{color: 'red', textAlign: 'center', fontSize: '12px'}}*/}
                                {/*                    className='promotion-price'>*/}
                                {/*            ¥{reverseTenPercentOff(product.marked_up_price)}*/}
                                {/*        </Typography>*/}
                                {/*    </Grid>*/}
                                {/*    <Grid item xs={2} style={{textAlign: 'center'}}>*/}
                                {/*        →*/}
                                {/*    </Grid>*/}
                                {/*    <br/>*/}
                                {/*    <Grid item xs={6}>*/}
                                {/*        <Typography variant='body2'*/}
                                {/*                    style={{color: 'red', textAlign: 'center', fontSize: '14px'}}>*/}
                                {/*            ¥{product.marked_up_price}*/}
                                {/*        </Typography>*/}
                                {/*    </Grid>*/}
                                {/*</Grid>*/}

                                <Typography variant='caption' className='product-units'>
                                    {product.size_units === 'nan' ? "" :
                                        (isEnglish ? product.english_size_units : product.size_units)
                                    }
                                </Typography>
                            </CardContent>
                            <CardActions className='product-button-container'>
                                <Button
                                    disabled={!product.active}
                                    size="small"
                                    variant={"contained"}
                                    className='product-button'
                                    style={{
                                        // backgroundColor: '#afeeee',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        width: '100%',
                                        textTransform: 'none',
                                    }}
                                    onClick={() => addToCart(product.id)}
                                >
                                    {isEnglish ? 'Add to cart' : 'カートに追加'}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}

                {/*<p style={{marginLeft: '20px',marginBottom: '0px', color: 'darkgoldenrod', fontSize: '15px'}}>*/}
                {/*    {isEnglish? 'All prices displayed are tax included.': '表示価格は全て税込価格です。'}*/}
                {/*</p>*/}


                {/*ページネーション*/}
                <Grid item xs={12}>
                    <Pagination
                        className={classes.pagination}
                        currentPageColor='secondary'
                        otherPageColor='default'
                        limit={pageLimit}
                        offset={offset}
                        total={filteredProduct.length}
                        onClick={(e, offset) => handleClickPagination(offset)}
                        classes={{
                            rootStandard: classes.pageNaviStandard,
                            rootCurrent: classes.pageNaviCurrent,
                            rootEnd: classes.pageNaviArrow,
                            text: classes.pageNaviText
                        }}
                    />
                </Grid>
            </Grid>

            {/*画像をクリックした時に出るダイアログ*/}
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth='xs'
            >
                <div style={{border: '7px solid #afeeee'}}>
                    <IconButton onClick={() => handleClose()} className={classes.closeButton}>
                        <CloseIcon/>
                    </IconButton>
                    <DialogContent>
                        <DialogContentText>
                            <div style={{textAlign: 'center'}}>
                                <img src={modalImage} alt={modalProduct.id} width='100%'/>
                            </div>
                            <Typography gutterBottom style={{color: 'black'}}>
                                {isEnglish ? modalProduct.english_name : modalProduct.name}
                            </Typography>
                            <Typography variant='body2' style={{color: 'red', fontSize: '15px'}}>
                                ¥{modalProduct.marked_up_price}
                            </Typography>
                            <Typography variant='caption' style={{color: 'black', fontSize: '13px'}}>
                                {modalProduct.size_units === 'nan' ? "" :
                                    (isEnglish ? modalProduct.english_size_units : modalProduct.size_units)
                                }
                            </Typography>
                        </DialogContentText>
                        <DialogActions style={{padding: '8px 0px'}}>
                            <Button
                                disabled={!modalProduct.active}
                                size="small"
                                variant={"contained"}
                                style={{
                                    // backgroundColor: '#afeeee',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    width: '100%',
                                    textTransform: 'none',
                                }}
                                onClick={() => {
                                    addToCart(modalProduct.id);
                                    setOpen(false);
                                }}
                            >
                                {isEnglish ? 'Add to cart' : 'カートに追加'}
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </div>
            </Dialog>
        </>
    )
}

export default ProductList