import React, {useEffect, useState} from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {Slider, Fade} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    container: {
        // margin: '2rem',
        marginTop: '2rem',
        width: '80%',
        position: 'relative',
    },
    popValue: {
        color: 'white',
        position: 'absolute',
        // left: 'calc(20% - 15px)',
        top: -23,
        width: '25px',
        height: '25px',
        display: 'flex',
        transform: 'rotate(-45deg)',
        alignItems: 'center',
        borderRadius: '50% 50% 50% 0',
        justifyContent: 'center',
        backgroundColor: '#3880ff',
        border: '1px solid dimgray',
    },
    pop5: {
        position: 'absolute',
        left: 'calc(25% - 13px)',
        top: -23,
        width: '25px',
        height: '25px',
        display: 'flex',
        transform: 'rotate(-45deg)',
        alignItems: 'center',
        borderRadius: '50% 50% 50% 0',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
        border: '1px solid dimgray',
        color: 'dimgray',
    },
    pop10: {
        position: 'absolute',
        left: 'calc(50% - 13px)',
        top: -23,
        width: '25px',
        height: '25px',
        display: 'flex',
        transform: 'rotate(-45deg)',
        alignItems: 'center',
        borderRadius: '50% 50% 50% 0',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
        border: '1px solid dimgray',
        color: 'dimgray',
    },
    pop15: {
        position: 'absolute',
        left: 'calc(75% - 13px)',
        top: -23,
        width: '25px',
        height: '25px',
        display: 'flex',
        transform: 'rotate(-45deg)',
        alignItems: 'center',
        borderRadius: '50% 50% 50% 0',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
        border: '1px solid dimgray',
        color: 'dimgray',
    },
    pop20: {
        position: 'absolute',
        left: 'calc(100% - 13px)',
        top: -23,
        width: '25px',
        height: '25px',
        display: 'flex',
        transform: 'rotate(-45deg)',
        alignItems: 'center',
        borderRadius: '50% 50% 50% 0',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
        border: '1px solid dimgray',
        color: 'dimgray',
    },
    popperStyle: {
        backgroundColor: 'darkgray',
        // color: 'white',
        fontWeight: 'bold',
    },
    currentlyTotal: {
        fontSize: '16px',
        color: '#3880ff',
        fontWeight: 'bold',
    },
    message: {
        border: '1px solid gray',
        padding: '15px',
        borderRadius: '10px',
        whiteSpace: 'pre-wrap',
        fontSize: '14px',
    },
    alertMessage: {
        color: 'rgba(0, 0, 0, 0.54)',
        textAlign: 'center',
        fontSize: '12px',
        whiteSpace: 'pre-wrap',
    },
}));

const marks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 50000,
        label: '¥50,000'
    },
    {
        value: 100000,
        label: '¥100,000'
    },
    {
        value: 150000,
        label: '¥150,000'
    },

    {
        value: 200000,
        label: '¥200,000'
    },
];

const OrderSlider = withStyles({
    root: {
        color: '#3880ff',
        height: 25,
        padding: '15px 0',
    },
    thumb: {
        height: 0,
        width: 0,
    },
    markLabel: {
        top: 40,
    },
    valueLabel: {
        // left: 'calc(-50%+20px)',
        left: 'calc(-50% - 10px)',
        top: -22,
        '& *': {
            background: '#3880ff',
            color: '#fff',
        },
    },
    track: {
        height: 25,
    },
    rail: {
        height: 25,
        opacity: 1,
        backgroundColor: '#bfbfbf',
    },
    mark: {
        backgroundColor: 'white',
        height: 25,
        width: 2,
        marginTop: 0,
    },
    markActive: {
        opacity: 1,
        backgroundColor: '#bfbfbf',
    },
})(Slider);


const OrderProgressBar = (props) => {

    const {isEnglish, order} = props

    const classes = useStyles()
    const [orderTotal, setOrderTotal] = useState(0)
    const [orderRate, setOrderRate] = useState(0)
    const [message, setMessage] = useState('')

    const baseDateStr = '2022-06-17'
    const firstThreshold = 100000
    const secondThreshold = 200000

    const calcOrderTotal = () => {
        if (order) {
            const baseDate = new Date(baseDateStr)
            const filteredOrder = order.filter(o => {
                let createdDate = new Date(o.updated_at.slice(0, 10))
                if (baseDate <= createdDate) {
                    return o
                }
            })
            const filteredOrderTotal = filteredOrder.map(fo => fo.total).reduce((prev, curr) => prev + curr, 0)
            setOrderTotal(filteredOrderTotal % secondThreshold)
            setOrderRate(Number(((filteredOrderTotal % secondThreshold) / secondThreshold) * 100))
        }
    }

    useEffect(() => {
        calcOrderTotal()
    }, [order])


    const handleClick = (newPlacement, type) => (event) => {
        switch (type) {
            case 'pop5':
                setMessage(isEnglish ? `When you reach ¥50,000,\nyou will receive a ¥800 OFF coupon.` : `50,000円に到達すると、\n800円OFFクーポンが付与されます。`)
                setIsShowFirst(true)
                setIsShowSecond(false)
                setIsShowThird(false)
                setIsShowForth(false)
                break
            case 'pop10':
                setMessage(isEnglish ? `When you reach ¥100,000,\nyou will receive a ¥1,500 OFF coupon.` : `100,000円に到達すると、\n1,500円OFFクーポンが付与されます。`)
                setIsShowFirst(false)
                setIsShowSecond(true)
                setIsShowThird(false)
                setIsShowForth(false)
                break
            case 'pop15':
                setMessage(isEnglish ? `When you reach ¥150,000,\nyou will receive a ¥3,000 OFF coupon.` : `150,000円に到達すると、\n3,000円OFFクーポンが付与されます。`)
                setIsShowFirst(false)
                setIsShowSecond(false)
                setIsShowThird(true)
                setIsShowForth(false)
                break
            case 'pop20':
                setMessage(isEnglish ? `When you reach ¥200,000,\nyou will receive a ¥5,000 OFF coupon.` : `200,000円に到達すると、\n5,000円OFFクーポンが付与されます。`)
                setIsShowFirst(false)
                setIsShowSecond(false)
                setIsShowThird(false)
                setIsShowForth(true)
                break
        }
    }

    const [isShowFirst, setIsShowFirst] = useState(false)
    const [isShowSecond, setIsShowSecond] = useState(false)
    const [isShowThird, setIsShowThird] = useState(false)
    const [isShowForth, setIsShowForth] = useState(false)

    // setOrderTotal(50000)

    return (
        <>
            <p className={classes.currentlyTotal}>{isEnglish ? `＼ Currently total ¥${orderTotal} ／` : `＼ 現在の合計ご利用額は${orderTotal}円です ／`}</p>
            {isShowFirst &&
            <Fade in={isShowFirst} {...(isShowFirst ? {timeout: 500} : {})}>
                <p className={classes.message}>{message}</p>
            </Fade>
            }
            {isShowSecond &&
            <Fade in={isShowSecond} {...(isShowSecond ? {timeout: 500} : {})}>
                <p className={classes.message}>{message}</p>
            </Fade>
            }
            {isShowThird &&
            <Fade in={isShowThird} {...(isShowThird ? {timeout: 500} : {})}>
                <p className={classes.message}>{message}</p>
            </Fade>
            }
            {isShowForth &&
            <Fade in={isShowForth} {...(isShowForth ? {timeout: 500} : {})}>
                <p className={classes.message}>{message}</p>
            </Fade>
            }

            <div className={classes.container}>
                <OrderSlider
                    value={orderTotal}
                    min={0}
                    max={200000}
                    marks={marks}
                />

                <span className={classes.pop5} onClick={handleClick('top', 'pop5')}>
                    <span style={{transform: 'rotate(45deg)'}}>?</span>
                </span>

                <span className={classes.pop10} onClick={handleClick('top', 'pop10')}>
                    <span style={{transform: 'rotate(45deg)'}}>?</span>
                </span>

                <span className={classes.pop15} onClick={handleClick('top', 'pop15')}>
                    <span style={{transform: 'rotate(45deg)'}}>?</span>
                </span>

                <span className={classes.pop20} onClick={handleClick('top', 'pop20')}>
                    <span style={{transform: 'rotate(45deg)'}}>?</span>
                </span>

                <p className={classes.alertMessage}>
                    {isEnglish ?
                        '※The above gauge only be applied to orders with delivery completed after 17th Jun. 2022, ' +
                        'and will be reflected after delivery is completed. It will be reset ' +
                        '¥200,000 has been reached.'
                        :
                        '※上記ゲージは、2022年6月17日以降に配達が完了した注文に限り、配達完了後に加算が反映されます。\nゲージが200,000円に到達したらリセットされます。'}
                </p>


            </div>
        </>
    )
}

export default OrderProgressBar