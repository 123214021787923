import React from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer";
import ContactUsHome from "../ContactUs/ContactUsHome";

const ContactUsPage = () => {
    return (
        <div className="pageWrapper">
            <NavBar/>
            <ContactUsHome/>
            <Footer/>
        </div>
    )
}

export default ContactUsPage