import React, {useEffect, useState} from "react";
import axios from "axios";
import {COSTCO_INVITATION_URL, MY_PROFILE_URL} from "../../Messages/urls";
import {makeStyles} from "@material-ui/core/styles";
import {withCookies} from "react-cookie";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import {BACK} from "../../Messages/constantMessages";
import Container from "@material-ui/core/Container";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Typography} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import TopPage from "../pages/TopPage";


const useStyles = makeStyles((theme) => ({
    titleImg: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    back: {
        padding: 0,
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    tooltip: {
        marginTop: '2rem',
    },
    lineButton: {
        borderRadius: '8px',
        marginTop: '35px',
        marginLeft: '1rem',
        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)'
    }
}));
const InviteFriend = (props) => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '/e' || currentUrl.slice(-2) === '_e'
    const token = props.cookies.get('current-token')

    const classes = useStyles()

    const title_eng = 'images/title_invite_eng.jpg'
    const title_jp = 'images/title_invite_jp.jpg'
    const inviteHusky = 'images/invite_husky.png'
    const lineButton = 'images/line_send_button.png'

    const [myProfile, setMyProfile] = useState()
    const [myInvitationCode, setMyInvitationCode] = useState()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')

    const invitationURL = isEnglish ? `https://husky-cart.com/register?${myInvitationCode}_e` : `https://husky-cart.com/register?${myInvitationCode}`
    const inviteMessage = isEnglish ? `You have been invited to Husky Cart (COSTCO Shopping Delivery service) by ${firstName} ${lastName}. Check it out at the link below!\n${invitationURL}` : `${lastName} ${firstName}さんからHusky Cart(コストコ買い物代行サービス)に招待されました。下記リンクからチェックしよう！\n${invitationURL}`
    const lineMessage = `https://line.me/R/msg/text/?${inviteMessage}`

    const [openTip, setOpenTip] = useState(false)

    const handleCloseTip = () => {
        setOpenTip(false)
    }

    const handleOpenTip = () => {
        setOpenTip(true)
    }

    useEffect(() => {
        // Profileを取得
        axios.get(MY_PROFILE_URL, {headers: {'Authorization': `Token ${token}`}})
            .then(res => {
                setMyProfile(res.data[0])
            })
        if (!token) {
            window.location.href = isEnglish ? '/e' : '/'
        }
    }, [token])

    useEffect(() => {
        if (myProfile) {
            axios.get(`${COSTCO_INVITATION_URL}?invite=${myProfile.user_profile}`)
                .then(res => {
                    setMyInvitationCode(res.data[0].code)
                })
        }
        if (myProfile) {
            setFirstName(myProfile.first_name)
            setLastName(myProfile.last_name)
        }
    }, [myProfile])

    return (
        <Container maxWidth='xs' style={{marginTop: '1rem'}}>
            {isEnglish ?
                <img src={title_eng} alt="title" className={classes.titleImg}/>
                :
                <img src={title_jp} alt="title" className={classes.titleImg}/>
            }

            <ol style={{paddingLeft: '1rem', marginBottom: '0', marginTop: '2rem', fontSize: '14px'}}>
                <li style={{marginBottom: '0.5rem'}}>
                    {isEnglish ? 'Copy the invitation link from the button below and send it to your friends!' : '下記のボタンから招待リンクをコピーしてお友達に送ろう！'}
                </li>
                {/*<li style={{marginBottom: '0.5rem'}}>*/}
                {/*    {isEnglish ? 'If your friend becomes a new member through the invitation link, he/she will get a ¥500 off coupon.' : 'お友達が招待リンクから新規会員するとお友達は500円offクーポンが貰えるよ'}*/}
                {/*</li>*/}
                <li style={{marginBottom: '0.5rem'}}>
                    {isEnglish ? 'Friends who register as new members through the invitation link or by entering the invitation code will receive a 500 yen off coupon.' : '招待リンクから、もしくは招待コードを入力して新規会員登録したお友達は、500円OFFクーポンもらえるよ。'}
                </li>
                <li style={{marginBottom: '0.5rem'}}>
                    {isEnglish ? 'When your friend who registers as a new member through the invitation link or by entering the invitation code makes first order, you will get a coupon for 1,000 yen off.' : '招待リンクから、もしくは招待コードを入力して新規会員登録したお友達が、初回注文すると、あなたは1000円OFFクーポンもらえるよ。'}
                </li>
                {/*<li style={{marginBottom: '0.5rem'}}>*/}
                {/*    {isEnglish ? 'You can invite as many people as you want, so refer lots of friends!\n' : '招待は何人でもできるので沢山紹介してね！'}*/}
                {/*</li>*/}
            </ol>
            <p style={{marginTop: '1.5rem', fontSize: '14px'}}>
                {isEnglish ? '※In order to prevent fraudulent activities, invitations to friends with the same person or address, or coupons for invalid orders will be invalidated or deleted.' : '※不正行為防止のため、同一人物及び同住所でのお友達招待、または無効オーダーによるクーポンは無効・削除させていただきます。'}
            </p>

            <div style={{textAlign: 'center', marginTop: '1.5rem'}}>
                <Tooltip title={isEnglish ? 'Copied!' : 'コピーされました!'}
                         className={classes.tooltip}
                         arrow
                         placement='top'
                         disableHoverListener
                         open={openTip}
                         onClose={handleCloseTip}>
                    <CopyToClipboard text={inviteMessage}>
                        <Button
                            onClick={handleOpenTip}
                            variant="contained"
                            color="primary"
                            style={{textTransform: 'none'}}
                            className={classes.button}
                            startIcon={<FileCopyIcon/>}>
                            {isEnglish ? 'Invitation Link' : '招待リンク'}
                        </Button>
                    </CopyToClipboard>
                </Tooltip>

                <Link
                    component="button"
                    // href={lineMessage}
                    onClick={() => window.location.href = lineMessage}
                >
                    <img src={lineButton} alt="line" height='36px' className={classes.lineButton}/>
                </Link>

                <Typography variant='body2' style={{
                    marginTop: '0.5rem',
                    color: 'rgba(0, 0, 0, 0.54)'
                }}>({isEnglish ? `Invitation code : ${myInvitationCode}` : `招待コード : ${myInvitationCode}`})</Typography>
            </div>


            <div style={{textAlign: 'center', marginTop: '1rem'}}>
                <img src={inviteHusky} alt="invite" width='50%'/>
            </div>

            <Grid container className={classes.back}>
                <Grid item xs>
                    <Link href={isEnglish ? '/my_page_e' : '/my_page'} variant="body2" color='textSecondary'>
                        {isEnglish ? BACK : '戻る'}
                    </Link>
                </Grid>
            </Grid>

        </Container>
    )
}

export default withCookies(InviteFriend)