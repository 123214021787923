import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import {withCookies} from 'react-cookie'
import axios from "axios";
import {COSTCO_MAIL_LIST_URL, CREATE_PROFILE_URL, MY_PROFILE_URL} from "../../Messages/urls";
import DisplayProfile from "./DisplayProfile";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import {BACK, BUTTON_EDIT, BUTTON_UPDATE, LABEL_PHONE_NUMBER,} from "../../Messages/constantMessages";
import {isMatchLanguagePrefecture, isValidCombination} from "../utils/func"
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.primary
    },
    container: {
        margin: "auto",
    },
    back: {
        padding: 0,
        margin: 0,
    },
    titleImg: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    submit: {
        margin: theme.spacing(0, 0, 2),
    },
    selectForm: {
        width: '100%',
        "& .MuiFormLabel-root.Mui-error": {
            color: "#ff5722 !important",
        },
        "& .MuiOutlinedInput-root.Mui-error": {
            '& fieldset': {
                borderColor: '#ff5722',
            },
        },
    }
}));

const Profile = (props) => {
    const classes = useStyles()

    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '/e' || currentUrl.slice(-2) === '_e'

    const [isEditMode, setIsEditMode] = useState(true)
    const token = props.cookies.get('current-token')
    const [myProfile, setMyProfile] = useState([])
    const [editedProfile, setEditedProfile] = useState({
        id: '',
        first_name: '',
        last_name: '',
        postal_code: '',
        prefecture: '',
        city_name: '',
        place_number: '',
        building_floor_room_name: '',
        phone_number: '',
    })
    const [recipientList, setRecipientList] = useState()
    const [mailChecked, setMailChecked] = useState(false)
    // 画像のパス
    const title_eng = 'images/title_profile_eng.jpeg'
    const title_jp = 'images/title_profile_jp.jpeg'

    const handleInputChange = () => event => {
        const value = event.target.value
        const name = event.target.name
        setEditedProfile({...editedProfile, [name]: value})
    }

    useEffect(() => {
        if (!token) {
            window.location.href = isEnglish ? 'login_e' : 'login'
        }
        const getMyProfile = async () => {
            const resmy = await axios.get(MY_PROFILE_URL,
                {headers: {'Authorization': `Token ${token}`}})
            resmy.data[0] && setMyProfile(resmy.data[0])
            resmy.data[0] && setEditedProfile({
                id: resmy.data[0].id,
                first_name: resmy.data[0].first_name,
                last_name: resmy.data[0].last_name,
                postal_code: resmy.data[0].postal_code,
                prefecture: resmy.data[0].prefecture,
                city_name: resmy.data[0].city_name,
                place_number: resmy.data[0].place_number,
                building_floor_room_name: resmy.data[0].building_floor_room_name,
                phone_number: resmy.data[0].phone_number
            })
        }
        getMyProfile()
        // メールリスト取得
        const getMailList = async () => {
            const res = await axios.get(COSTCO_MAIL_LIST_URL)
            res.data[0] && setRecipientList(res.data[0].recipient_list)
        }
        getMailList()
    }, [token, isEnglish])

    useEffect(() => {
        myProfile && recipientList && setMailChecked(
            recipientList.includes(myProfile.user_profile)
        )
    }, [myProfile, recipientList])

    const editProfile = async () => {
        const editData = new FormData()
        editData.append("first_name", editedProfile.first_name)
        editData.append("last_name", editedProfile.last_name)
        editData.append("postal_code", editedProfile.postal_code)
        editData.append("prefecture", editedProfile.prefecture)
        editData.append("city_name", editedProfile.city_name)
        editData.append("place_number", editedProfile.place_number)
        editData.append("building_floor_room_name", editedProfile.building_floor_room_name)
        editData.append("phone_number", editedProfile.phone_number)
        try {
            const res = await axios.put(`${CREATE_PROFILE_URL}${myProfile.id}/`, editData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            })
            setMyProfile(res.data)
            isEnglish ? window.location.href = '/profile_e' : window.location.href = '/profile'
        } catch (e) {
            console.log("error from editProfile", e)
        }
        editMailList()
    }

    const editMailList = async () => {
        if (mailChecked && !recipientList.includes(myProfile.user_profile)) {
            // チェックが入っていたら受信者リストにuser_profileを登録
            recipientList.push(myProfile.user_profile)
            const data = {
                "recipient_list": recipientList
            }
            await axios.put(`${COSTCO_MAIL_LIST_URL}1/`, data)
        }
        if (!mailChecked && recipientList.includes(myProfile.user_profile)) {
            // チェックが入ってなかったら受信者リストからuser_Profileを外す
            const recipientListUpdated = recipientList.filter(function (item) {
                return item !== myProfile.user_profile
            });
            const data = {
                "recipient_list": recipientListUpdated
            }
            await axios.put(`${COSTCO_MAIL_LIST_URL}1/`, data)
        }
    }

    const tokyo = isEnglish ? 'Tokyo' : '東京都'
    const kanagawa = isEnglish ? 'Kanagawa' : '神奈川県'

    return (
        <Container maxWidth='xs' style={{marginBottom: '2rem'}}>
            <div className={classes.paper}>
                {isEnglish ?
                    <img src={title_eng} alt="title" className={classes.titleImg}/>
                    :
                    <img src={title_jp} alt="title" className={classes.titleImg}/>
                }

                {/*プロフィール更新*/}
                {myProfile.id ?
                    (isEditMode ?
                            // update
                            <div>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            variant="standard" margin="normal"
                                            fullWidth
                                            label={isEnglish ? 'Last Name' : '苗字'}
                                            name="last_name"
                                            defaultValue={myProfile.last_name}
                                            onChange={handleInputChange()}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            variant="standard" margin="normal"
                                            fullWidth
                                            label={isEnglish ? 'First Name' : '名前'}
                                            name="first_name"
                                            defaultValue={myProfile.first_name}
                                            onChange={handleInputChange()}
                                        />
                                    </Grid>
                                </Grid>

                                <TextField
                                    variant="standard" margin="normal"
                                    fullWidth
                                    label={isEnglish ? 'Postal Code (no hyphen)' : '郵便番号(ハイフンなし)'}
                                    name="postal_code"
                                    defaultValue={myProfile.postal_code}
                                    onChange={handleInputChange()}
                                />

                                <FormControl className={classes.selectForm} margin="normal"
                                             {...(!isMatchLanguagePrefecture(editedProfile.prefecture, isEnglish) ? {error: true} : {error: false})}>
                                    <InputLabel
                                        id="prefecture-simple-select-outlined-label">{isEnglish ? 'Prefecture' : '都道府県'}</InputLabel>
                                    <Select
                                        labelId="prefecture-simple-select-outlined-label"
                                        id="prefecture-simple-select-outlined"
                                        value={editedProfile.prefecture}
                                        onChange={handleInputChange()}
                                        label={isEnglish ? 'Prefecture' : '都道府県'}
                                        name="prefecture"
                                    >
                                        <MenuItem value={tokyo}>{tokyo}</MenuItem>
                                        <MenuItem value={kanagawa}>{kanagawa}</MenuItem>
                                    </Select>
                                </FormControl>

                                {editedProfile.prefecture === tokyo &&
                                <FormControl className={classes.selectForm} margin="normal"
                                             {...(!isValidCombination(editedProfile.prefecture, editedProfile.city_name) ? {error: true} : {error: false})}>
                                    <InputLabel
                                        id="city-simple-select-outlined-label">{isEnglish ? 'City Name' : '市区町村名'}</InputLabel>
                                    <Select
                                        labelId="city-simple-select-outlined-label"
                                        id="city-simple-select-outlined"
                                        value={editedProfile.city_name}
                                        onChange={handleInputChange()}
                                        label={isEnglish ? 'City Name' : '市区町村名'}
                                        name="city_name"
                                        margin="normal"
                                    >
                                        <MenuItem
                                            value={isEnglish ? 'Shinjuku' : '新宿区'}>{isEnglish ? 'Shinjuku' : '新宿区'}</MenuItem>
                                        <MenuItem
                                            value={isEnglish ? 'Chiyoda' : '千代田区'}>{isEnglish ? 'Chiyoda' : '千代田区'}</MenuItem>
                                        <MenuItem
                                            value={isEnglish ? 'Chuo' : '中央区'}>{isEnglish ? 'Chuo' : '中央区'}</MenuItem>
                                        <MenuItem
                                            value={isEnglish ? 'Koto' : '江東区'}>{isEnglish ? 'Koto' : '江東区'}</MenuItem>
                                        <MenuItem
                                            value={isEnglish ? 'Minato' : '港区'}>{isEnglish ? 'Minato' : '港区'}</MenuItem>
                                        <MenuItem
                                            value={isEnglish ? 'Shibuya' : '渋谷区'}>{isEnglish ? 'Shibuya' : '渋谷区'}</MenuItem>
                                        <MenuItem
                                            value={isEnglish ? 'Shinagawa' : '品川区'}>{isEnglish ? 'Shinagawa' : '品川区'}</MenuItem>
                                        <MenuItem
                                            value={isEnglish ? 'Meguro' : '目黒区'}>{isEnglish ? 'Meguro' : '目黒区'}</MenuItem>
                                        <MenuItem
                                            value={isEnglish ? 'Setagaya' : '世田谷区'}>{isEnglish ? 'Setagaya' : '世田谷区'}</MenuItem>
                                        <MenuItem
                                            value={isEnglish ? 'Ota' : '大田区'}>{isEnglish ? 'Ota' : '大田区'}</MenuItem>
                                    </Select>
                                </FormControl>}

                                {editedProfile.prefecture === kanagawa &&
                                <FormControl className={classes.selectForm} margin="normal"
                                             {...(!isValidCombination(editedProfile.prefecture, editedProfile.city_name) ? {error: true} : {error: false})}>
                                    <InputLabel
                                        id="city-simple-select-outlined-label">{isEnglish ? 'City Name' : '市区町村名'}</InputLabel>
                                    <Select
                                        labelId="city-simple-select-outlined-label"
                                        id="city-simple-select-outlined"
                                        value={editedProfile.city_name}
                                        onChange={handleInputChange()}
                                        label={isEnglish ? 'City Name' : '市区町村名'}
                                        name="city_name"
                                        margin="normal"
                                    >
                                        <MenuItem
                                            value={isEnglish ? 'Kawasaki-shi Kawasaki' : '川崎市 川崎区'}>{isEnglish ? 'Kawasaki-shi Kawasaki' : '川崎市 川崎区'}</MenuItem>
                                        <MenuItem
                                            value={isEnglish ? 'Kawasaki-shi Saiwai' : '川崎市 幸区'}>{isEnglish ? 'Kawasaki-shi Saiwai' : '川崎市 幸区'}</MenuItem>
                                        <MenuItem
                                            value={isEnglish ? 'Kawasaki-shi Nakahara' : '川崎市 中原区'}>{isEnglish ? 'Kawasaki-shi Nakahara' : '川崎市 中原区'}</MenuItem>
                                        <MenuItem
                                            value={isEnglish ? 'Yokohama-shi Kohoku' : '横浜市 港北区'}>{isEnglish ? 'Yokohama-shi Kohoku' : '横浜市 港北区'}</MenuItem>
                                        <MenuItem
                                            value={isEnglish ? 'Yokohama-shi Tsurumi' : '横浜市 鶴見区'}>{isEnglish ? 'Yokohama-shi Tsurumi' : '横浜市 鶴見区'}</MenuItem>
                                    </Select>
                                </FormControl>}

                                <TextField
                                    variant="standard" margin="normal"
                                    fullWidth
                                    label={isEnglish ? 'Place Name and Address' : '地名・番地'}
                                    name="place_number"
                                    defaultValue={myProfile.place_number}
                                    onChange={handleInputChange()}
                                />
                                <TextField
                                    variant="standard" margin="normal"
                                    fullWidth
                                    label={isEnglish ? 'Building name, floor and room number' : '建物名・階・部屋番号'}
                                    name="building_floor_room_name"
                                    defaultValue={myProfile.building_floor_room_name}
                                    onChange={handleInputChange()}
                                />

                                <TextField
                                    variant="standard" margin="normal"
                                    fullWidth
                                    label={isEnglish ? LABEL_PHONE_NUMBER : '電話番号'}
                                    name="phone_number"
                                    defaultValue={myProfile.phone_number}
                                    onChange={handleInputChange()}
                                />

                            </div>
                            :
                            <DisplayProfile myProfile={myProfile}/>
                    )
                    :
                    <div>
                        <CircularProgress/>
                    </div>
                }

                {isEditMode ?
                    < Button className={classes.submit}
                             onClick={() => editProfile()}
                             type="submit"
                             fullWidth
                             variant="contained"
                             disabled={!isValidCombination(editedProfile.prefecture, editedProfile.city_name)}
                             color="primary">{isEnglish ? BUTTON_UPDATE : '更新'}
                    </Button>

                    :
                    <Button className={classes.submit}
                            onClick={() => setIsEditMode(!isEditMode)}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary">{isEnglish ? BUTTON_EDIT : '編集'}
                    </Button>
                }
            </div>

            <Grid container className={classes.back}>
                <Grid item xs>
                    <Link href={isEnglish ? '/my_page_e' : '/my_page'} variant="body2" color='textSecondary'>
                        {isEnglish ? BACK : '戻る'}
                    </Link>
                </Grid>
            </Grid>
        </Container>
    )
}

export default withCookies(Profile)
