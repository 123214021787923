import React, {useEffect} from "react";
import Footer from "../Footer";
import NavBar from "../NavBar/NavBar";


const NotFoundPage = () => {
    useEffect(() => {
        window.location.href = '/'
    }, [])
    return (

        <div className='pageWrapper'>
            <NavBar/>
            <Footer/>
        </div>
    )
}


export default NotFoundPage