import React, {useContext, useEffect, useState} from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {CostcoContext} from "./CostcoHome";
import Typography from "@material-ui/core/Typography";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';


const CategoryDrawer = () => {
    const {
        categories,
        filterQuery,
        setFilterQuery,
        setOffset,
        products,
        classes,
        token,
        setLoginDialogOpen
    } = useContext(CostcoContext)
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'
    // const {isEnglish} = useContext(LanguageContext)
    const [isOpen, setIsOpen] = useState(false)

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsOpen(open)
    }

    const handleCategoryFilter = (categoryId) => {
        setOffset(0)
        setFilterQuery({...filterQuery, "category": categoryId})
    }

    const handleLoginAlert = () => {
        if (!token) {
            setLoginDialogOpen(true);
        }
    }

    const numberOfProductsInCategory = (categoryId) => {
        return (products.filter(product => product.category === categoryId).length)
    }

    useEffect(() => {
        handleCategoryFilter()
    }, [products])
    const list = () => (
        <div
            role='presentation'
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List className={classes.categoryList}>
                <ListItem button onClick={() => {
                    !token ? handleLoginAlert() : handleCategoryFilter()
                }}>
                    <ListItemText>
                        <Typography variant='body2'>{isEnglish ? 'All Categories' : '全てのカテゴリー'}</Typography>
                    </ListItemText>
                    <ListItemSecondaryAction>
                        <Typography variant='body2'>({products.length})</Typography>
                    </ListItemSecondaryAction>
                </ListItem>
                {categories.map((category) => {
                    return (
                        <ListItem button onClick={() => !token ? handleLoginAlert() : handleCategoryFilter(category.id)}
                                  key={category.id}>
                            <ListItemText>
                                <Typography
                                    variant='body2'>{isEnglish ? category.english_name : category.name}</Typography>
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <Typography variant='body2'>({numberOfProductsInCategory(category.id)})</Typography>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                })}
            </List>
        </div>
    )
    return (
        <div className={classes.categoryDrawer}>
            <IconButton onClick={toggleDrawer(true)}>
                <MenuIcon/>
            </IconButton>

            <SwipeableDrawer
                anchor='left'
                open={isOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                {list()}
            </SwipeableDrawer>
        </div>
    )
}

export default CategoryDrawer