import React, {useState} from "react";
import Footer from "../Footer";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import axios from "axios";
import {PASSWORD_RESET_CONFIRM_URL} from "../../Messages/urls";
import NavBar from "../NavBar/NavBar";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    span: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'teal',
    },
    spanError: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'fuchsia',
        marginTop: 10,
    },
    margin: {
        margin: theme.spacing(1),
    },
    titleImg: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
    },
}))

const PasswordReset = (props) => {
    const classes = useStyles()
    // const {isEnglish} = useContext(LanguageContext)
    const urlParam = props.location.search.substring(1).split('?')
    const token = urlParam[0]
    const email = urlParam[1]
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const isMatchPassword = password === confirmPassword
    const isFill = !password || !confirmPassword
    const unCreatable = isFill || !isMatchPassword

    const title_eng = 'images/title_password_reset_eng.jpeg'

    const inputChangePassword = () => event => {
        setPassword(event.target.value)
    }
    const inputChangeConfirmPassword = () => event => {
        setConfirmPassword(event.target.value)
    }

    const resetPassword = async (event) => {
        event.preventDefault()
        const data = {'password': password, 'token': token}
        try {
            const res = await axios.post(PASSWORD_RESET_CONFIRM_URL, data,
                {headers: {'Content-Type': 'application/json'}})
            console.log(res.data)
            // ログインしてホームに遷移
            window.location.href = '/login'
        } catch {
            console.log('error')
        }

    }

    return (
        <div className='pageWrapper'>
             <NavBar/>
            <Container maxWidth='xs'>

                <form onSubmit={resetPassword}>
                    <div className={classes.paper}>
                        <img src={title_eng} alt="title" className={classes.titleImg}/>
                        <FormControl
                            className={classes.textField}
                            variant="standard"
                            fullWidth
                        >
                            <TextField
                                variant="standard" margin="normal"
                                fullWidth
                                label='Email'
                                name="email"
                                disabled
                                value={email}
                                style={{marginTop: '0'}}
                            />
                        </FormControl>

                        <FormControl
                            className={clsx(classes.margin, classes.textField)}
                            variant="outlined"
                            fullWidth
                        >
                            <InputLabel htmlFor="outlined-adornment-password">
                                New Password
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                name="password"
                                label='Password'
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={inputChangePassword()}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            // onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                        <FormControl
                            className={clsx(classes.margin, classes.textField)}
                            variant="outlined"
                            fullWidth
                        >
                            <InputLabel htmlFor="outlined-adornment-confirm-password">
                                Confirm Password
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-confirm-password"
                                label='Confirm Password'
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={inputChangeConfirmPassword()}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            // onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <Button
                            className={classes.submit}
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            disabled={unCreatable}
                        >Reset Password</Button>
                    </div>
                </form>
            </Container>
            <Footer/>
        </div>

    )
}

export default PasswordReset