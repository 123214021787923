import {
    START_FETCH,
    FETCH_SUCCESS,
    ERROR_CATCHED,
    INPUT_EDIT,
} from "../Messages/actions";


const loginReducer = (state, action) => {
    switch (action.type) {
        case START_FETCH: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case FETCH_SUCCESS: {
            return {
                ...state,
                isLoading: false
            }
        }
        case ERROR_CATCHED: {
            return {
                ...state,
                error: 'メールアドレスかパスワードが正しくありません / Email or password is not correct!',
                isLoading: false
            }
        }
        case INPUT_EDIT: {
            return {
                ...state,
                [action.inputName]: action.payload,
                error: '',
            }
        }
        default:
            return state;
    }
}

export default loginReducer