import React from "react";
import NavBar from "../NavBar/NavBar";
import Container from "@material-ui/core/Container";
import '../styles/privacyPolicy.css'
import Footer from "../Footer";
import Hidden from "@material-ui/core/Hidden";

const UsageContractPage = () => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'
    // const {isEnglish} = useContext(LanguageContext)
    return (
        <div className='pageWrapper'>
            <NavBar/>
            <Container
                className='wrapper'
                style={{marginBottom: '20px'}}
            >
                <div className='spViewInner'>
                    <Hidden smUp>
                        <h3 style={{textAlign: 'center'}}>
                            {isEnglish ? 'Terms of Service' : '利用規約'}
                        </h3>
                    </Hidden>

                    {/*iPadサイズ*/}
                    <Hidden xsDown lgUp>
                        <h2 style={{textAlign: 'center'}}>
                            {isEnglish ? 'Terms of Service' : '利用規約'}
                        </h2>
                    </Hidden>

                    {/*PCサイズ*/}
                    <Hidden mdDown>
                        <h1 style={{textAlign: 'center'}}>
                            {isEnglish ? 'Terms of Service' : '利用規約'}
                        </h1>
                    </Hidden>

                    <p className="text dotline">
                        {isEnglish ?
                            'Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the https://husky-cart.com\n' +
                            'Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.' +
                            'By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.'
                            :
                            'この利用規約（以下，「本規約」といいます。）は， Huskycart（以下，「当社」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。'
                        }
                    </p>
                    {isEnglish ?
                        <dl className='privacyList'>
                            <dt>Termination</dt>
                            <dd><p className='text'>
                                We may terminate or suspend access to our Service immediately, without prior notice or
                                liability, for any reason whatsoever, including without limitation if you breach the
                                Terms.
                                All provisions of the Terms which by their nature should survive termination shall
                                survive termination, including, without limitation, ownership provisions, warranty
                                disclaimers, indemnity and limitations of liability.
                            </p></dd>

                            <dt>Content</dt>
                            <dd><p className='text'>
                                Our Service allows you to post, link, store, share and otherwise make available certain
                                information, text, graphics, videos, or other material ("Content").
                            </p></dd>

                            <dt>Links To Other Web Sites</dt>
                            <dd><p className='text'>
                                Our Service may contain links to third-party web sites or services that are not owned or
                                controlled by My Company (change this).
                                My Company (change this) has no control over, and assumes no responsibility for, the
                                content, privacy policies, or practices of any third party web sites or services. You
                                further acknowledge and agree that My Company (change this) shall not be responsible or
                                liable, directly or indirectly, for any damage or loss caused or alleged to be caused by
                                or in connection with use of or reliance on any such content, goods or services
                                available on or through any such web sites or services.
                            </p></dd>

                            <dt>Price</dt>
                            <dd><p className='text'>
                                The price displayed on this website is the Huskycart price so it differs from the store
                                price.
                            </p></dd>

                            <dt>Out of Stock Products</dt>
                            <dd><p className='text'>
                                    It is possible that some items may be out of stock depending on the condition of the store on that day. Please note that we are unable to change the date of your order. If you choose to ask for an alternative item and we are unable to reach you, we will cancel the item.
                            </p></dd>

                            <dt>Contact Us</dt>
                            <dd><p className='text'>
                                If you have any questions about these Terms, please contact us.
                            </p></dd>

                        </dl> :
                        <dl className='privacyList'>
                            <dt>第1条（適用）</dt>
                            <dd>
                                <ol>
                                    <li>本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。</li>
                                    <li>当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。</li>
                                    <li>本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。</li>
                                </ol>
                            </dd>
                            <dt>第2条（利用登録）</dt>
                            <dd>
                                <ol>
                                    <li>本サービスにおいては，登録希望者が本規約に同意の上，当社の定める方法によって利用登録を申請し，当社がこれを承認することによって，利用登録が完了するものとします。</li>
                                    <li>当社は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。</li>
                                    <li>利用登録の申請に際して虚偽の事項を届け出た場合</li>
                                    <li>本規約に違反したことがある者からの申請である場合</li>
                                    <li>その他，当社が利用登録を相当でないと判断した場合</li>
                                </ol>
                            </dd>
                            <dt>第3条（ユーザーIDおよびパスワードの管理）</dt>
                            <dd>
                                <ol>
                                    <li>ユーザーは，自己の責任において，本サービスのユーザーIDおよびパスワードを適切に管理するものとします。</li>
                                    <li>ユーザーは，いかなる場合にも，ユーザーIDおよびパスワードを第三者に譲渡または貸与し，もしくは第三者と共用することはできません。当社は，ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には，そのユーザーIDを登録しているユーザー自身による利用とみなします。</li>
                                    <li>ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は，当社に故意又は重大な過失がある場合を除き，当社は一切の責任を負わないものとします。</li>
                                </ol>
                            </dd>
                            <dt>第4条（利用料金および支払方法）</dt>
                            <dd>
                                <ol>
                                    <li>ユーザーは，本サービスの有料部分の対価として，当社が別途定め，本ウェブサイトに表示する利用料金を，当社が指定する方法により支払うものとします。</li>
                                    <li>ユーザーが利用料金の支払を遅滞した場合には，ユーザーは年14．6％の割合による遅延損害金を支払うものとします。</li>
                                </ol>
                            </dd>
                            <dt>第5条（禁止事項）</dt>
                            <dd>
                                <p className='text'>ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。</p>
                                <ol>
                                    <li>法令または公序良俗に違反する行為</li>
                                    <li>犯罪行為に関連する行為</li>
                                    <li>本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為</li>
                                    <li>当社，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為</li>
                                    <li>本サービスによって得られた情報を商業的に利用する行為</li>
                                    <li>当社のサービスの運営を妨害するおそれのある行為</li>
                                    <li>不正アクセスをし，またはこれを試みる行為</li>
                                    <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
                                    <li>不正な目的を持って本サービスを利用する行為</li>
                                    <li>本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為</li>
                                    <li>他のユーザーに成りすます行為</li>
                                    <li>当社が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為</li>
                                    <li>面識のない異性との出会いを目的とした行為</li>
                                    <li>当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為</li>
                                    <li>その他，当社が不適切と判断する行為</li>
                                </ol>
                            </dd>
                            <dt>第6条（表示価格）</dt>
                            <dd>
                                <p className="text"> 本webサイトに表示されている価格はHuskycart価格の為、実際の店頭価格とは異なります。</p>
                            </dd>
                            <dt>第7条 在庫切れ商品について</dt>
                            <dd>
                                <p className='text'>当日の店舗状況により、在庫切れ商品がある可能性がございます。また買い物代行日の変更は受付られませんのでご了承ください。代替品希望(要連絡)を選択され、連絡が繋がらない場合は商品をキャンセルとさせていただきます。</p>
                            </dd>

                            <dt>第8条（本サービスの提供の停止等）</dt>
                            <dd>
                                <ol>
                                    <li>当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。</li>
                                    <li>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</li>
                                    <li>地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合</li>
                                    <li>コンピュータまたは通信回線等が事故により停止した場合</li>
                                    <li>その他，当社が本サービスの提供が困難と判断した場合</li>
                                    <li>当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。</li>
                                </ol>
                            </dd>

                            <dt>第9条（利用制限および登録抹消）</dt>
                            <dd>
                                <ol>
                                    <li>当社は，ユーザーが以下のいずれかに該当する場合には，事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限し，またはユーザーとしての登録を抹消することができるものとします。</li>
                                    <li>本規約のいずれかの条項に違反した場合</li>
                                    <li>登録事項に虚偽の事実があることが判明した場合</li>
                                    <li>料金等の支払債務の不履行があった場合</li>
                                    <li>当社からの連絡に対し，一定期間返答がない場合</li>
                                    <li>本サービスについて，最終の利用から一定期間利用がない場合</li>
                                    <li>その他，当社が本サービスの利用を適当でないと判断した場合</li>
                                    <li>当社は，本条に基づき当社が行った行為によりユーザーに生じた損害について，一切の責任を負いません。</li>
                                </ol>
                            </dd>
                            <dt>第10条（退会）</dt>
                            <dd>
                                <p className="text">ユーザーは，当社の定める退会手続により，本サービスから退会できるものとします。</p>
                            </dd>
                            <dt>第11条（保証の否認および免責事項）</dt>
                            <dd>
                                <ul>
                                    <li>当社は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。</li>
                                    <li>当社は，本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし，本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。</li>
                                    <li>前項ただし書に定める場合であっても，当社は，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。また，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は，ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。</li>
                                    <li>当社は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。</li>
                                </ul>
                            </dd>
                            <dt>第12条（サービス内容の変更等）</dt>
                            <dd>
                                <p className="text">当社は，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。</p>
                            </dd>
                            <dt>第13条（利用規約の変更）</dt>
                            <dd>
                                <p className="text">当社は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。</p>
                            </dd>
                            <dt>第14条（個人情報の取扱い）</dt>
                            <dd>
                                <p className="text">当社は，本サービスの利用によって取得する個人情報については，当社「プライバシーポリシー」に従い適切に取り扱うものとします。</p>
                            </dd>
                            <dt>第15条（通知または連絡）</dt>
                            <dd>
                                <p className="text">ユーザーと当社との間の通知または連絡は，当社の定める方法によって行うものとします。当社は,ユーザーから,当社が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。</p>
                            </dd>
                            <dt>第16条（権利義務の譲渡の禁止）</dt>
                            <dd>
                                <p className="text">ユーザーは，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。</p>
                            </dd>
                            <dt>第17条（準拠法・裁判管轄）</dt>
                            <dd>
                                <ol>
                                    <li>本規約の解釈にあたっては，日本法を準拠法とします。</li>
                                    <li>本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄とします。</li>
                                </ol>
                            </dd>
                        </dl>
                    }
                </div>
            </Container>
            <Footer/>
        </div>
    )
}

export default UsageContractPage