import React, {useEffect, useState} from "react";
import axios from "axios";
import {COSTCO_PRODUCT_URL} from "../../Messages/urls";


const ReceiptOrderList = (props) => {

    const {isEnglish, firstOrderItem, cartItems, isOff} = props
    const [products, setProducts] = useState()
    const list = []

    const tenPercentOff = price => {
        // 0.9倍して1の位切り捨て
        return Math.floor(price * 0.9 * 0.1) * 10
    }

    useEffect(() => {
        axios.get(COSTCO_PRODUCT_URL)
            .then(res => {
                setProducts(res.data)
            })
    }, [])

    if (products) {
        for (let index in firstOrderItem) {
            let cartId = firstOrderItem[index]
            for (let cartItemKey in cartItems) {
                if (cartId === cartItems[cartItemKey].id) {
                    const productId = cartItems[cartItemKey].product
                    const targetProduct = products.filter(product => (product.id === Number(productId)))[0]
                    list.push(
                        <tr key={targetProduct.id}>
                            <td colSpan="1"
                                valign="top">
                                {isEnglish ? targetProduct.english_name : targetProduct.name} × {cartItems[cartItemKey].quantity}
                                <br/>
                            </td>
                            <td align="right"
                                valign="top"
                                colSpan="2">
                                ¥ {isOff ?
                                    tenPercentOff(targetProduct.marked_up_price) * cartItems[cartItemKey].quantity
                                    :
                                    targetProduct.marked_up_price * cartItems[cartItemKey].quantity
                                }
                                {/*¥ {targetProduct.marked_up_price * cartItems[cartItemKey].quantity}*/}
                                <br/>
                            </td>
                        </tr>
                    )
                }
            }
        }
    }

    return (
        <>
            {list}
        </>
    )
}

export default ReceiptOrderList