import React, {useEffect} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {withCookies} from 'react-cookie';
import Hidden from '@material-ui/core/Hidden';
import {removeQuery} from "../utils/func";
import {useLocation,} from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: '2rem', paddingTop: '3rem'
    },
    shoppingButton: {
        height: '70px',
        [theme.breakpoints.up('lg')]: {
            height: '100px'
        },
    },
    ShoppingButtonP: {
        fontSize: '16px', lineHeight: '70px',
        [theme.breakpoints.up('lg')]: {
            lineHeight: '100px',
            fontSize: '18px',
        },
        '&:hover': {
            backgroundColor: '#ff5722',
        },
    },
    shoppingButtonLg: {
        textAlign: 'center',
        fontWeight: 'bold',
        height: '60px',
        lineHeight: '60px',
        width: '100%',
        backgroundColor: '#48d1cc',
        color: 'white',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#ff5722',
        }
    }

}))


const UpperTop = (props) => {
    const classes = useStyles()
    const location = useLocation().search;

    const query = new URLSearchParams(location);
    const rdCode = query.get('rd_code')

    useEffect(() => {
        if (rdCode) {
            sessionStorage.setItem('rd_code', rdCode);
            console.log('save', rdCode)
        }
    }, [])

    // const currentUrl = window.location.href
    const currentUrl = removeQuery(window.location.href)
    const isEnglish = currentUrl.slice(-2) === '/e' || currentUrl.slice(-2) === '_e'

    const token = props.cookies.get('current-token')
    // 画像のパスを定義する

    // Top
    // const TopLgJp = 'images/top_lg_jp.jpeg'
    // const TopLgEng = 'images/top_lg_eng.jpeg'
    // const TopMdJp = 'images/top_md_jp.jpeg'
    // const TopMdEng = 'images/top_md_eng.jpeg'
    // const TopXsJp = 'images/top_xs_jp.jpeg'
    // const TopXsEng = 'images/top_xs_eng.jpeg'

    // const topXs = 'images/top_xs.jpeg'
    const topMd = 'images/top_lg.jpeg'
    const topLg = 'images/top_lg.jpeg'
    // const topLg = 'images/top_lg_.jpg'
    // const topXs = 'images/top-japanese.jpeg'
    const topXsJp = 'images/top1.jpeg'
    const topXsEng = 'images/top1eng.jpeg'
    const top2XsJp = 'images/top2jp.jpeg'
    const top2XsEng = 'images/top2eng.jpeg'
    const top3XsJp = 'images/top3jp.jpeg'
    const top3XsEng = 'images/top3eng.jpeg'
    const top4XsJp = 'images/top4jp.jpeg'
    const top4XsEng = 'images/top4eng.jpeg'
    const top5XsJp = 'images/top5jp.jpeg'
    const top5XsEng = 'images/top5eng.jpeg'

    const topLgJp1 = 'images/top_lg_jp_1.jpeg'
    const topLgJp2 = 'images/top_lg_jp_2.jpeg'
    const topLgJp3 = 'images/top_lg_jp_3.jpeg'
    const topLgJp4 = 'images/top_lg_jp_4.jpeg'
    const topLgJp5 = 'images/top_lg_jp_5.jpeg'
    const topLgEng1 = 'images/top_lg_eng_1.jpeg'
    const topLgEng2 = 'images/top_lg_eng_2.jpeg'
    const topLgEng3 = 'images/top_lg_eng_3.jpeg'
    const topLgEng4 = 'images/top_lg_eng_4.jpeg'
    const topLgEng5 = 'images/top_lg_eng_5.jpeg'


    return (<div style={{background: '#ffffff', marginTop: 0}}>

        {/*スマホサイズ*/}
        <Hidden lgUp>
            {/*topImage*/}
            <div className='top-image-wrapper'
                 // style={{pointerEvents: 'none'}}
            >
                <h1 style={{margin: 0, padding: 0}}>
                    <img src={isEnglish ? topXsEng : topXsJp} alt='コストコ買い物代行サービス' width='100%'/>
                </h1>

                <p style={{
                    // padding: '1rem',
                    whiteSpace: 'pre-wrap',
                    textAlign: 'center',
                    fontSize: '18px',
                    margin: '28px 0 28px 0',
                    lineHeight: '32px',
                }}>
                    {isEnglish ?
                        <>
                            <span>{"Huskycart items are purchased at\nthe "}</span>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    background: 'linear-gradient(transparent 70%,#FFF9C4 0%)'
                                }}>
                                {"very popular membership\nsupermarket"}
                            </span>
                            <span>{"『"}</span>
                            <span style={{fontWeight: 'bold', color: 'rgb(229, 21, 50)'}}>
                                {"Costco"}
                            </span>
                            <span>{"』"}</span>
                        </>
                        :
                        <>
                            <span>{"ハスキーカートの商品は"}</span>
                            <span
                                style={{fontWeight: 'bold', color: 'rgb(229, 21, 50)'}}
                                // style={{
                                //     fontWeight: 'bold',
                                //     background: 'linear-gradient(transparent 70%,#FFF9C4 0%)'
                                // }}
                            >
                                {"大人気\n"}
                            </span>
                            <span>{"の"}</span>
                            <span
                                style={{
                                    fontWeight: 'bold', background: 'linear-gradient(transparent 70%,#FFF9C4 0%)'
                                }}>
                                {"会員制スーパーマーケット\n"}
                            </span>
                            <span>{"『"}</span>
                            <span style={{fontWeight: 'bold'}}>
                                <a
                                    style={{color: 'blue'}}
                                    href="https://costco-johokan.com/costco-news/guide/what-is-costco/" target='_blank'
                                >
                                {"コストコ"}
                            </a>
                            </span>
                            <span>{"』"}</span>
                            <span>{"で購入しています！"}</span>
                        </>
                    }
                </p>
                <div style={{height: '46px', backgroundColor: '#EBEBEB'}}/>

                <h2 style={{margin: 0, padding: 0}}>
                    <img style={{verticalAlign: 'bottom'}} src={isEnglish ? top2XsEng : top2XsJp}
                         alt='対応エリア・手数料・支払い方法' width='100%'/>
                </h2>
                <div style={{height: '40px', backgroundColor: '#EBEBEB'}}/>
                <h2 style={{textAlign: 'center', margin: '50px 0 20px 0', fontSize: '22px'}}>
                    {isEnglish ? "How to use" : "ご利用方法"}
                </h2>
                <img style={{verticalAlign: 'bottom'}} src={isEnglish ? top3XsEng : top3XsJp} alt='ご利用方法'
                     width='100%'/>
                <img style={{verticalAlign: 'bottom'}} src={isEnglish ? top4XsEng : top4XsJp} alt='あとは商品が届くのを待つだけ'
                     width='100%'/>
                <h2 style={{margin: 0, padding: 0}}>
                    <img src={isEnglish ? top5XsEng : top5XsJp}
                         alt='ハスキーカートの特徴'
                         width='100%'/>
                </h2>
            </div>
        </Hidden>

        {/*PCサイズ*/}
        <Hidden mdDown>
            <div
                // style={{pointerEvents: 'none'}}
            >
                <h1 style={{margin: 0, padding: 0}}>
                    <img style={{verticalAlign: 'bottom'}} src={isEnglish ? topLgEng1 : topLgJp1}
                         alt="コストコ買い物代行サービス"
                         width='100%'/>
                </h1>
                <p style={{
                    padding: '1rem',
                    whiteSpace: 'pre-wrap',
                    textAlign: 'center',
                    fontSize: '18px',
                    margin: '40px 0 40px 0',
                    lineHeight: '30px',
                }}>
                    {isEnglish ?
                        <>
                            <span>{"Huskycart items are purchased at the "}</span>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    background: 'linear-gradient(transparent 70%,#FFF9C4 0%)'
                                }}>
                                {"very popular membership supermarket"}
                            </span>
                            <span>{"『"}</span>
                            <span style={{fontWeight: 'bold', color: 'rgb(229, 21, 50)'}}>
                                {"Costco"}
                            </span>
                            <span>{"』"}</span>
                        </>
                        :
                        <>
                            <span>{"ハスキーカートの商品は"}</span>
                            <span
                                style={{fontWeight: 'bold', color: 'rgb(229, 21, 50)'}}
                                // style={{
                                //     fontWeight: 'bold',
                                //     background: 'linear-gradient(transparent 70%,#FFF9C4 0%)'
                                // }}
                            >
                                {"大人気"}
                            </span>
                            <span>{"の"}</span>
                            <span
                                style={{
                                    fontWeight: 'bold', background: 'linear-gradient(transparent 70%,#FFF9C4 0%)'
                                }}>
                                {"会員制スーパーマーケット"}
                            </span>
                            <span>{"『"}</span>
                            <span style={{fontWeight: 'bold'}}>
                                <a
                                    style={{color: 'blue'}}
                                    href="https://costco-johokan.com/costco-news/guide/what-is-costco/" target="_blank">
                                {"コストコ"}
                                </a>
                            </span>
                            <span>{"』"}</span>
                            <span>{"で購入しています！"}</span>
                        </>
                    }
                </p>
                <div style={{height: '30px', backgroundColor: '#EBEBEB'}}/>
                <h2 style={{margin: 0, padding: 0}}>
                    <img style={{verticalAlign: 'bottom'}} src={isEnglish ? topLgEng2 : topLgJp2}
                         alt="対応エリア・手数料・支払い方法"
                         width='100%'/>
                </h2>
                <div style={{height: '40px', backgroundColor: '#EBEBEB'}}/>
                <h2 style={{textAlign: 'center', margin: '60px 0 20px 0', fontSize: '24px'}}>
                    {isEnglish ? "How to use" : "ご利用方法"}
                </h2>
                <h2 style={{margin: 0, padding: 0}}>
                    <img style={{verticalAlign: 'bottom'}} src={isEnglish ? topLgEng3 : topLgJp3} alt="ご利用方法"
                         width='100%'/>
                </h2>
                <h2 style={{margin: 0, padding: 0}}>
                    <img style={{verticalAlign: 'bottom'}} src={isEnglish ? topLgEng4 : topLgJp4}
                         alt="あとは商品が届くのを待つだけ"
                         width='100%'/>
                </h2>
                <h2 style={{margin: 0, padding: 0}}>
                    <img style={{verticalAlign: 'bottom'}} src={isEnglish ? topLgEng5 : topLgJp5} alt="ハスキーカートの特徴"
                         width='100%'/>
                </h2>
                <div style={{height: '60px', backgroundColor: '#FFF'}}/>
            </div>
        </Hidden>

        {/*コストコに飛ばすボタン*/}
        <div
            className={classes.shoppingButton}
            id='go-shop-btn'
            style={{
                width: 'calc(100% - 10px)',
                backgroundColor: '#48d1cc',
                color: 'white',
                position: 'fixed',
                bottom: '5px',
                marginLeft: '5px',
                marginRight: '5px',
                cursor: 'pointer',
            }}
            onClick={() => {
                isEnglish ? window.location.href = '/costco_e' : window.location.href = '/costco'
            }}
        >
            {isEnglish ?
                <p
                    className={classes.ShoppingButtonP}
                    style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: 0,
                        marginBottom: 0,
                    }}>
                    Let's shopping
                </p>
                :
                <p
                    className={classes.ShoppingButtonP}
                    style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: 0,
                        marginBottom: 0
                    }}>
                    ご注文はこちら
                </p>}

        </div>
    </div>)
}

export default withCookies(UpperTop)