import React from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer";
import PurchaseHistory from "../Profile/PurchaseHistory";

const PurchaseHistoryPage = () => {
    return (
        <div className='pageWrapper'>
            <NavBar/>
            <PurchaseHistory/>
            <Footer/>
        </div>
    )
}

export default PurchaseHistoryPage