import React from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer";
import CostcoOrderSummary from "../Profile/CostcoOrderSummary";


const CostcoOrderSummaryPage = () => {

    return (
        <div className='pageWrapper'>
            <NavBar/>
            <CostcoOrderSummary/>
            <Footer/>
        </div>
    )
}

export default CostcoOrderSummaryPage