import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import DisplayCartItem from "./DisplayCartItem";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.primary
    },
    container: {
        margin: "auto",
    },
    back: {
        padding: 0,
        margin: 0,
    },
    titleImg: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    submit: {
        margin: theme.spacing(0, 0, 2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    topLine: {
        borderTop: 'solid 1px #e0e0e0',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        paddingBottom: '0.5rem'
    },
    content: {
        paddingLeft: '1rem'
    },
    title: {
        marginTop: theme.spacing(1),
    }
}));

const OrderDetails = (props) => {
    const classes = useStyles()
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '/e' || currentUrl.slice(-2) === '_e'
    const {firstOrder, orderId, cartItems} = props
    const [order, setOrder] = useState()

    const tipValue2Str = (value) => {
        switch (value) {
            case 0:
                return isEnglish ? 'none' : 'なし'
            case 5:
                return '5%'
            case 10:
                return '10%'
            case 15:
                return '15%'
            case 20:
                return '20%'
            default:
                return 'value error'
        }
    }


    useEffect(() => {
        for (const key in firstOrder) {
            if (firstOrder[key].id === orderId) {
                setOrder(firstOrder[key])
            }
        }
    }, [firstOrder, orderId])

    return (
        <>
            {order ?
                <Container maxWidth='md' style={{padding: '0', backgroundColor: '#f6f6f6'}}>

                    <DisplayCartItem cartItemArray={order.cart_item} cartItems={cartItems}
                                     isOff={order.memo.includes('10%off')}/>

                    <Grid container>
                        <Grid item container direction="column" xs={12} className={classes.topLine}
                              style={{border: 'none'}}>
                            <Typography variant="h6" gutterBottom className={classes.title}>
                                {isEnglish ? "Price" : "金額"}
                            </Typography>
                            <Grid container className={classes.content}>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>{isEnglish ? 'Products' : '商品代金'}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>¥{order.products_price}</Typography>
                                </Grid>

                                {order.shopping_fee > 250 ?
                                    <>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom>{isEnglish ? 'Fee' : '手数料'}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom>¥{order.shopping_fee}</Typography>
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom>{isEnglish ? 'Shopping fee' : '代行料'}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom>¥{order.shopping_fee}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom>{isEnglish ? 'Delivery fee' : '配送料'}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom>¥{order.delivery_fee}</Typography>
                                        </Grid>
                                    </>
                                }

                                <Grid item xs={6}>
                                    <Typography gutterBottom>{isEnglish ? 'Tip' : 'チップ'}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        gutterBottom>¥{(Math.floor(order.tip * order.products_price * 0.001) * 10)} ({tipValue2Str(order.tip)})</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>{isEnglish ? 'Discount' : '割引'}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>-¥{order.coupon_price}</Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography gutterBottom>{isEnglish ? 'Total' : '合計'}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>¥{order.total}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" xs={12} className={classes.topLine}>
                            <Typography variant="h6" gutterBottom className={classes.title}>
                                {isEnglish ? "Delivery address" : "配達住所"}
                            </Typography>
                            <Grid container className={classes.content}>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>{isEnglish ? 'Name' : '氏名'}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>{order.name}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>{isEnglish ? 'Address' : '住所'}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>
                                        {order.address}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>{isEnglish ? 'Phone number' : '電話番号'}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>{order.phone_number}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" xs={12} className={classes.topLine}>
                            <Typography variant="h6" gutterBottom className={classes.title}>
                                {isEnglish ? "Delivery time" : "配達日時"}
                            </Typography>
                            <Grid container className={classes.content}>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>{isEnglish ? 'Date' : '日付'}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>{order.delivery_date.slice(0, 5)}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>{isEnglish ? 'Delivery time' : '時間'}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>{order.delivery_date.slice(5)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item container direction="column" xs={12} className={classes.topLine}
                              style={{borderBottom: 'solid 1px #e0e0e0'}}>
                            <Typography variant="h6" gutterBottom className={classes.title}>
                                {isEnglish ? "ETC" : "その他"}
                            </Typography>
                            <Grid container className={classes.content}>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>{isEnglish ? 'Payment method' : '決済方法'}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>{order.payment_method}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        gutterBottom>{isEnglish ? 'If out of stock item' : '在庫切れ対応'}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>{order.cancel}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>{isEnglish ? 'Memo' : 'メモ'}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>{order.memo}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                :
                'loading'
            }
        </>
    )
}

export default OrderDetails