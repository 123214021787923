import React from "react";
import {withCookies} from 'react-cookie';
import Login from "../Login";
import Footer from "../Footer";
import NavBar from "../NavBar/NavBar";


const LoginPage = () => {

    return (
        <div className='pageWrapper'>
            <NavBar/>
            <Login/>
            <Footer/>
        </div>
    )
}

export default withCookies(LoginPage)
