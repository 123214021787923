import React, {useEffect, useState} from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useWindowDimensions} from "../../utils/useWindowDimensions";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";


const useStyles = makeStyles((theme) => ({
    reviewImage: {
        maxWidth: 50,
        maxHeight: 50
    }
}))

const CostcoReviewItems = (props) => {
    const {cart, products} = props
    const classes = useStyles()
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'
    const dict = {}
    const list = []

    const {width} = useWindowDimensions()
    const [size, setSize] = useState('')
    useEffect(() => {
        if (width >= 1920) {
            setSize('xl')
        } else if (width >= 1280) {
            setSize('lg')
        } else if (width >= 960) {
            setSize('md')
        } else if (width >= 600) {
            setSize('sm')
        } else {
            setSize('xs')
        }
    }, [width, size])

    for (const product of cart) {
        const id = product.id
        if (dict[id]) {
            dict[id] += 1
        } else {
            dict[id] = 1
        }
    }
    if (size !== 'xs') {
        // スマホサイズではない
        for (let key in dict) {
            const targetProduct = products.filter(product => (product.id === Number(key)))
            list.push(
                <TableRow key={key}>
                    <TableCell component='th' scope='row'>
                        <img src={targetProduct[0].image.replace('http', 'https')} alt={targetProduct.name}
                             className={classes.reviewImage}/>
                    </TableCell>
                    <TableCell>{isEnglish ? targetProduct[0].english_name : targetProduct[0].name}</TableCell>
                    <TableCell>¥{Math.ceil(targetProduct[0].marked_up_price)}</TableCell>
                    <TableCell>{isEnglish ? 'quantity: ': '個数: '}{dict[key]}</TableCell>
                    <TableCell>¥{Math.ceil(targetProduct[0].marked_up_price * dict[key])}</TableCell>
                </TableRow>
            )
        }
    } else {
        // スマホサイズ
        for (let key in dict) {
            const targetProduct = products.filter(product => (product.id === Number(key)))
            list.push(
                <Grid item xs={12} key={key}>
                    <Card>
                        <CardActionArea>
                            <Grid container>
                                <Grid item xs={4}>
                                    <CardMedia
                                        image={targetProduct[0].image.replace('http', 'https')}
                                        title={targetProduct[0].name}
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            display: 'inline-block',
                                            marginLeft: '1rem',
                                            marginTop: '1rem',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <CardContent style={{display: 'inline-block', paddingBottom: '0'}}>
                                        <Typography gutterBottom variant="subtitle1" component="h2">
                                            {isEnglish ? targetProduct[0].english_name : targetProduct[0].name}
                                        </Typography>
                                        <p style={{marginRight: '0', marginLeft: '0'}}>
                                            ¥{targetProduct[0].marked_up_price}
                                        </p>
                                    </CardContent>
                                </Grid>
                            </Grid>
                        </CardActionArea>

                        <CardActions style={{padding: '0', display: 'flex', justifyContent: 'center'}}>

                            <p style={{marginRight: '0', marginLeft: '0'}}>
                                <span>{isEnglish ? 'quantity: ' : '個数: '}</span>{dict[key]}</p>

                            <Typography variant="h6" component="p" style={{marginLeft: '1rem'}}>
                                ¥{targetProduct[0].marked_up_price * dict[key]}
                            </Typography>

                        </CardActions>
                    </Card>
                </Grid>
            )
        }
    }

    return (
        <>
            {size !== 'xs' ?
                // スマホサイズではない時
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            {list}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                // スマホサイズの時
                <Grid container spacing={1}>
                    {list}
                </Grid>
            }
        </>
    )
}

export default CostcoReviewItems