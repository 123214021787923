import React, {useState} from "react";
import CostcoPageTemplate from "../templates/CostcoPageTemplate";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    loadingCart: {
        backgroundColor: 'white',
        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '100vw',
        height: '100vh',
        zIndex: '9999',
        textAlign: 'center',
        transition: '1.5s',
    },
    image: {
        marginTop: '25vh',
        width: '75%',
        [theme.breakpoints.up('lg')]: {
            marginTop: '25vh',
            width: '20%',
        },
    },
}))

const CostcoPage = () => {
    const classes = useStyles()
    const [isOpen, setIsOpen] = useState(false)
    const [isDisplay, setIsDisplay] = useState(false)
    const huskyWalk = 'images/loading_cart.gif'

    setTimeout(() => {
        setIsOpen(true)
    }, 1200)
    setTimeout(() => {
        setIsDisplay(true)
    }, 2700)

    return (
        <>
            <div
                className={classes.loadingCart}
                style={{
                    opacity: isOpen ? 0 : 1,
                    display: isDisplay ? 'none' : 'block'
                }}
            >
                <img src={huskyWalk} alt="topImage"
                     className={classes.image}
                    //  height='130px'
                    //  style={{marginTop: '25vh'}}
                    //  style={{}}
                />
            </div>
            <CostcoPageTemplate/>
        </>
    )
}

export default CostcoPage